import { Box, Typography } from '@mui/material'
import React from 'react'

import { useLocation } from 'react-router-dom'
import DrawerOrganization from './DrawerOrganization';
import BoxCardOrganization from './BoxCardOrganization';

const OrganizationDashboard = () => {
  const location = useLocation();
  const { id } = location.state || {};



  return (
    <Box display='flex'>
      <DrawerOrganization id={id} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, height: '100%', marginTop: "50px", justifyContent: 'center' }}>
        <Box mb={2} width='100%' display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant="body1" component="span" fontSize='24px' fontWeight={400}>
            Dashboard
          </Typography>
        </Box>

        <BoxCardOrganization id={id} />
      </Box>

    </Box>
  )
}

export default OrganizationDashboard