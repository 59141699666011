
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState , useContext } from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Paper } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import { BorderAllRounded, Height, Visibility, VisibilityOff } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import Input from '@mui/material/Input';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../../../hooks/useAuth';
import { Link } from 'react-router-dom';
// import { AuthContext } from '../../../../contexts/AuthContext';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://dshgsonic.com/">
                DSHGSonic
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();

export const OrganizationLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [signInType, setSignInType] = useState('first');
    const navigate = useNavigate();
    const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));
    const { enqueueSnackbar } = useSnackbar();
    const {save} = useAuth();
    // const { dispatch } = useContext(AuthContext);
    // const { login } = useContext(AuthContext);




    const userName = 'admin';
    const adminPassword = 'Admin@123'

    const handleSwitchSignIn = () => {
        setSignInType(signInType === 'first' ? 'second' : 'first');
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };




    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (!email || !password) {
            enqueueSnackbar('Please enter fields', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' }})
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/organizationlogin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ workEmail: email, password }),
            });
    
            const data = await response.json();
    
            if (response.ok) {
              
                const id = data.id;
                save({} , data.token)
                navigate('/organizationdashboard' , { state: { id } } );// Uncomment and adjust to your protected route
            } else if (response.status === 401) {
                enqueueSnackbar('Wrong username or password', {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' }})
            } else {
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' }})
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong!', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' }})
        }
    };

  
    
    
      
    return (
        // <ThemeProvider theme={defaultTheme}>
            <Grid
                container
                sx={{
                    background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))',
                    // height: '100%',
                    display: 'flex',
                    minHeight:'100vh'
                    // padding: isMobile ? 5 : 0
                }}
            >
                <Grid item xs={12} sm={8} md={6} lg={6} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: isMobile ? 0 : 2,
                    margin: 'auto'
                }}>
                    <CssBaseline />
                    <Paper elevation={10} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        
                        p: 5,
                        borderRadius: "25px",
                        width: {xs : '90%' , md: '60%'}
                    }}  >
                        <Grid container direction="column" alignItems="center" sx={{
                            borderRadius: "20px 20px 0 0" 
                        }} xs={12}>
                            <Grid item>
                                <img
                                    src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                                    alt="Logo"
                                    style={{ width: '100px', marginRight: '5px' }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography component="h1" variant="h5" marginTop={2}>
                                    Organization Sign In
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container component="form"  justifyContent='center'  noValidate sx={{ mt: 1 }} xs={12}>
                            <Grid item xs={9}>
                                <TextField
                                    margin="normal"
                                     variant='standard'
                                    required
                                    fullWidth
                                    id="email"
                                    label="Username"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={(e) => setEmail(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EmailIcon color='primary'/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <FormControl required sx={{ mt: 1 }} fullWidth variant="standard">
                                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                    <Input
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={handlePasswordChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff color='primary'/> : <Visibility color='primary'/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={handleSubmit}
                                >
                                    Sign In
                                </Button>
                            </Grid>


                            <Grid container xs={12}>
                                <Grid item xs={12} md={6} lg={6}>
                                        <Link sx={{ cursor: 'pointer' }}>Forgot Password</Link>
                                </Grid>

                                <Grid item xs={12} md={6} lg={6} display='flex' justifyContent='end'>
                                    <Link sx={{ cursor: 'pointer' }} to="/signup">Sign Up</Link>
                                    {/* <Link  href="/signup">Sign Up</Link> */}
                                </Grid>

                                

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        // </ThemeProvider>
    );
}

export default OrganizationLogin;
