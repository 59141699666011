

// import React from 'react';
// import LinearProgress from '@mui/material/LinearProgress';
// import { styled } from '@mui/system';
// import { Box } from '@mui/material';

// const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   flexGrow: 1,
//   '& .MuiLinearProgress-bar': {
//     backgroundColor: 'yellow',
//   },
// }));

// class LinearDeterminate extends React.Component {
//   state = {
//     completed: 0
//   };

//   componentDidMount() {
//     this.updateProgress();
//   }

//   componentDidUpdate(prevProps) {
//     if (prevProps.activeStep !== this.props.activeStep || prevProps.totalSteps !== this.props.totalSteps) {
//       this.updateProgress();
//     }
//   }

//   updateProgress = () => {
//     const { activeStep, totalSteps } = this.props;
//     const newProgress = ((activeStep + 1) / totalSteps) * 100;
//     this.setState({ completed: newProgress });
//   };

//   render() {
//     return (
//         <Box sx={{ width: '60%', position: 'relative', bottom: 100}}>
//       <CustomLinearProgress
//         variant="determinate"
//         value={this.state.completed}
//       />
//       </Box>
//     );
//   }
// }

// export default LinearDeterminate;


import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearDeterminate({ activeStep, totalSteps }) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const newProgress = ((activeStep + 1) / totalSteps) * 100;
    setProgress(newProgress);
  }, [activeStep, totalSteps]);

  return (
    <Box sx={{ width: '60%', position: 'relative', bottom: 100 }}>
      <LinearProgress 
        variant="determinate" 
        value={progress} 
        // sx={{
        //   '& .MuiLinearProgress-bar': {
        //     backgroundColor: '#FF6900' ,
        //     borderColor: '#FF6900', 
        //   }
        // }} 
      />
    </Box>
  );
}

