import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import Swal from 'sweetalert2';

import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Paper,
  Grid
} from '@mui/material';
import Topbar from '../../components/Topbar';

const CenterOnboardingForm = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    organizationType: '',
    firstName: '',
    lastName: '',
    workEmail: '',
    workPhone: '',
    zipCode: '',
    numEmployees: '',
    jobPosition: [],
    referance: '',
    otherReferal: '',
    // jobIndustry: '',
  });

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [isAgree, setIsAgree] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'workEmail') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setEmailError(!emailPattern.test(value));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Logic to process form data
    console.log(formData);
  };

  const handleReferalChange = (event) => {
    setFormData({ ...formData, referance: event.target.value });
  };

  const handleOtherReferalChange = (event) => {
    setFormData({ ...formData, otherReferal: event.target.value });
  };

  const handleSucces = () => {
    Swal.fire({
        title: "Thank you for joining.",
        text: "We will be in touch soon",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: '#FEE4CC',
        // cancelButtonColor: '#F57C20',
        confirmButtonText: "Check your profile",
        // cancelButtonText: "Home",
        customClass: {
          popup: 'custom-swal'
      }
    }).then((result) => {
        if (result.isConfirmed) {

            window.location.reload();


        }
    });
};


  const handleJobChange = (event) => {
    const { value } = event.target;
    const updatedJobPosition = [...formData.jobPosition]; // Create a copy of the current jobPosition array

    if (updatedJobPosition.includes(value)) {
      // If the selected value is already in the array, remove it
      updatedJobPosition.splice(updatedJobPosition.indexOf(value), 1);
    } else {
      // Otherwise, add it to the array
      updatedJobPosition.push(value);
    }

    setFormData({
      ...formData,
      jobPosition: updatedJobPosition // Update the jobPosition array in formData
    });
  };

  const handleError = () => {
    Swal.fire({
      title: "Error",
      text: "There was an error processing your request. Please try again.",
      icon: "error",
      confirmButtonColor: "#d33",
      confirmButtonText: "OK",
      customClass: {
        popup: 'custom-swal'
    }
    });
  };


  const handleUserExist = () => {
    Swal.fire({
      title: "Error",
      text: "Data with mobile number is already present!!",
      icon: "error",
      confirmButtonColor: "#d33",
      confirmButtonText: "OK",
      customClass: {
        popup: 'custom-swal'
    }
    });
  };

  const handleUserEmailExist = () => {
    Swal.fire({
      title: "Error",
      text: "Data with email is already present!!",
      icon: "error",
      confirmButtonColor: "#d33",
      confirmButtonText: "OK",
      customClass: {
        popup: 'custom-swal'
    }
    });
  };

  const addUserData = async () => {
    console.log("in the data submission 602", formData);

    // Check if all required fields are filled
    const requiredFields = [
      'companyName', 'organizationType', 'firstName', 'lastName', 'workEmail',
      'workPhone', 'zipCode', 'numEmployees', 'jobPosition', 'referance'
    ];

    const isFormComplete = requiredFields.every(field => formData[field] && formData[field].length > 0);

    if (!isFormComplete) {
      console.log('Please fill all the required fields.');
      handleFieldError()

      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/hiringdata`, {
        companyName: formData.companyName,
        organizationType: formData.organizationType,
        firstName: formData.firstName,
        lastName: formData.lastName,
        workEmail: formData.workEmail,
        workPhone: formData.workPhone,
        zipCode: formData.zipCode,
        numEmployees: formData.numEmployees,
        jobPosition: formData.jobPosition,
        referance: formData.referance,
        otherReferal: formData.otherReferal,
        // jobIndustry: formData.jobIndustry
      });
      console.log(response.data);
      if (response.data === 'Phone') {
        console.log('User is already present with mobile');
        handleUserExist()
      } else if (response.data === 'Email') {
        console.log('User is already present with email');
        handleUserEmailExist()
      }else if (response.data === 'new') {
        console.log('User data added successfully', response);
        handleSucces();
      } else {
        handleError();
      }
    } catch (error) {
      console.error('Error adding user data:', error);
      handleError();
    }
  };

  const handleFieldError = () => {
    Swal.fire({
        title: "Incomplete Form",
        text: "Please fill out all required fields before submitting.",
        icon: "warning",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
        customClass: {
            popup: 'custom-swal'
        }
    });
};












  return (

    
    <Box component="form" onSubmit={handleSubmit} sx={{ '& .MuiTextField-root': { mb: 2, width: '100%', borderRadius: 10 } }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 1, fontSize: isMobile ? '14px' : '18px', fontWeight: 600 }} fontWeight={500} >
        1.Organization Name :
      </Typography>

     
      <TextField
        placeholder="Organization Name"
        variant="outlined"
        fullWidth
        name="companyName"
        type='text'
        value={formData.companyName}
        onChange={handleChange}
        size={isMobile ? 'small' : 'medium'}
        onKeyPress={(e) => {
          if (/[0-9]/.test(e.key)) {
            e.preventDefault();
          }
        }}
       
        sx={{
          mb: 1,
          mt: 1,
          borderRadius: 1,


          "& .MuiOutlinedInput-root": { borderRadius: 4 }
        }}
      />


      <Typography variant="h6" sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: 600, mb: 1, mt: 1 }}>
        2.Organization Type :
      </Typography>
      <Grid display={'flex'} flexDirection={'column'} xs={12} lg={8} md={8}>
        <FormControl component="fieldset">
          <RadioGroup
            name="organizationType"
            value={formData.organizationType}
            onChange={handleChange}
          >
            <FormControlLabel
              value="Health Center"
              control={<Radio />}
              label="A. Health Center"
              componentsProps={{
                typography: {
                  sx: { fontSize: isMobile ? '13px' : '15px', fontWeight: 500 }
                }
              }}
            />
            <FormControlLabel
              value="CBO"
              control={<Radio />}
              label="B. CBO"
              componentsProps={{
                typography: {
                  sx: { fontSize: isMobile ? '13px' : '15px', fontWeight: 500 }
                }
              }}
            />
            <FormControlLabel
              value="Health Plan"
              control={<Radio />}
              label="C. Health Plan"
              componentsProps={{
                typography: {
                  sx: { fontSize: isMobile ? '13px' : '15px', fontWeight: 500 }
                }
              }}
            />
            <FormControlLabel
              value="Nonprofit"
              control={<Radio />}
              label="D. Nonprofit"
              componentsProps={{
                typography: {
                  sx: { fontSize: isMobile ? '13px' : '15px', fontWeight: 500 }
                }
              }}
            />
            <FormControlLabel
              value="Other"
              control={<Radio />}
              label="E. Other"
              componentsProps={{
                typography: {
                  sx: { fontSize: isMobile ? '13px' : '15px', fontWeight: 500 }
                }
              }}
            />
          </RadioGroup>
        </FormControl>

      </Grid>


      <Box>
        <Typography sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: 600, mb: 1, mt: 1 }}>
          3.Representative Name :
        </Typography>
        <Box display='flex' justifyContent="space-between">
          <TextField
            placeholder='First Name'
            variant="outlined"
            fullWidth
            size={isMobile ? 'small' : 'medium'}
            name="firstName"
            value={formData.firstName}
            onKeyPress={(e) => {
              if (/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={handleChange}
            sx={{ mr: 3, borderRadius: 1, "& .MuiOutlinedInput-root": { borderRadius: 4 } }}
          />

          <TextField
            placeholder='Last Name'
            variant="outlined"
            fullWidth
            name="lastName"
            size={isMobile ? 'small' : 'medium'}
            onKeyPress={(e) => {
              if (/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            value={formData.lastName}
            onChange={handleChange}
            sx={{ borderRadius: 1, "& .MuiOutlinedInput-root": { borderRadius: 4 } }}
          />
        </Box>
      </Box>

      <Typography sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: 600, mb: 1, mt: 1 }}>
        4.Work Email :
      </Typography>
      <TextField
        placeholder='Work Email'
        variant="outlined"
        fullWidth
        size={isMobile ? 'small' : 'medium'}
        name="workEmail"
        value={formData.workEmail}
        onChange={handleChange}
        helperText={emailError ? "Please enter a valid email address" : ""}
        FormHelperTextProps={{
          sx: { color: 'red' },
        }}
        sx={{ borderRadius: 1, "& .MuiOutlinedInput-root": { borderRadius: 4 } }}
      />

      <Typography sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: 600, mb: 1, mt: 1 }}>
        5.Phone :
      </Typography>
      <TextField
        placeholder="Work Phone"
        variant="outlined"
        fullWidth
        size={isMobile ? 'small' : 'medium'}
        name="workPhone"
        value={formData.workPhone}
        onKeyPress={(e) => {
          if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          }
        }}
        onChange={handleChange}
        sx={{ borderRadius: 1, "& .MuiOutlinedInput-root": { borderRadius: 4 } }}
      />

      <Typography sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: 600, mb: 1, mt: 1 }}>
        6.ZIP Code :
      </Typography>

      <TextField
        placeholder="ZIP Code"
        variant="outlined"
        fullWidth
        size={isMobile ? 'small' : 'medium'}
        name="zipCode"
        value={formData.zipCode}
        
        onChange={handleChange}
        sx={{ borderRadius: 1, "& .MuiOutlinedInput-root": { borderRadius: 4 } }}
      />
      <Typography sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: 600, mb: 1, mt: 1 }}>
        7.Number of employees :
      </Typography>
      <TextField
        placeholder="Number of Employees"
        variant="outlined"
        fullWidth
        size={isMobile ? 'small' : 'medium'}
        name="numEmployees"
        value={formData.numEmployees}
        onKeyPress={(e) => {
          if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          }
        }}
        onChange={handleChange}
        sx={{ borderRadius: 1, "& .MuiOutlinedInput-root": { borderRadius: 4 } }}
      />

      <Box >

        <Typography variant="h6" sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: 600, mb: 1, mt: 1 }}>
          8.Which frontline public health partners are you intrested in hiring?
        </Typography>
        
          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={formData.jobPosition.includes("Community Health Worker/Promotor de Salud/Community Health Representative")}
                onChange={handleJobChange}
                value="Community Health Worker/Promotor de Salud/Community Health Representative"
           

              />}
              label="A. Community Health Worker/Promotor de Salud/Community Health Representative"
              componentsProps={{
                typography: {
                  sx: { fontSize: isMobile ? '13px' : '15px', fontWeight: 500 }
                }
              }}
            />
            <FormControlLabel
              control={<Checkbox
                checked={formData.jobPosition.includes("Medicaid (Medi-Cal) Doula")}
                onChange={handleJobChange}
                value="Medicaid (Medi-Cal) Doula"
              />}
              label="B. Medicaid (Medi-Cal) Doula"
              componentsProps={{
                typography: {
                  sx: { fontSize: isMobile ? '13px' : '15px', fontWeight: 500 }
                }
              }}
            />
            <FormControlLabel
              control={<Checkbox
                checked={formData.jobPosition.includes("Peer Support Specialist")}
                onChange={handleJobChange}
                value="Peer Support Specialist"
              />}
              label="C. Peer support specialist"
              componentsProps={{
                typography: {
                  sx: { fontSize: isMobile ? '13px' : '15px', fontWeight: 500 }
                }
              }}
            />
            <FormControlLabel
              control={<Checkbox
                checked={formData.jobPosition.includes("Wellness Coach")}
                onChange={handleJobChange}
                value="Wellness Coach"
              />}
              label="D. Wellness Coach"
              componentsProps={{
                typography: {
                  sx: { fontSize: isMobile ? '13px' : '15px', fontWeight: 500 }
                }
              }}
            />
          </FormGroup>
        
      </Box>

      <Box >

        <Typography variant="h6" sx={{ fontSize: isMobile ? '14px' : '18px', fontWeight: 600, mb: 1, mt: 1 }}>
          9.How did you hear about us?
        </Typography>
        <Grid  xs={12} lg={8} md={8} >
          <FormControl component="fieldset">
            <RadioGroup
              name="referal"
              value={formData.referance}
              onChange={handleReferalChange}
            >
              <FormControlLabel
                value="Social media"
                control={<Radio />}
                label="A. Social media"
                componentsProps={{
                  typography: {
                    sx: { fontSize: isMobile ? '13px' : '15px', fontWeight: 500 }
                  }
                }}
              />
              <FormControlLabel
                value="Search engine"
                control={<Radio />}
                label="B. Search engine"
                componentsProps={{
                  typography: {
                    sx: { fontSize: isMobile ? '13px' : '15px', fontWeight: 500 }
                  }
                }}
              />
              <FormControlLabel
                value="Referral"
                control={<Radio />}
                label="C. Referral"
                componentsProps={{
                  typography: {
                    sx: { fontSize: isMobile ? '13px' : '15px', fontWeight: 500 }
                  }
                }}
              />
              <FormControlLabel
                value="Conference or event"
                control={<Radio />}
                label="D. Conference or event"
                componentsProps={{
                  typography: {
                    sx: { fontSize: isMobile ? '13px' : '15px', fontWeight: 500 }
                  }
                }}
              />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label="E. Other"
                componentsProps={{
                  typography: {
                    sx: { fontSize: isMobile ? '13px' : '15px', fontWeight: 500 }
                  }
                }}
              />
            </RadioGroup>
          </FormControl>
          
        </Grid>
      </Box>


      <Box display={'flex'} flexDirection={'row'} alignItems="center" sx={{ mt: 2, mb: 2 }} justifyContent='center'>
        <Checkbox
          checked={isAgree}
          onChange={() => setIsAgree(!isAgree)}
          inputProps={{ 'aria-label': 'I agree to terms of service and privacy policy' }}

        />
        <Typography variant="body2" fontSize={isMobile ? '14px' : '16px'} fontWeight={500} color={isAgree ? 'primary' : 'textSecondary'}>I agree to terms of service and privacy policy</Typography>
      </Box>
      <Box width='100%' display='flex' alignItems='center' justifyContent='center'>
        <Button
          type="submit"
          variant="contained"

          disabled={!isAgree}
          onClick={addUserData}
          sx={{ borderRadius: 5, mb: 1, width: isMobile ? '100px' : '150px' }}
        >
          Submit
        </Button>
      </Box>
    </Box>
   
  );
}

const HealthOnboarding = () => {

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));


  return (
    <Box display='flex' width='100%' justifyContent='center' alignItems='center' flexDirection='column' bgcolor='#E7E1F0'>
      <Box width='100%' bgcolor='#E3DFFD'>
        <Topbar />
      </Box>
      <Box width='90%' display='flex' alignItems='center' justifyContent='center' flexDirection='column' pb={5}>
        <Typography mt={15} mb={2} color='#3C2565' sx={{ fontSize: isMobile ? '30px' : '50px' }} >
          Health Onboarding Form
        </Typography>

        <Grid xs={11}>
        <Paper elevation={20} sx={{ padding: isMobile ? 3 : 4,  borderRadius: 10 }}>
          <CenterOnboardingForm />
        </Paper>
        </Grid>
      </Box>
    </Box>

  );
}

export default HealthOnboarding;