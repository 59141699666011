


import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Grid, useMediaQuery } from '@mui/material';
import Swal from 'sweetalert2';
import { DrawerHeader } from '../../../components/DrawerHeader';
import { ColorModeContext, tokens } from "../../../theme";
import { useNavigate } from 'react-router-dom';

import TalentDetails from '../Talent/TalentDetails';
import HiringDetails from './HiringDetails';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import CallMadeIcon from '@mui/icons-material/CallMade';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';


export const Hiring = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();

  const handleError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      customClass: {
        popup: 'custom-swal'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  };

  const [talentData, setTalentData] = useState([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);

  const fetchHiringData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/allHiringdata`);
      setTalentData(response.data);
    } catch (error) {
      console.error('Error fetching talent data:', error);
      enqueueSnackbar('Error fetching hiring details!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
    })
    }
  };

  useEffect(() => {
    fetchHiringData();
  }, []);

  const handleDetails = (id) => {
    // navigate(`/hiringdetails/${id}`);
    navigate('/hiringdetails', { state: { id } })
  };

  const handlejob = (id) => {
    navigate(`/jobs`  , { state: { id } });
  };

  const handleAddJob = (id) => {
    console.log(id);
    navigate(`/addjob/${id}`);
  };



  const handleEdit = (id) => {
    console.log(`Edit talent with id: ${id}`);
    // navigate(`/editonboarding/${id}`);
    navigate('/editonboarding', { state: { id } })
  };

  const handleDelete = async (id) => {
    try {

      const response = await axios.post(`${process.env.REACT_APP_URL}/deleteorgnaization`, { id })
      console.log(response);
      if (response.data === 'delete') {
        console.log('Deleted');
        enqueueSnackbar('Organization deleted successfully', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
      })
      } else {
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
      })
      }

    } catch (error) {
      console.log(error);
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
    })

    }

  }

  const handleOrganizationDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#FEE4CC',
      cancelButtonColor: '#3C2565',
      confirmButtonText: "Yes, delete it!",
      customClass: {
        popup: 'custom-swal'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id)
      
      }
    });
  }





  return (

    <Box sx={{ display: 'flex' }}>
      <DrawerHeader />
      <Box component="main" sx={{ flexGrow: 1, p: 3, height: '100%', marginTop: "100px", justifyContent: 'center' }}>
        <Paper elevation={20} style={{ padding: 16, height: '100%' }}>
          <Typography variant="h4" gutterBottom justifyContent={'center'} color='#3C2565' mb={3}>
            Organization Data
          </Typography>
          {talentData.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: '16px' }}>Sr No</TableCell>
                    <TableCell style={{ fontSize: '16px' }}>Organization Name</TableCell>
                    <TableCell style={{ fontSize: '16px' }}>Representative Name</TableCell>
                    <TableCell style={{ fontSize: '16px' }}>Add Job</TableCell>
                    <TableCell style={{ fontSize: '16px' }}>Job List</TableCell>
                    <TableCell style={{ fontSize: '16px' }}>Details</TableCell>
                    <TableCell style={{ fontSize: '16px' }}>Edit</TableCell>
                    <TableCell style={{ fontSize: '16px' }}>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {talentData.map((talent, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ fontSize: '14px' }}>{index + 1}</TableCell>
                      <TableCell style={{ fontSize: '14px' }}>{talent.companyName}</TableCell>
                      <TableCell style={{ fontSize: '14px' }}>{talent.firstName} {talent.lastName}</TableCell>

                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: '#3C2565', color: 'white', fontWeight: 'bold' }}
                          onClick={() => handleAddJob(talent._id)}>
                            <AddIcon sx={{ mr: 1 }} />
                          Add Job

                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: '#F77E09', color: 'white', fontWeight: 'bold' }}
                          onClick={() => handlejob(talent._id)}>
                            <CallMadeIcon sx={{ mr: 1 }} />
                          Job List


                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: '#3C2565', color: 'white', fontWeight: 'bold' }}
                          onClick={() => handleDetails(talent._id)}>
                             <ListIcon sx={{ mr: 1 }} />
                          Details


                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: '#F77E09', color: 'white', fontWeight: 'bold' }}
                          onClick={() => handleEdit(talent._id)}>
                            <EditIcon sx={{ mr: 1 }} />
                          Edit


                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}
                          onClick={() => handleOrganizationDelete(talent._id)}>
                            <DeleteIcon sx={{ mr: 1 }} />
                          Delete


                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'center',

                height: '100vh',
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {selectedPhoneNumber && <HiringDetails phoneNumber={selectedPhoneNumber} />}

        </Paper>
      </Box>
    </Box>


    // <Grid  spacing={2} display='flex'>
    //   <Grid item xs={12}>
    //     <DrawerHeader />
    //   </Grid>
    //   <Grid item xs={12} component={Paper} elevation={20} sx={{ flexGrow: 1, p: isSmallScreen ? 1 : 3, height: '100%', marginTop: "100px", justifyContent: 'center' }}>
    //     <Grid component={Typography} variant="h4" gutterBottom color='#3C2565' mb={3} align="center" >
    //       Organization Data
    //     </Grid>
    //     {talentData.length > 0 ? (
    //       <TableContainer component={Paper}>
    //         <Table >
    //           <Grid xs={12} md={10}>
    //           <TableHead>
    //             <TableRow>
    //               <TableCell >Sr No</TableCell>
    //               <TableCell style={{ fontSize: isSmallScreen ? '12px' : '16px' }}>Organization Name</TableCell>
    //               <TableCell style={{ fontSize: isSmallScreen ? '12px' : '16px' }}>Representative Name</TableCell>
    //               <TableCell style={{ fontSize: isSmallScreen ? '12px' : '16px' }}>Add Job</TableCell>
    //               <TableCell style={{ fontSize: isSmallScreen ? '12px' : '16px' }}>Job List</TableCell>
    //               <TableCell style={{ fontSize: isSmallScreen ? '12px' : '16px' }}>Details</TableCell>
    //               <TableCell style={{ fontSize: isSmallScreen ? '12px' : '16px' }}>Edit</TableCell>
    //               <TableCell style={{ fontSize: isSmallScreen ? '12px' : '16px' }}>Delete</TableCell>
    //             </TableRow>
    //           </TableHead>
    //           <TableBody>
    //             {talentData.map((talent, index) => (
    //               <TableRow key={index}>
    //                 <TableCell style={{ fontSize: isSmallScreen ? '10px' : '14px' }}>{index + 1}</TableCell>
    //                 <TableCell style={{ fontSize: isSmallScreen ? '10px' : '14px' }}>{talent.companyName}</TableCell>
    //                 <TableCell style={{ fontSize: isSmallScreen ? '10px' : '14px' }}>{talent.firstName} {talent.lastName}</TableCell>
    //                 <TableCell>
    //                   <Grid component={Button}
    //                     variant="contained"
    //                     sx={{ backgroundColor: '#3C2565', color: 'white', fontWeight: 'bold' }}
    //                     onClick={() => handleAddJob(talent._id)}
    //                     xs={12}
    //                   >
    //                     <AddIcon sx={{ mr:  isSmallScreen ? 0 : 1 }} />
    //                     Add Job
    //                   </Grid>
    //                 </TableCell>
    //                 <TableCell>
    //                   <Grid component={Button}
    //                     variant="contained"
    //                     sx={{ backgroundColor: '#F77E09', color: 'white', fontWeight: 'bold' }}
    //                     onClick={() => handlejob(talent.workPhone)}
    //                   >
    //                     <CallMadeIcon sx={{ mr: 1 }} />
    //                     Job List
    //                   </Grid>
    //                 </TableCell>
    //                 <TableCell>
    //                   <Grid component={Button}
    //                     variant="contained"
    //                     sx={{ backgroundColor: '#3C2565', color: 'white', fontWeight: 'bold' }}
    //                     onClick={() => handleDetails(talent.workPhone)}
    //                   >
    //                     <ListIcon sx={{ mr: 1 }} />
    //                     Details
    //                   </Grid>
    //                 </TableCell>
    //                 <TableCell>
    //                   <Grid component={Button}
    //                     variant="contained"
    //                     sx={{ backgroundColor: '#F77E09', color: 'white', fontWeight: 'bold' }}
    //                     onClick={() => handleEdit(talent.workPhone)}
    //                   >
    //                     <EditIcon sx={{ mr: 1 }} />
    //                     Edit
    //                   </Grid>
    //                 </TableCell>
    //                 <TableCell>
    //                   <Grid component={Button}
    //                     variant="contained"
    //                     sx={{ backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}
    //                     onClick={() => handleOrganizationDelete(talent._id)}
    //                   >
    //                     <DeleteIcon sx={{ mr: 1 }} />
    //                     Delete
    //                   </Grid>
    //                 </TableCell>
    //               </TableRow>
    //             ))}
    //           </TableBody>
    //           </Grid>
    //         </Table>
    //       </TableContainer>
    //     ) : (
    //       <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
    //         <CircularProgress />
    //       </Grid>
    //     )}

    //     {selectedPhoneNumber && <HiringDetails phoneNumber={selectedPhoneNumber} />}
    //   </Grid>
    // </Grid>
  );
};

export default Hiring;
