


import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Grid } from '@mui/material';
import Swal from 'sweetalert2';
import { DrawerHeader } from '../../../components/DrawerHeader';
import { ColorModeContext, tokens } from "../../../theme";
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import TalentDetails from './TalentDetails';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import DeleteIcon from '@mui/icons-material/Delete';

export const Talent = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();

  const handleError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      customClass: {
        popup: 'custom-swal'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  };

  const [talentData, setTalentData] = useState([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);

  const fetchTalentData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/alldata`);
      const sortedData = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Adjust the attribute for sorting
      setTalentData(sortedData);
    } catch (error) {
      console.error('Error fetching talent data:', error);
      handleError();
    }
  };

  useEffect(() => {
    fetchTalentData();
  }, []);

  const handleDetails = (phoneNumber) => {
    // navigate(`/details/${phoneNumber}`);
    navigate('/details', { state: { phoneNumber } });
  };



  const handleEdit = (phoneNumber) => {
    console.log(`Edit talent with id: ${phoneNumber}`);
    // navigate(`/edittalent/${phoneNumber}`);
    navigate('/edittalent', { state: { phoneNumber } });
  };

  const handleDelete = async (id) => {
    try {

      const response = await axios.post(`${process.env.REACT_APP_URL}/deletetalent`, { id })
      console.log(response);
      if (response.data === 'delete') {
        console.log('Deleted');
      } else {
        handleError();
      }

    } catch (error) {
      console.log(error);
      handleError();

    }

  }

  const handleTalentDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#FEE4CC',
      cancelButtonColor: '#3C2565',
      confirmButtonText: "Yes, delete it!",
      customClass: {
        popup: 'custom-swal'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id)
        Swal.fire({
          title: "Deleted!",
          text: "Talent data has been deleted.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: '#FEE4CC',

          confirmButtonText: "Done",
          customClass: {
            popup: 'custom-swal'
          }
        }).then((result) => {

          window.location.reload();
        });
      }
    });
  }

  const handleOnboardingForm = () => {
    navigate('/formonboardingtalent')
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <DrawerHeader />
      <Box component="main" sx={{ flexGrow: 1, p: 3, height: '100%', marginTop: "100px", justifyContent: 'center' }}>
        <Paper elevation={20} style={{ padding: 16, height: '100%' }}>
          <Grid container xs={12} md={12} lg={12} justifyContent='space-between' >
            <Typography variant="h4" gutterBottom justifyContent={'center'} mb={3}>
              Talent Data
            </Typography>
           

           
            <Button
              variant='contained'
              sx={{ backgroundColor: '#3C2565', color: '#fff' , width:'180px' , height:'40px' }}
              onClick={handleOnboardingForm}
            >
              Add Talent
            </Button>
           

          </Grid>
          {talentData.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: '16px' }}>Sr No</TableCell>
                    <TableCell style={{ fontSize: '16px' }}>User Name</TableCell>
                    <TableCell style={{ fontSize: '16px' }}>Phone Number</TableCell>
                    <TableCell style={{ fontSize: '16px' }}>Details</TableCell>
                    <TableCell style={{ fontSize: '16px' }}>Edit</TableCell>
                    <TableCell style={{ fontSize: '16px' }}>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {talentData.map((talent, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ fontSize: '14px' }}>{index + 1}</TableCell>
                      <TableCell style={{ fontSize: '14px' }}>{talent.firstName} {talent.lastName}</TableCell>
                      <TableCell style={{ fontSize: '14px' }}>{talent.mobileNumber}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: '#3C2565', color: 'white', fontWeight: 'bold' }}
                          onClick={() => handleDetails(talent.mobileNumber)}>
                          <ListIcon sx={{ mr: 1 }} />
                          Details

                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: '#F77E09', color: 'white', fontWeight: 'bold' }}
                          onClick={() => handleEdit(talent.mobileNumber)}>
                          <EditIcon sx={{ mr: 1 }} />
                          Edit


                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}
                          onClick={() => handleTalentDelete(talent._id)}>
                          <DeleteIcon sx={{ mr: 1 }} />
                          Delete


                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'center',

                height: '100vh',
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {selectedPhoneNumber && <TalentDetails phoneNumber={selectedPhoneNumber} />}

        </Paper>
      </Box>
    </Box>
  );
};

export default Talent;
