// // import { Height } from '@mui/icons-material'
// // import { Box, Paper } from '@mui/material'
// // import React from 'react'

// // export const TermsAndConditions = () => {

// //   return (
// //     <Box height='100%' display='flex' alignItems='center' justifyContent='center'>
// //         <Paper elevation={20} sx={{height:'90%' , width:'90%' , display:'flex' , alignItems:'center' , justifyContent:'center' , flexDirection:'column'}}>
// //             <Box height='6%' width='100%' bgcolor='#18acdd'>
// //             </Box>
// //             <Box height='94%' width='100%'>

// //             </Box>

// //         </Paper>
// //     </Box>
// //   )
// // }

// // export default TermsAndConditions;


// import { Box, Paper, Typography } from '@mui/material';
// import React from 'react';

// export const TermsAndConditions = () => {
//     return (
//         <Box height='100%' display='flex' alignItems='center' justifyContent='center'>
//             <Paper elevation={20} sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
//                 <Box height='6%' width='100%' bgcolor='#18acdd'>
//                 </Box>
//                 <Box height='94%' width='100%' p={3} overflow='auto'>
//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Terms and Conditions
//                     </Typography>
//                     <Typography variant='h6' paragraph fontSize='14px' fontWeight={350}>
//                         Please read these terms and conditions carefully before using Our Service.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Interpretation and Definitions
//                     </Typography>
//                     <Typography variant='subtitle1' gutterBottom fontSize='15px' mt={1}>
//                         Interpretation
//                     </Typography>
//                     <Typography variant='body1' paragraph fontSize='14px' fontWeight={350}>
//                         The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural
//                     </Typography>
//                     <Typography variant='subtitle1' gutterBottom fontSize='20px' >
//                         Definitions
//                     </Typography>
//                     <Typography variant='body1' paragraph fontSize='14px' fontWeight={350}>
//                         For the purposes of these Terms and Conditions:
//                         <ul>
//                             <li><strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device, named SmartTracker.</li>
//                             <li><strong>Application Store</strong> means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.</li>
//                             <li><strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.</li>
//                             <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
//                             <li><strong>Country</strong> refers to: Florida, United States.</li>
//                             <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Innovated Soft LLC, 4327 S Highway 27 Unit 146 Clermont, FL 34711.</li>
//                             <li><strong>Content</strong> refers to content such as text, images, or other information that can be posted, uploaded, linked to, or otherwise made available by You, regardless of the form of that content.</li>
//                             <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone, or a digital tablet.</li>
//                             <li><strong>Service</strong> refers to the Application.</li>
//                             <li><strong>Subscriptions</strong> refer to the services or access to the Service offered on a subscription basis by the Company to You.</li>
//                             <li><strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</li>
//                             <li><strong>Third-party Social Media Service</strong> means any services or content (including data, information, products, or services) provided by a third-party that may be displayed, included, or made available by the Service.</li>
//                             <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
//                         </ul>
//                     </Typography>
//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Acknowledgment
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Subscriptions
//                     </Typography>
//                     <Typography variant='body1' paragraph fontSize='15px'>
//                         Subscription period
//                     </Typography>
//                     <Typography variant='body1'>
//                         The Service or some parts of the Service are available only with a paid Subscription. You will be billed in advance on a recurring and periodic basis (such as daily, weekly, monthly, or annually), depending on the type of Subscription plan you select when purchasing the Subscription.
//                         <br />
//                         <br />
//                         At the end of each period, Your Subscription will automatically renew under the exact same conditions unless You cancel it or the Company cancels it.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Subscription Cancellations
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         You may cancel Your Subscription renewal either through Your Account settings page or by contacting the Company. You will not receive a refund for the fees You already paid for Your current Subscription period, and You will be able to access the Service until the end of Your current Subscription period.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Billing
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         You shall provide the Company with accurate and complete billing information, including full name, address, state, zip code, telephone number, and a valid payment method information.
//                         <br />
//                         <br />
//                         Should automatic billing fail to occur for any reason, the Company will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Fee Changes
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         The Company, in its sole discretion and at any time, may modify the Subscription fees. Any Subscription fee change will become effective at the end of the then-current Subscription period.
//                         <br />
//                         <br />
//                         The Company will provide You with reasonable prior notice of any change in Subscription fees to give You an opportunity to terminate Your Subscription before such change becomes effective.
//                         <br />
//                         <br />
//                         Your continued use of the Service after the Subscription fee change comes into effect constitutes Your agreement to pay the modified Subscription fee amount.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Refunds
//                     </Typography>
//                     <Typography variant='body1'>
//                         Except when required by law, paid Subscription fees are non-refundable.
//                         <br />
//                         <br />
//                         Certain refund requests for Subscriptions may be considered by the Company on a case-by-case basis and granted at the sole discretion of the Company.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         User Accounts
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.
//                         <br />
//                         <br />
//                         You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.
//                         <br />
//                         <br />
//                         You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.
//                         <br />
//                         <br />
//                         You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.
//                     </Typography>




//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Communications
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         When you consent to electronic communications, we will send you a message confirming your registration.
//                         <br />
//                         <br />
//                         You can cancel the SMS service at any time by texting “STOP” to "short code or phone number". When you send the SMS message “STOP” to us, we reply with an SMS message that confirms that you have been unsubscribed. After this, you won’t receive any additional SMS messages from us. If you want to join again, sign up as you did the first time, and we will start sending SMS messages to you again.
//                         <br />
//                         <br />
//                         You can get more information at any time by texting “HELP” to "short code or phone number". When you send the SMS message “HELP” to us, we respond with instructions on how to use our service and how to unsubscribe.
//                         <br />
//                         <br />
//                         Message and data rates may apply for any messages that we send to you or you send to us.
//                         <br />
//                         <br />
//                         We are able to deliver messages to the following mobile phone carriers: Major carriers: AT&T, Verizon Wireless, Sprint, T-Mobile, MetroPCS, US Cellular, Alltel, Boost Mobile, Nextel, and Virgin Mobile. Minor carriers: Alaska Communications Systems (ACS), Appalachian Wireless (EKN), Bluegrass Cellular, Cellular One of East Central IL (ECIT), Cellular One of Northeast Pennsylvania, Cincinnati Bell Wireless, Cricket, Coral Wireless (Mobi PCS), COX, Cross, Element Mobile (Flat Wireless), Epic Touch (Elkhart Telephone), GCI, Golden State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois Valley Cellular, Inland Cellular, iWireless (Iowa Wireless), Keystone Wireless (Immix Wireless/PC Man), Mosaic (Consolidated or CTC Telecom), Nex-Tech Wireless, NTelos, Panhandle Communications, Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA, Simmetry (TMP Corporation), Thumb Cellular, Union Wireless, United Wireless, Viaero Wireless, and West Central (WCC or 5 Star Wireless). Carriers are not liable for delayed or undelivered messages.
//                         <br />
//                         <br />
//                         Message frequency varies. You will receive at least two messages per appointment set, including an appointment confirmation and an appointment reminder. Any cancellations and administrative changes will result in additional messages. Contact your wireless provider for more information about your text plan or data plan. If you have questions about the services provided by Our Service, email us at info@smarttracker.health.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Content
//                     </Typography>
//                     <Typography variant='body1' fontSize='15px'>
//                         Your Right to Post Content
//                     </Typography>
//                     <Typography>
//                         <br />
//                         Our Service allows You to post Content. You are responsible for the Content that You post to the Service, including its legality, reliability, and appropriateness.
//                         <br />
//                         <br />
//                         By posting Content to the Service, You grant Us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of Your rights to any Content You submit, post or display on or through the Service and You are responsible for protecting those rights. You agree that this license includes the right for Us to make Your Content available to other users of the Service, who may also use Your Content subject to these Terms.
//                         <br />
//                         <br />
//                         You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights, or any other rights of any person.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Content Restrictions
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         The Company is not responsible for the content of the Service's users. You expressly understand and agree that You are solely responsible for the Content and for all activity that occurs under your account, whether done so by You or any third person using Your account.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libelous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following:
//                     </Typography>
//                     <Typography variant='body1' component='ul'>
//                         <li>Unlawful or promoting unlawful activity.</li>
//                         <li>Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.</li>
//                         <li>Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.</li>
//                         <li>Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person.</li>
//                         <li>Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights.</li>
//                         <li>Impersonating any person or entity including the Company and its employees or representatives.</li>
//                         <li>Violating the privacy of any third person.</li>
//                         <li>False information and features.</li>
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with this Terms, refuse or remove this Content. The Company further reserves the right to make formatting and edits and change the manner any Content. The Company can also limit or revoke the use of the Service if You post such objectionable Content. As the Company cannot control all content posted by users and/or third parties on the Service, you agree to use the Service at your own risk. You understand that by using the Service You may be exposed to content that You may find offensive, indecent, incorrect or objectionable, and You agree that under no circumstances will the Company be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of your use of any content.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Content Backups
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         Although regular backups of Content are performed, the Company does not guarantee there will be no loss or corruption of data.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to being backed up or that changes during the time a backup is performed.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         The Company will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of Content. But You acknowledge that the Company has no liability related to the integrity of Content or the failure to successfully restore Content to a usable state.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         You agree to maintain a complete and accurate copy of any Content in a location independent of the Service.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Copyright Policy
//                     </Typography>
//                     <Typography variant='h6' gutterBottom fontSize='15px'>
//                         Intellectual Property Infringement
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content posted on the Service infringes a copyright or other intellectual property infringement of any person.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, You must submit Your notice in writing to the attention of our copyright agent via email at info@smarttracker.health and include in Your notice a detailed description of the alleged infringement.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing Your copyright.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         DMCA Notice and DMCA Procedure for Copyright Infringement Claims
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
//                     </Typography>
//                     <Typography variant='body1' component='ul'>
//                         <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest.</li>
//                         <li>A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.</li>
//                         <li>Identification of the URL or other specific location on the Service where the material that You claim is infringing is located.</li>
//                         <li>Your address, telephone number, and email address.</li>
//                         <li>A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</li>
//                         <li>A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright owner or authorized to act on the copyright owner's behalf.</li>
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         You can contact our copyright agent via email at info@smarttracker.health. Upon receipt of a notification, the Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Service.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Intellectual Property
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Links to Other Websites
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Termination
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Limitation of Liability
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         "AS IS" and "AS AVAILABLE" Disclaimer
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Governing Law
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Disputes Resolution
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         For European Union (EU) Users
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         United States Federal Government End Use Provisions
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         If You are a U.S. federal government end user, our Service is a "Commercial Item" as that term is defined at 48 C.F.R. §2.101.
//                     </Typography>
//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         United States Legal Compliance
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Severability and Waiver
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         <strong>Severability:</strong> If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         <strong>Waiver:</strong> Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Translation Interpretation
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.
//                     </Typography>

//                     <Typography variant='h6' gutterBottom fontSize='20px'>
//                         Changes to These Terms and Conditions
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.
//                     </Typography>
//                     <Typography variant='body1' paragraph>
//                         By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.
//                     </Typography>

//                 </Box>
//             </Paper>
//         </Box>
//     );
// }

// export default TermsAndConditions;
