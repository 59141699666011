import * as React from 'react';
import { Paper, Box, Typography, MenuItem, Stepper, Step, StepLabel, Button, Checkbox } from '@mui/material';
import { TextField } from '@mui/material';
import { useState } from 'react';
import Select from '@mui/material/Select';
import Topbar from '../../components/Topbar';
import Swal from 'sweetalert2';
import FormControlLabel from '@mui/material/FormControlLabel';

const fields = [
    'Hello! What should we call you?',
    'Awesome! Let\'s begin with your mobile number, which will serve as your platform ID*.',
    'Welcome to WePair! What are your preferred languages?',
    'What job position are you looking for today?',
    'What interests you most in a job?',
    'How many years of experience do you have?',
    'When are you able to start?',
    'Where would you like to work? (City, state)',
    'What are your expertise and community engagement?',
    'Which certificate(s), certification(s), or license(s) do you hold, list which state?',
    'Are you interested in being a mentor?',
    'Where would you like your matches sent?',

];


const locationOptions = ['City 1, State 1', 'City 2, State 2', 'City 3, State 3']; // Example location options



const languages = ['English', 'Spanish', 'French', 'German', 'Chinese', 'Japanese'];
const jobPositions = ['Community Health Worker/Promotora/Community Health Representative', 'Community Doula', 'Peer Support Specialist', 'Wellness Coach']; // Example job positions
const interests = ['Career advancement', 'Opportunity to get hired', 'Compensation'];
const experienceOptions = ['<1 year', '1-2 years', '3-5 years', '5+ years'];
const startOptions = ['Not sure', 'In a few weeks', 'In a few months', 'On a specific date'];
const childandMaternalHealthOptions = ['At-risk children', 'At-risk mothers', ' Pregnant/postpartum individuals'];
const communityRepresentationOptions = ['American Indian', 'Alaskan Native', 'Asian', ' African American/Black', 'Latino/Hispanic', 'Native Hawaiian or Pacific Islander', 'Rural residents']
const accessibilityandEquityOptions = ['Disability community', 'Economically disadvantaged', ' Farm workers']
const healthandWellnessOptions = ['Individuals with chronic health conditions', 'Individuals with limited English proficiency or literacy', 'Individuals with mental illness']
const socialJusticeandInclusionOptions = ['Justice-involved individuals', ' LGBTQIA+ community', 'Older adults (over 65 years of age)', 'People uninsured or underinsured']
const youthServicesOptions = ['At-risk youth', 'Youth mental health'];
const healthcare = ['CHW (Community Health Worker)', 'HHA (Home Health Aide)', 'CNA (Certified Nursing Assistant)', 'CHES (Certified Health Education Specialist)'];
const specializedCareCertificationsOptions = [' CD (Certified Doula) [DONA]', 'CLD (Certified Lactation Doula)', 'ICBD (International Certified Birth Doula)', 'CHPNA (Certified Hospice and Palliative Nursing Assistant)'];
const mentalHealthandPeerSupport = ['NCPS (National Certified Peer Specialist)', 'CPSS (Certified Peer Support Specialist)', 'NBC-HWC (National Board Certified Health & Wellness Coach)', 'CHWC (Certified Health and Wellness Coach)'];
const otherOptions = ['Not certified ', 'Other (Please specify)']

const FLPHPsForm = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [preferredLanguage, setPreferredLanguage] = useState('');
    const [jobPosition, setJobPosition] = useState('');
    const [jobInterest, setJobInterest] = useState('');
    const [yearsExperience, setYearsExperience] = useState('');
    const [startDate, setStartDate] = useState('');
    const [location, setLocation] = useState('');
    const [expertise, setExpertise] = useState('');
    const [certification, setCertification] = useState('');
    const [interestInMentor, setInterestInMentor] = useState('');
    const [matchesSent, setMatchesSent] = useState('');
    const [childandMaternalHealth, setChildandMaternalHealth] = useState('');
    const [communityRepresentation, setCommunityRepresentation] = useState('');
    const [accessibilityandEquity, setAccessibilityandEquity] = useState('');
    const [healthandWellness, setHealthandWellness] = useState('');
    const [socialJusticeandInclusion, setSocialJusticeandInclusion] = useState('');
    const [youthServices, setYouthServices] = useState('');
    const [healthCare , setHealthCare] = useState('');
    const [specializedCareCertifications , setSpecializedCareCertifications] = useState('');
    const [mentalHealthandPeerSupport , setMentalHealthandPeerSupport] = useState('');
    const [otherOptions , setOtherOptions] = useState('');

    const [agreeToTerms, setAgreeToTerms] = useState(false);


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        switch (name) {
            case 'firstName':
                setFirstName(fieldValue);
                break;
            case 'lastName':
                setLastName(fieldValue);
                break;
            case 'phoneNumber':
                setPhoneNumber(fieldValue);
                break;
            case 'email':
                setEmail(fieldValue);
                break;
            case 'preferredLanguage':
                setPreferredLanguage(fieldValue);
                break;
            case 'jobPosition':
                setJobPosition(fieldValue);
                break;
            case 'jobInterest':
                setJobInterest(fieldValue);
                break;
            case 'yearsExperience':
                setYearsExperience(fieldValue);
                break;
            case 'startDate':
                setStartDate(fieldValue);
                break;
            case 'location':
                setLocation(fieldValue);
                break;

            case 'childandMaternalHealth':
                setChildandMaternalHealth(fieldValue);
                break;
            case 'CommunityRepresentation':
                setCommunityRepresentation(fieldValue);
                break;
            case 'accessibilityandEquity':
                setAccessibilityandEquity(fieldValue);
                break;
            case 'healthandWellness':
                setHealthandWellness(fieldValue);
                break;
            case 'socialJusticeandInclusion':
                setSocialJusticeandInclusion(fieldValue);
                break;
            case 'youthServices':
                setYouthServices(fieldValue);
                break;
            case 'healthandWellness':
                setHealthandWellness(fieldValue);
                break;
            case 'mentalHealthandPeerSupport':
                setMentalHealthandPeerSupport(fieldValue);
                break;
            case 'specializedCareCertifications':
                setSpecializedCareCertifications(fieldValue);
                break;
            case 'otherOptions':
                setOtherOptions(fieldValue);
                break;
            default:
                break;
        }
    };







    const addUserData = async () => {
        const userData = {
            firstName,
            lastName,
            phoneNumber,
            email,
            preferredLanguage,
            jobPosition,
            jobInterest,
            yearsExperience,
            startDate,
            location,
            expertise,
            certification,
            interestInMentor,
            matchesSent,
        };

        try {
            const response = await fetch('${process.env.REACT_APP_URL}/flphpsdata', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            console.log('User data added successfully', response);
            handleSucces()
        } catch (error) {
            console.error('Error adding user data:', error);
            handleError();
        }
    };


    const handleFormSubmit = () => {
        try {

            Swal.fire({
                title: "Are you sure?",
                text: "You want to submit",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                customClass: {
                    popup: 'custom-swal'
                }
            }).then((result) => {
                if (result.isConfirmed) {

                    // handleSucces()
                    addUserData();

                }
            });
        } catch (error) {
            handleError()
        }
    }


    const handleSucces = () => {
        Swal.fire({
            title: "Successful",
            text: "Data Saved successfully!!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
            customClass: {
                popup: 'custom-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {

                window.location.reload();


            }
        });
    };


    const handleError = () => {
        Swal.fire({
            title: "Error",
            text: "There was an error processing your request. Please try again.",
            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
            customClass: {
                popup: 'custom-swal'
            }
        });
    };

    const handleFieldError = () => {
        Swal.fire({
            title: "Incomplete Form",
            text: "Please fill out all required fields before submitting.",
            icon: "warning",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
        });
    };

    const areAllFieldsFilled = () => {
        return (
            firstName &&
            lastName &&
            phoneNumber &&
            email &&
            preferredLanguage &&
            jobPosition &&
            jobInterest &&
            yearsExperience &&
            startDate &&
            location &&
            expertise &&
            childandMaternalHealth &&
            certification &&
            interestInMentor &&
            matchesSent
        );
    };


    return (
        <Box sx={{ display: 'flex' }}>
            <Topbar />
            <Box component="main" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }} marginTop="120px">
                <Paper elevation={20} style={{ width: '100%', height: '100%', background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))' }}>
                    <Box height='100%' width='100%' display='flex' alignItems='center' justifyContent='center'>
                        <Box
                            sx={{
                                width: '70%',
                                height: '80%',
                                marginTop: '10px',
                                textAlign: 'center',
                                backgroundColor: '#f0f0f0',
                                alignItems: 'center',
                                p: 20,
                                paddingTop: 10,
                                borderRadius: 8,
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {fields.map((label) => (
                                    <Step>
                                        <StepLabel>{activeStep}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <Typography variant="h4" gutterBottom sx={{ color: '#800080', fontWeight: 'bold', marginTop: 4 }}>
                                FLPHPs Onboarding Questions
                            </Typography>
                            <Typography variant="body1" gutterBottom sx={{ marginBottom: 3, color: '#800080' }}>
                                {`Field ${activeStep + 1} of ${fields.length}`}
                            </Typography>
                            <form onSubmit={handleSucces}>
                                <Box>
                                    {fields[activeStep] === 'Agree to Terms and Conditions' ? (
                                        <FormControlLabel
                                            control={<Checkbox checked={agreeToTerms} onChange={handleChange} name="agreeToTerms" required />}
                                            label={fields[activeStep]}
                                        />
                                    ) : (
                                        <>
                                            {activeStep === 0 && (
                                                <>
                                                    <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' width='100%'>
                                                        <TextField
                                                            name="firstName"
                                                            label="First Name"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            value={firstName}
                                                            onChange={handleChange}
                                                            required
                                                            sx={{ minWidth: 600 }}
                                                        />
                                                        <TextField
                                                            name="lastName"
                                                            label="Last Name"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            value={lastName}
                                                            onChange={handleChange}
                                                            required
                                                            sx={{ minWidth: 600 }}
                                                        />
                                                    </Box>
                                                </>
                                            )}
                                            {activeStep === 1 && (
                                                <>
                                                    <TextField
                                                        name="phoneNumber"
                                                        label="Telephone Number"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        value={phoneNumber}
                                                        onChange={handleChange}
                                                        required
                                                        sx={{ minWidth: 600 }}
                                                    />
                                                    <TextField
                                                        name="email"
                                                        label="Email"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        value={email}
                                                        onChange={handleChange}
                                                        required
                                                        sx={{ minWidth: 600 }}
                                                    />
                                                </>
                                            )}
                                            {activeStep === 2 && (
                                                <TextField
                                                    name="preferredLanguage"
                                                    label="Preferred Languages"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    value={preferredLanguage}
                                                    onChange={handleChange}
                                                    select
                                                    required
                                                    sx={{ minWidth: 600 }}
                                                >
                                                    {languages.map((lang) => (
                                                        <MenuItem key={lang} value={lang}>
                                                            {lang}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                            {activeStep === 3 && (
                                                <TextField
                                                    name="jobPosition"
                                                    label="Job Position"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    value={jobPosition}
                                                    onChange={handleChange}
                                                    select
                                                    required
                                                    sx={{ minWidth: 600 }}
                                                >
                                                    {jobPositions.map((job) => (
                                                        <MenuItem key={job} value={job}>
                                                            {job}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                            {activeStep === 4 && (
                                                <TextField
                                                    name="jobInterest"
                                                    label="Job Interest"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    value={jobInterest}
                                                    onChange={handleChange}
                                                    select
                                                    required
                                                    sx={{ minWidth: 600 }}
                                                >
                                                    {interests.map((interest) => (
                                                        <MenuItem key={interest} value={interest}>
                                                            {interest}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                            {activeStep === 5 && (
                                                <TextField
                                                    name="yearsExperience"
                                                    label="Years of Experience"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    value={yearsExperience}
                                                    onChange={handleChange}
                                                    select
                                                    required
                                                    sx={{ minWidth: 600 }}
                                                >
                                                    {experienceOptions.map((exp) => (
                                                        <MenuItem key={exp} value={exp}>
                                                            {exp}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                            {activeStep === 6 && (
                                                <TextField
                                                    name="startDate"
                                                    label="Start Date"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    value={startDate}
                                                    onChange={handleChange}
                                                    select
                                                    required
                                                    sx={{ minWidth: 600 }}
                                                >
                                                    {startOptions.map((start) => (
                                                        <MenuItem key={start} value={start}>
                                                            {start}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}

                                            {activeStep === 7 && (
                                                <TextField
                                                    name="location"
                                                    label="Where would you like to work? (City, state)"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    value={location}
                                                    onChange={handleChange}
                                                    select
                                                    required
                                                    sx={{ minWidth: 600 }}
                                                >
                                                    {locationOptions.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                            {activeStep === 8 && (
                                                <>
                                                    <Box display='flex' alignItems='center' justifyContent='space-between' flexDirection='row'>
                                                        <TextField
                                                            name="childandMaternalHealth"
                                                            label="Child And Maternal Health"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            value={childandMaternalHealth}
                                                            onChange={handleChange}
                                                            select
                                                            sx={{ mr: 4, minWidth: 400 }}
                                                        >
                                                            {childandMaternalHealthOptions.map((child) => (
                                                                <MenuItem key={child} value={child}>
                                                                    {child}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>

                                                        <TextField
                                                            name="CommunityRepresentation"
                                                            label="Community Representation"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            value={communityRepresentation}
                                                            onChange={handleChange}
                                                            select
                                                            sx={{ minWidth: 400 }}
                                                        >
                                                            {communityRepresentationOptions.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Box>

                                                    <Box display='flex' alignItems='center' justifyContent='space-between' flexDirection='row'>
                                                        <TextField
                                                            name="accessibilityandEquity"
                                                            label="Accessibility And Equity"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            value={accessibilityandEquity}
                                                            onChange={handleChange}
                                                            select
                                                            sx={{ mr: 4, minWidth: 400 }}
                                                        >
                                                            {accessibilityandEquityOptions.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>

                                                        <TextField
                                                            name="healthandWellness"
                                                            label="Health And Wellness"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            value={healthandWellness}
                                                            onChange={handleChange}
                                                            select
                                                            sx={{ minWidth: 400 }}
                                                        >
                                                            {healthandWellnessOptions.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Box>

                                                    <Box display='flex' alignItems='center' justifyContent='space-between' flexDirection='row'>
                                                        <TextField
                                                            name="socialJusticeandInclusion"
                                                            label="Social Justice and Inclusion"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            value={socialJusticeandInclusion}
                                                            onChange={handleChange}
                                                            select
                                                            sx={{ mr: 4, minWidth: 400 }}
                                                        >
                                                            {socialJusticeandInclusionOptions.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>

                                                        <TextField
                                                            name="youthServices"
                                                            label="Youth Services"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            value={youthServices}
                                                            onChange={handleChange}
                                                            select
                                                            sx={{ minWidth: 400 }}
                                                        >
                                                            {youthServicesOptions.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Box>
                                                </>
                                            )}



                                            {activeStep === 9 && (
                                                <>

                                                    <Box display='flex' alignItems='center' justifyContent='space-between' flexDirection='row'>
                                                        <TextField
                                                            name="healthcare"
                                                            label="Healthcare"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            value={healthcare}
                                                            onChange={handleChange}
                                                            select
                                                            sx={{ mr: 4, minWidth: 400 }}
                                                        >
                                                            {healthcare.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>

                                                        <TextField
                                                            name="specializedCareCertifications"
                                                            label="Specialized Care Certifications"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            value={specializedCareCertifications}
                                                            onChange={handleChange}
                                                            select
                                                            sx={{ minWidth: 400 }}
                                                        >
                                                            {specializedCareCertificationsOptions.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Box>

                                                    <Box display='flex' alignItems='center' justifyContent='space-between' flexDirection='row'>
                                                        <TextField
                                                            name="mentalHealthandPeerSupport"
                                                            label="Mental Health And Peer Support"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            value={mentalHealthandPeerSupport}
                                                            onChange={handleChange}
                                                            select
                                                            sx={{ mr: 4, minWidth: 400 }}
                                                        >
                                                            {mentalHealthandPeerSupport.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>

                                                        <TextField
                                                            name="otherOptions"
                                                            label="Other Options"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            value={otherOptions}
                                                            onChange={handleChange}
                                                            select
                                                            sx={{ minWidth: 400 }}
                                                        >
                                                            {otherOptions.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Box>

                                                </>

                                            )}

                                            {activeStep === 10 && (
                                                <TextField
                                                    name="certification"
                                                    label="Which certificate(s), certification(s), or license(s) do you hold, list which state?"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    value={certification}
                                                    onChange={handleChange}
                                                    required
                                                    sx={{ minWidth: 600 }}
                                                />
                                            )}

                                            {activeStep === 11 && (
                                                <TextField
                                                    name="intrestInMentor"
                                                    label="Are you interested in being a mentor?"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    value={interestInMentor}
                                                    onChange={handleChange}
                                                    required
                                                    sx={{ minWidth: 600 }}
                                                />
                                            )}

                                            {activeStep === 12 && (
                                                <TextField
                                                    name="matchesSent"
                                                    label="Where would you like your matches sent?"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    value={matchesSent}
                                                    onChange={handleChange}
                                                    required
                                                    sx={{ minWidth: 600 }}
                                                />
                                            )}


                                        </>
                                    )}
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ backgroundColor: '#ff6900', color: '#ffffff', marginRight: 3 }}
                                    >
                                        Back
                                    </Button>
                                    {activeStep === fields.length - 1 ? (
                                        <Button type="submit" sx={{ backgroundColor: '#ff6900', color: '#ffffff' }}>
                                            Submit
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={handleNext}
                                            sx={{ backgroundColor: '#ff6900', color: '#ffffff' }}
                                        >
                                            Next
                                        </Button>
                                    )}
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

export default FLPHPsForm;
