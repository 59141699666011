// import { Box, Button, Paper, TextField, Typography } from '@mui/material'
// import React, { useState } from 'react'
// import { Navigate, useNavigate } from 'react-router-dom';
// import Swal from 'sweetalert2'

// export const ForgotPassword = () => {

//     const [error, setError] = useState('');
//     const [email, setEmail] = useState('')
//     const [otp, setOtp] = useState('')
//     const [message, setMessage] = useState('');
//     const [otpSent, setOtpSent] = useState(false);
//     const navigate = useNavigate()
//     const [otpTimestamp, setOtpTimestamp] = useState(0);

//     const handleFieldError = () => {
//         Swal.fire({
//             title: "Error",
//             text: "Please fill all fields",
//             icon: "warning",
//             showCancelButton: false, // Set to false to hide the cancel button
//             showConfirmButton: true, // Set to true to show the confirm (OK) button
//             confirmButtonColor: "#3085d6",
//             confirmButtonText: "OK" // Change the text of the confirm button to "OK"
//           }).then((result) => {
//             if (result.isConfirmed) {
//               console.log("Empty field error");

//             }
//           });
//     }


//     const handleWrongEmailError = () => {
//         Swal.fire({
//             title: "Error",
//             text: "Email not found",
//             icon: "warning",
//             showCancelButton: false, // Set to false to hide the cancel button
//             showConfirmButton: true, // Set to true to show the confirm (OK) button
//             confirmButtonColor: "#3085d6",
//             confirmButtonText: "OK" // Change the text of the confirm button to "OK"
//           }).then((result) => {
//             if (result.isConfirmed) {
//               console.log("email error");
//               window.location.reload();
//             }
//           });
//     }


//     const handleError = () => {
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "Something went wrong!",
//         }).then((result) => {
//           if (result.isConfirmed) {
//             // Reload the window after clicking "OK"
//             window.location.reload();
//           }
//         });
//       }

//       const handleCodeSend = () => {
//         Swal.fire({
//             title: "successful.",
//             text: "Verification code send successfully.",
//             icon: "success",
//             showCancelButton: false, // Set to false to hide the cancel button
//             showConfirmButton: true, // Set to true to show the confirm (OK) button
//             confirmButtonColor: "#3085d6",
//             confirmButtonText: "OK" ,// Change the text of the confirm button to "OK"
//           }).then((result) => {
//             if (result.isConfirmed) {
//               console.log("Verification Code sent");
//             }
//           });
//     }

//     const handleCodeVerify = () => {
//         Swal.fire({
//             title: "successful.",
//             text: "Verification code verified successfully.",
//             icon: "success",
//             showCancelButton: false, // Set to false to hide the cancel button
//             showConfirmButton: true, // Set to true to show the confirm (OK) button
//             confirmButtonColor: "#3085d6",
//             confirmButtonText: "OK" ,// Change the text of the confirm button to "OK"
//           }).then((result) => {
//             if (result.isConfirmed) {
//               console.log("Code verified");
//             }
//           });
//     }

//     const handleCodeExpire = () => {
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "Verification Code expired. Resend the Code",
//         }).then((result) => {
//           if (result.isConfirmed) {
//             // Reload the window after clicking "OK"
//             console.log("Code expired.");
//           }
//         });
//       }


//       const handleWrongCode = () => {
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "Incorrect Verification Code",
//         }).then((result) => {
//           if (result.isConfirmed) {
//             // Reload the window after clicking "OK"
//             window.location.reload();
//           }
//         });
//       }



//     const handleCheckEmail = () => {
//         try {
//             const response = fetch(`${process.env.REACT_APP_URL}/otpsend`, {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//                 body: JSON.stringify({ email: email }),
//             }).then(response => response.json())
//                 .then(result => {
//                     const userotp = result.combineData.otp;
//                     const name = result.combineData.name
//                     const picture = result.combineData.picture 
//                     const email =result.combineData.email


//                     localStorage.setItem('useremail' ,email)
//                     localStorage.setItem('userName' , name)
//                     localStorage.setItem('userPicture', picture)
//                     localStorage.setItem('userotp', userotp)



//                     if (!email) {
//                         handleFieldError()
//                     } else if (result === "False") {
//                        handleWrongEmailError()
//                     } else if (userotp === "undefined" || userotp === "null" || userotp === null) {

//                         handleError()
//                     } else {
//                         console.log("OTP send successfully")
//                         handleCodeSend()
//                         setError("OTP is valid for 5 min only")
//                         setOtpSent(true);
//                         setOtpTimestamp(Date.now());


//                     }


//                 });


//         } catch (error) {
//             console.error("Error To send OTP:", error);
//             handleError()
//         }
//     }




//     const handleSubmitOtp = () => {
//         const userOtp = localStorage.getItem('userotp')

//         const currentTime = Date.now();
//         const otpValidityDuration = 5 * 60 * 1000;

//         if (currentTime - otpTimestamp > otpValidityDuration) {
//             handleCodeExpire()
//             return;
//         } else if (otp === userOtp) {
//             handleCodeVerify()
//             localStorage.setItem('userEmail' , email)
//             console.log("Before set password " , (localStorage.getItem('userEmail')))
//             navigate("/setforgotpassword")


//         } else if (!otp) {
//             handleFieldError()
//         } else if (otp !== userOtp) {
//             handleWrongCode()
//         }
//         else {
//             handleError()
//         }
//     }


//     const paperStyle = { height: "80%", width: "50%", borderRadius: 20 };
//     return (
//         <Box sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", height: "100%", flexDirection: "row"}}>
//             <Paper elevation={10} sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", height: "80%", width: "80%", flexDirection: "row" }}>
//                 <Box height="80%" width="50" sx={{
//                     backgroundImage: "url('https://t3.ftcdn.net/jpg/04/92/75/18/360_F_492751838_Ybun2zwpQC8AZv11AwZLdXJk4cUrTt5z.jpg')",
//                     height: 400,
//                     width: 500,
//                     backgroundSize: "cover",
//                     backgroundRepeat: "no-repeat",

//                 }}>

//                 </Box>
//                 <Paper elevation={20} style={paperStyle} sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>

//                     <Typography sx={{ fontSize: "50px", fontWeight: "100", margin: 1 }}>
//                         Reset
//                     </Typography>
//                     <Typography sx={{ fontSize: "50px", fontWeight: "100", margin: 1 }}>
//                         Your Password?
//                     </Typography>
//                     <Box
//                         sx={{
//                             marginTop: 4,
//                             display: "flex",
//                             flexDirection: "column",
//                             alignItems: "center",
//                         }}

//                     >
//                         <Box noValidate sx={{ mt: 1 }} >



//                             <TextField
//                                 required
//                                 fullWidth
//                                 id="outlined-required"
//                                 label="Email"
//                                 placeholder="Enter Register Email"
//                                 margin="2"
//                                 onChange={(e) => setEmail(e.target.value)}
//                             />
//                             <Button
//                                 type="submit"
//                                 variant="contained"
//                                 color="primary"
//                                 sx={{ mt: 2, mb: 2 }}
//                                 onClick={handleCheckEmail}
//                             >
//                                 {otpSent ? "Resend OTP" : "Send OTP"}
//                             </Button>
//                             {otpSent && (
//                             <>

//                                 <TextField
//                                     required
//                                     fullWidth
//                                     id="outlined-required"
//                                     label="OTP"
//                                     placeholder="Enter OTP"
//                                     margin="2"
//                                     onChange={(e) => setOtp(e.target.value)}
//                                 />
//                                 <Button
//                                     variant="contained"
//                                     // fullWidth
//                                     color="primary"
//                                     sx={{ mt: 2, mb: 2 }}
//                                     onClick={handleSubmitOtp}
//                                 >
//                                     Verify OTP
//                                 </Button>
//                             </>
//                         )}
//                         </Box>

//                     </Box>
//                 </Paper>



//             </Paper>
//         </Box>
//     )
// }



// import React from 'react';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useState, useContext } from 'react';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import { Paper } from '@mui/material';
// import { Navigate, useNavigate } from 'react-router-dom';
// import EmailIcon from '@mui/icons-material/Email';
// import { BorderAllRounded, Height, Visibility, VisibilityOff } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
// import IconButton from '@mui/material/IconButton';
// import FormControl from '@mui/material/FormControl';
// import InputLabel from '@mui/material/InputLabel';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import XIcon from '@mui/icons-material/X';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import { useEffect } from 'react';
// import Swal from 'sweetalert2';
// // import { AuthContext } from '../../../../contexts/AuthContext';
// import Input from '@mui/material/Input';
// // import axios from axios;
// import axios from 'axios';
// import { useSnackbar } from 'notistack';



// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://dshgsonic.com/">
//         DSHGSonic
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// const defaultTheme = createTheme();

// export const ForgotPassword = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   const [signInType, setSignInType] = useState('first');
//   const navigate = useNavigate();
//   const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));
//   // const { dispatch } = useContext(AuthContext);
//   // const { login } = useContext(AuthContext);
//   const [otpTimestamp, setOtpTimestamp] = useState(0);
//   const [error, setError] = useState('');
//   const [otpSent, setOtpSent] = useState(false);
//   const [otp, setOtp] = useState('')
//   const [userotp, setUserOtp] = useState('')
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [otpVerified, setOtpVerified] = useState(false);
//   const { enqueueSnackbar } = useSnackbar();





//   // const userName = 'admin';
//   // const adminPassword = 'Admin@123'

//   const handleSwitchSignIn = () => {
//     setSignInType(signInType === 'first' ? 'second' : 'first');
//   };

//   const handleClickShowPassword = () => setShowPassword((show) => !show);



//   const handleFieldError = () => {
//     Swal.fire({
//       title: "Error",
//       text: "Please fill all fields",
//       icon: "warning",
//       showCancelButton: false,
//       showConfirmButton: true,
//       confirmButtonColor: "#3085d6",
//       confirmButtonText: "OK",
//       customClass: {
//         popup: 'custom-swal'
//       }
//     }).then((result) => {
//       if (result.isConfirmed) {
//         console.log("email error");
//       }
//     });
//   }

//   const handlePasswordChange = (event) => {
//     setPassword(event.target.value);
// };

// const handleConfirmPasswordChange = (event) => {
//     setConfirmPassword(event.target.value);
// };

// const handleSubmit = async(event) => {
//   event.preventDefault();
// }




//   const handleCheckEmail = async (event) => {
//     event.preventDefault();
//     console.log("in function", email);

//     try {
//       const response = await axios.post(`${process.env.REACT_APP_URL}/api/forgotpassword`, {
//         userEmail: email, // Send the email as `userEmail`
//       });
//       console.log(response);


//       if (response.status === 200) {
//         setOtpSent(true);
//         setOtp(response.data.otp); // Store the received OTP

//         setOtpTimestamp(Date.now());
//         enqueueSnackbar('OTP sent successfully!', { variant: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
//       } else {
//         console.error('Failed to send OTP:', response.data.message);
//       }
//     } catch (error) {
//       console.error('Error sending OTP:', error.response ? error.response.data : error.message);
//     }
//   }



//   const handleCodeVerify = () => {
//     Swal.fire({
//       title: "successful.",
//       text: "Verification code verified successfully.",
//       icon: "success",
//       showCancelButton: false, // Set to false to hide the cancel button
//       showConfirmButton: true, // Set to true to show the confirm (OK) button
//       confirmButtonColor: "#3085d6",
//       confirmButtonText: "OK",
//       customClass: {
//         popup: 'custom-swal'
//     }// Change the text of the confirm button to "OK"
//     }).then((result) => {
//       if (result.isConfirmed) {
//         console.log("Code verified");
//       }
//     });
//   }

//   const handleCodeExpire = () => {
//     Swal.fire({
//       icon: "error",
//       title: "Oops...",
//       text: "Verification Code expired. Resend the Code",
//       customClass: {
//         popup: 'custom-swal'
//     }
//     }).then((result) => {
//       if (result.isConfirmed) {
//         // Reload the window after clicking "OK"
//         console.log("Code expired.");
//       }
//     });
//   }


//   const handleWrongCode = () => {
//     Swal.fire({
//       icon: "error",
//       title: "Oops...",
//       text: "Incorrect Verification Code",
//       customClass: {
//         popup: 'custom-swal'
//     }
//     }).then((result) => {
//       if (result.isConfirmed) {

//       }
//     });
//   }



//   const handleSubmitOtp = () => {
//     const currentTime = Date.now();
//     const otpValidityDuration = 5 * 60 * 1000; // 5 minutes in milliseconds

//     // Debugging logs
//     console.log('OTP Timestamp:', otpTimestamp);
//     console.log('Current Time:', currentTime);
//     console.log('Time Difference:', currentTime - otpTimestamp);


//     if (!userotp) {
//       handleFieldError();
//       return;
//     }

//     if (otp === userotp) {
//       if (currentTime - otpTimestamp <= otpValidityDuration) {
//         handleCodeVerify();
//       } else {
//         handleCodeExpire();
//       }
//     } else {
//       handleWrongCode();
//     }
//   }






//   return (
//     // <ThemeProvider theme={defaultTheme}>
//     <Grid
//       container
//       sx={{
//         background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))',
//         // height: '100%',
//         display: 'flex',
//         minHeight: '100vh'
//         // padding: isMobile ? 5 : 0
//       }}
//     >
//       <Grid item xs={12} sm={8} md={6} lg={6} sx={{
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         padding: isMobile ? 0 : 2,
//         margin: 'auto'
//       }}>
//         <CssBaseline />
//         <Paper elevation={10} sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',

//           p: 5,
//           borderRadius: "25px",
//           width: { xs: '90%', md: '60%' }
//         }}  >
//           <Grid container direction="column" alignItems="center" sx={{
//             borderRadius: "20px 20px 0 0"
//           }} xs={12}>
//             <Grid item>
//               <img
//                 src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
//                 alt="Logo"
//                 style={{ width: '100px', marginRight: '5px' }}
//               />
//             </Grid>
//             <Grid item>
//               <Typography component="h1" variant="h3" marginTop={2}>
//                 Reset Your Password
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container component="form" justifyContent='center' noValidate sx={{ mt: 1 }} xs={12}>
//             <Grid item xs={9}>
//               <TextField
//                 margin="normal"
//                 variant='standard'
//                 required
//                 fullWidth
//                 id="email"
//                 label="Email"
//                 name="email"
//                 autoComplete="email"
//                 autoFocus
//                 onChange={(e) => setEmail(e.target.value)}
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <EmailIcon color='primary' />
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//             </Grid>
//             <Grid item xs={9}>
//               <Button
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 sx={{ mt: 2, mb: 2, borderRadius: 4 }}
//                 onClick={handleCheckEmail}
//               >
//                 {otpSent ? "Resend Code" : "Send Code"}
//               </Button>
//             </Grid>

//             {otpSent && (
//               <>
//                 <Grid xs={9}>

//                   <TextField
//                     required
//                     fullWidth
//                     id="outlined-required"
//                     label="OTP"
//                     placeholder="Enter OTP"
//                     margin="2"
//                     onChange={(e) => setUserOtp(e.target.value)}
//                   />
//                 </Grid>

//                 <Grid xs={9}>
//                   <Button
//                     variant="contained"
//                     // fullWidth

//                     sx={{ mt: 2, mb: 2, borderRadius: 4, bgcolor: '#ecb61e' }}
//                     onClick={handleSubmitOtp}
//                   >
//                     Verify Code
//                   </Button>
//                 </Grid>
//               </>
//             )}
//             {/* <Grid item xs={9}>
//               <FormControl required sx={{ mt: 1 }} fullWidth variant="standard">
//                 <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
//                 <Input
//                   id="outlined-adornment-password"
//                   type={showPassword ? 'text' : 'password'}
//                   value={password}
//                   onChange={handlePasswordChange}
//                   endAdornment={
//                     <InputAdornment position="end">
//                       <IconButton
//                         aria-label="toggle password visibility"
//                         onClick={handleClickShowPassword}
//                         edge="end"
//                       >
//                         {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
//                       </IconButton>
//                     </InputAdornment>
//                   }
//                   label="Password"
//                 />
//               </FormControl>
//             </Grid>

//             <Grid item xs={10} md={9} lg={9} >
//               <FormControl required variant='standard' fullWidth margin='normal'>
//                 <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
//                 <Input
//                   id="outlined-adornment-confirm-password"
//                   type="password"
//                   value={confirmPassword}
//                   onChange={handleConfirmPasswordChange}
//                   label="Confirm Password"
//                 />
//               </FormControl>
//               <Typography variant="caption" color="textSecondary" >
//                 Note : Password should contain at least one special character, one digit, one uppercase letter, and one lowercase letter
//               </Typography>
//               {error && <Typography color="error">{error}</Typography>}
//             </Grid>
//             <Grid item  xs={10} md={8} lg={8}>
//               <Button
//                 type="submit"
//                 fullWidth
//                 variant="contained"
//                 sx={{ mt: 3, mb: 2 }}
//                 onClick={handleSubmit}
//               >
//                 Change Password
//               </Button>
//             </Grid> */}
//             {otpVerified && (
//               <>
//                 <Grid item xs={9}>
//                   <FormControl required sx={{ mt: 1 }} fullWidth variant="standard">
//                     <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
//                     <Input
//                       id="outlined-adornment-password"
//                       type={showPassword ? 'text' : 'password'}
//                       value={password}
//                       onChange={handlePasswordChange}
//                       endAdornment={
//                         <InputAdornment position="end">
//                           <IconButton
//                             aria-label="toggle password visibility"
//                             onClick={handleClickShowPassword}
//                             edge="end"
//                           >
//                             {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
//                           </IconButton>
//                         </InputAdornment>
//                       }
//                     />
//                   </FormControl>
//                 </Grid>

//                 <Grid item xs={9}>
//                   <FormControl required sx={{ mt: 1 }} fullWidth variant="standard">
//                     <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
//                     <Input
//                       id="outlined-adornment-password"
//                       type={showPassword ? 'text' : 'password'}
//                       value={confirmPassword}
//                       onChange={handleConfirmPasswordChange}
//                       endAdornment={
//                         <InputAdornment position="end">
//                           <IconButton
//                             aria-label="toggle password visibility"
//                             onClick={handleClickShowPassword}
//                             edge="end"
//                           >
//                             {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
//                           </IconButton>
//                         </InputAdornment>
//                       }
//                     />
//                   </FormControl>
//                 </Grid>

//                 <Grid item xs={9}>
//                   <Button
//                     type="submit"
//                     fullWidth
//                     variant="contained"
//                     color="primary"
//                     sx={{ mt: 3, mb: 2, borderRadius: 4 }}
//                     onClick={handleSubmit}
//                   >
//                     Change Password
//                   </Button>
//                 </Grid>
//               </>
//             )}



//           </Grid>
//         </Paper>
//       </Grid>
//     </Grid>
//     // </ThemeProvider>
//   );
// }

// export default ForgotPassword;

import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState, useContext } from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import Input from '@mui/material/Input';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://dshgsonic.com/">
        DSHGSonic
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [signInType, setSignInType] = useState('first');
  const navigate = useNavigate();
  const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));
  const [otpTimestamp, setOtpTimestamp] = useState(0);
  const [error, setError] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [userotp, setUserOtp] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otpVerified, setOtpVerified] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSwitchSignIn = () => {
    setSignInType(signInType === 'first' ? 'second' : 'first');
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Validate that password and confirm password match
    if (password !== confirmPassword) {
      enqueueSnackbar('Passwords do not match', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      return;
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updatetalentpassword`, {
        userEmail: email,
        newPassword: password,
      });
  
      if (response.status === 200) {
        enqueueSnackbar('Password changed successfully!', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        navigate('/talentlogin');
      } else {
        enqueueSnackbar('Failed to change password', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    } catch (error) {
      console.error('Error changing password:', error.response ? error.response.data : error.message);
      enqueueSnackbar('An error occurred. Please try again.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  };
  

  const handleCheckEmail = async (event) => {
    event.preventDefault();
    console.log("in function", email);

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/forgotpassword`, {
        userEmail: email,
      });
      console.log(response);

      if (response.status === 200) {
        setOtpSent(true);
        setOtp(response.data.otp);
        setOtpTimestamp(Date.now());
        enqueueSnackbar('OTP sent successfully!', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        console.error('Failed to send OTP:', response.data.message);
      }
    } catch (error) {
      console.error('Error sending OTP:', error.response ? error.response.data : error.message);
    }
  };

  const handleSubmitOtp = () => {
    const currentTime = Date.now();
    const otpValidityDuration = 5 * 60 * 1000;

    if (!userotp) {
      enqueueSnackbar('Please fill in the OTP field', { variant: 'warning', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      return;
    }

    if (otp === userotp) {
      if (currentTime - otpTimestamp <= otpValidityDuration) {
        setOtpVerified(true);
        enqueueSnackbar('Verification code verified successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        enqueueSnackbar('Verification code expired. Resend the code.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    } else {
      enqueueSnackbar('Incorrect verification code.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  };

  return (
    <Grid
      container
      sx={{
        background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))',
        // display: 'flex',
        minHeight: '100vh'
      }}
    >
      <Grid item xs={12} sm={8} md={6} lg={6} sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: isMobile ? 0 : 2,
        margin: 'auto',
        flexDirection:'column'
      }}>
        <CssBaseline />
        <Paper elevation={10} sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 5,
          borderRadius: "25px",
          width: { xs: '90%', md: '60%' }
        }}  >
          <Grid container direction="column" alignItems="center" sx={{
            borderRadius: "20px 20px 0 0"
          }} xs={12}>
            <Grid item>
              <img
                src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                alt="Logo"
                style={{ width: '100px', marginRight: '5px' }}
              />
            </Grid>
            <Grid item>
              <Typography component="h1" variant="h3" marginTop={2}>
                Reset Your Password
              </Typography>
            </Grid>
          </Grid>
          <Grid container component="form" justifyContent='center' noValidate sx={{ mt: 1 }} xs={12}>
            <Grid item xs={9}>
              <TextField
                margin="normal"
                variant='standard'
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailIcon color='primary' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={9}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 2, mb: 2, borderRadius: 4 }}
                onClick={handleCheckEmail}
                disabled={otpVerified} 
              >
                {otpSent ? "Resend Code" : "Send Code"}
              </Button>
            </Grid>

            {otpSent && (
              <>
                <Grid xs={9}>
                  <TextField
                    required
                    fullWidth
                    id="outlined-required"
                    label="Verification Code"
                    placeholder="Enter Verification Code"
                    margin="2"
                    onChange={(e) => setUserOtp(e.target.value)}
                    disabled={otpVerified} 
                  />
                </Grid>

                <Grid xs={9}>
                  <Button
                    variant="contained"
                    sx={{ mt: 2, mb: 2, borderRadius: 4, bgcolor: '#ecb61e' }}
                    onClick={handleSubmitOtp}
                    disabled={otpVerified} 
                  >
                    Verify Code
                  </Button>
                </Grid>
              </>
            )}

            {otpVerified && (
              <>
                <Grid item xs={9}>
                  <FormControl required sx={{ mt: 1 }} fullWidth variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <Input
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={handlePasswordChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={9}>
                  <FormControl required sx={{ mt: 1 }} fullWidth variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                    <Input
                      id="outlined-adornment-password"
                      type='password'
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      // endAdornment={
                      //   <InputAdornment position="end">
                      //     <IconButton
                      //       aria-label="toggle password visibility"
                      //       onClick={handleClickShowPassword}
                      //       edge="end"
                      //     >
                      //       {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
                      //     </IconButton>
                      //   </InputAdornment>
                      // }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={9}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2, borderRadius: 4 }}
                    onClick={handleSubmit}
                  >
                    Change Password
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
        <Grid item xs={12} sm={12} md={12} lg={12} pt={4}>
          <Copyright />
        </Grid>
      </Grid>


    </Grid>
  );
};
