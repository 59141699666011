import React from 'react';
import { Box, Typography, TextField, MenuItem } from '@mui/material';
import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { AppBar } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutline';
import EditCalendarOtlinedIcon from '@mui/icons-material/EditCalendar';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import { useNavigate , useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { RadioGroup, FormControl, FormControlLabel, Radio, Checkbox, FormGroup } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DrawerOrganization from './DrawerOrganization';





const jobTitles = [
    'Community Health Worker',
    'Doula',
    'Peer Support Specialist',
    'Wellness Coach'
];
const employmentTypes = [
    'Full-time',
    'Part-time',
    'Contract',
    'Temporary',
    'Flexible'
];
const languages = [
    'English',
    'Spanish',
    'French',
    'Mandarin'
];
const locations = [
    'Remote',
    'In Person',

];


const EditJobOrganization = () => {

    const location = useLocation();
    const { id , jobid } = location.state || {};
 
    const navigate = useNavigate();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [cleared, setCleared] = React.useState(false);
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true);












    const [formData, setFormData] = useState({
        jobTitle: job?.jobTitle || '',
        companyOverview: job?.companyOverview || '',
        jobDescription: job?.jobDescription || '',
        qualifications: job?.qualifications || '',
        skills: job?.skills || '',
        languageSkills: job?.languageSkills || '',
        location: job?.location || '',
        jobType: job?.jobType || '',
        employmentType: job?.employmentType || '',
        salary: job?.salary || '',
        training: job?.training || '',
        communityEngagement: job?.communityEngagement || '',
        applicationDeadline: job?.applicationDeadline || '',
        diversityStatement: job?.diversityStatement || '',
        postingDate: job?.postingDate || '',

    });




    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     console.log(id);
    //     console.log(formData);
    //     try {
    //         const response = await axios.post(`${process.env.REACT_APP_URL}/updatejob`, {
    //             formData,
    //             id,
    //         });
    //         console.log('Job listing added:', response.data);
    //         handleSucces();
    //     } catch (error) {
    //         console.error('Error adding job listing:', error);
    //         handleError()
    //     }
    // };

    const handleFieldError = () => {
        Swal.fire({
            title: "Incomplete Form",
            text: "Please fill out all required fields before submitting.",
            icon: "warning",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
            customClass: {
                popup: 'custom-swal'
            }
        });
    };


  


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    useEffect(() => {
        const fetchJobDetails = async () => {
            
            try {
                const response = await axios.post(`${process.env.REACT_APP_URL}/api/jobdataorganization`, { jobid });
                const jobListing = response.data;
                


                if (jobListing && jobListing.length > 0) {
                    setJob(jobListing[0]);

                } else {
                    setJob(null);
                }

                setLoading(false);
                // console.log("59^^^^^^^^^^^", response.data);
            } catch (error) {
                console.error("There was an error fetching the job listing!", error);
                setLoading(false);
            }
        };

        fetchJobDetails();
    }, [jobid]);

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            jobTitle: job?.jobTitle || '',
            companyOverview: job?.companyOverview || '',
            jobDescription: job?.jobDescription || '',
            qualifications: job?.qualifications || '',
            skills: job?.skills || '',
            languageSkills: job?.languageSkills || '',
            location: job?.location || '',
            employmentType: job?.employmentType || '',
            salary: job?.salary || '',
            training: job?.training || '',
            communityEngagement: job?.communityEngagement || '',
            applicationDeadline: job?.applicationDeadline || '',
            diversityStatement: job?.diversityStatement || '',
            jobType: job?.jobType || '',
            postingDate: job?.postingDate || '',

        }));
    }, [job]);

    const handleBack = () => {
        navigate(`/organizationdashboard`, { state: { id } });
    }

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
        setFormData(prevFormData => ({
          ...prevFormData,
          postingDate: today,
        }));
      }, []);

      const handleSubmit = async (e) => {
        e.preventDefault();
        const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
        setFormData(prevFormData => ({
          ...prevFormData,
          postingDate: today,
        }));

        
        const isFormComplete = Object.entries(formData).every(([key, value]) => {
            if (key === 'diversityStatement') {
                return true; 
            }
            // else if (key === 'postingDate'){
            //     return true; 
            // }
            return value !== '';
        });
       

        if (!isFormComplete) {
           
            handleFieldError()
           return;
        }

      
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/updatejoborganization`, {
                formData: {
                    ...formData,
                    postingDate: today, // Ensure the date is included in the request
                  },
                jobid,
            });
            console.log('Job listing added:', response.data);
            handleSucces();
        } catch (error) {
            console.error('Error adding job listing:', error);
            handleError();
        }
    };



    const handleError = () => {
        Swal.fire({
            title: "Error",
            text: "There was an error processing your request. Please try again.",
            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
            customClass: {
                popup: 'custom-swal'
            }
        });
    };

    const handleSucces = () => {
        Swal.fire({
            title: "Thank You",
            text: "Information Saved",
            html: `<div class="custom-swal-icon">✔</div><p>Information Saved</p>`,
            icon: "success",
            showCancelButton: true,
            confirmButtonColor: '#FEE4CC',
            cancelButtonColor: '#F57C20',
            confirmButtonText: "Home",
            cancelButtonText: "Check this job",
            customClass: {
                popup: 'custom-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {


                // navigate("/hiringdata");


            }
        });
    };





    // if (!hiringDetails) {
    //     return (
    //         <Box
    //             sx={{
    //                 display: 'flex',
    //                 justifyContent: 'center',
    //                 alignItems: 'center',
    //                 height: '100vh',
    //             }}
    //         >
    //             <CircularProgress />
    //         </Box>
    //     );
    // }




    const handleDateChange = (date) => {
        
        setFormData({ ...formData, applicationDeadline: date.format('DD MMMM YYYY') });
    };

    const defaultDate = formData.applicationDeadline ? dayjs(formData.applicationDeadline) : null;






    return (

        <Box sx={{ display: 'flex' }}>
            <DrawerOrganization id={id}/>
            <Box component="main"  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%', width: '100%' }} paddingTop={10}>
            {/* <Box mb={2} width='100%' display='flex' alignItems='center' justifyContent='space-between'>
                    <Box display='flex' alignItems='center' onClick={handleBack} sx={{
                        '&:hover': {
                            cursor: 'pointer',
                            '& .MuiTypography-root': {
                                color: '#FF6900'
                            },
                            '& .MuiIconButton-root': {
                                color: '#FF6900'
                            }
                        }
                    }}>
                        <IconButton onClick={handleBack}>
                            <ArrowBackIosNewIcon sx={{ color: '#FF6900' }} />
                        </IconButton>
                        <Typography variant="body1" component="span" fontSize={isMobile ? '16px' : '18px'} fontWeight={400}>
                            Back
                        </Typography>
                    </Box>
                </Box> */}
                
                <Typography sx={{ fontSize: '35px', fontWeight: 600, color: '#3C2565', mb: 2 }}>
                    Edit Job
                </Typography>
                <Box sx={{ width: isMobile ? '90%' : '80%' }} p={5} bgcolor='#E7E1F0' borderRadius={5} mb={3}>
                    <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 500, mb: -1, color: '#3C2565' }}>1. Job Title</Typography>
                    <TextField
                        name="jobTitle"
                        select
                        fullWidth
                        placeholder="Select job title"
                        margin="normal"
                        value={formData.jobTitle}
                        onChange={handleChange}
                        sx={{ mb: 3 }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'white',
                                borderRadius: '15px',
                            }
                        }}
                    >
                        {jobTitles.map((title, index) => (
                            <MenuItem key={index} value={title}>
                                {title}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 500, mb: -1, color: '#3C2565' }}>2. Company Overview</Typography>
                    <TextField
                        name="companyOverview"
                        fullWidth
                        placeholder="Brief description of the company"
                        margin="normal"
                        value={formData.companyOverview}

                        onChange={handleChange}
                        sx={{ mb: 3 }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'white',
                                borderRadius: '15px',
                            }
                        }}
                    />

                    <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 500, mb: -1, color: '#3C2565' }}>3. Job Description</Typography>
                    <TextField
                        name="jobDescription"
                        fullWidth
                        placeholder="Detailed job description"
                        margin="normal"
                        multiline
                        rows={4}
                        value={formData.jobDescription}
                        onChange={handleChange}
                        sx={{ mb: 3 }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'white',
                                borderRadius: '15px',
                            }
                        }}
                    />

                    <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 500, mb: -1, color: '#3C2565' }}>4. Qualifications and Experience</Typography>
                    <TextField
                        name="qualifications"
                        fullWidth
                        placeholder="Required qualifications and experience"
                        margin="normal"
                        multiline
                        rows={4}
                        value={formData.qualifications}
                        onChange={handleChange}
                        sx={{ mb: 3 }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'white',
                                borderRadius: '15px',
                            }
                        }}
                    />

                    <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 500, mb: -1, color: '#3C2565' }}>5. Skills Required</Typography>
                    <TextField
                        name="skills"
                        fullWidth
                        placeholder="Specific skills required"
                        margin="normal"
                        multiline
                        rows={4}
                        value={formData.skills}
                        onChange={handleChange}
                        sx={{ mb: 3 }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'white',
                                borderRadius: '15px',
                            }
                        }}
                    />

                    <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 500, mb: -1, color: '#3C2565' }}>6. Language Skills</Typography>
                    <TextField
                        name="languageSkills"
                        select
                        fullWidth
                        placeholder="Select language skills"
                        margin="normal"
                        value={formData.languageSkills}
                        onChange={handleChange}
                        sx={{ mb: 3 }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'white',
                                borderRadius: '15px',
                            }
                        }}
                    >
                        {languages.map((language, index) => (
                            <MenuItem key={index} value={language}>
                                {language}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 500, mb: -1, color: '#3C2565' }}>7. Job Type</Typography>
                    <TextField
                        name="jobType"
                        select
                        fullWidth
                        placeholder="Select job type"
                        margin="normal"
                        value={formData.jobType}
                        onChange={handleChange}
                        sx={{ mb: 3 }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'white',
                                borderRadius: '15px',
                            }
                        }}
                    >
                        {locations.map((jobType, index) => (
                            <MenuItem key={index} value={jobType}>
                                {jobType}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 500, mb: -1, color: '#3C2565' }}>8. Location</Typography>
                    <TextField
                        name="location"
                        fullWidth
                        placeholder="Job location"
                        margin="normal"
                        value={formData.location}
                        onChange={handleChange}
                        sx={{ mb: 3 }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'white',
                                borderRadius: '15px',
                            }
                        }}
                    />

                    <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 500, mb: -1, color: '#3C2565' }}>9. Working Hours/Type of Employment</Typography>
                    <TextField
                        name="employmentType"
                        select
                        fullWidth
                        placeholder="Select employment type"
                        margin="normal"
                        value={formData.employmentType}
                        onChange={handleChange}
                        sx={{ mb: 3 }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'white',
                                borderRadius: '15px',
                            }
                        }}
                    >
                        {employmentTypes.map((type, index) => (
                            <MenuItem key={index} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 500, mb: -1, color: '#3C2565' }}>10. Salary/Compensation</Typography>
                    <TextField
                        name="salary"
                        fullWidth
                        placeholder="Compensation details"
                        margin="normal"
                        value={formData.salary}
                        onChange={handleChange}
                        sx={{ mb: 3 }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'white',
                                borderRadius: '15px',
                            }
                        }}
                    />

                    <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 500, mb: -1, color: '#3C2565' }}>11. Training and Support</Typography>
                    <TextField
                        name="training"
                        fullWidth
                        placeholder="Details about training and support"
                        margin="normal"
                        multiline
                        rows={4}
                        value={formData.training}
                        onChange={handleChange}
                        sx={{ mb: 3 }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'white',
                                borderRadius: '15px',
                            }
                        }}
                    />

                    <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 500, mb: -1, color: '#3C2565' }}>12. Community Engagement</Typography>
                    <TextField
                        name="communityEngagement"
                        fullWidth
                        placeholder="How talent will engage with the community"
                        margin="normal"
                        multiline
                        rows={4}
                        value={formData.communityEngagement}
                        onChange={handleChange}
                        sx={{ mb: 3 }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'white',
                                borderRadius: '15px',
                            }
                        }}
                    />

                    <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 500, mb: -1, color: '#3C2565' }}>13. Application Deadline</Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            name="startDate"
                            value={defaultDate}
                            onChange={handleDateChange}
                            sx={{ mt: 2, mb: 2, width: '320px' }}
                            slotProps={{
                                textField: {
                                    InputProps: {
                                        sx: {
                                            backgroundColor: 'white',  // Set background color to white
                                            borderRadius: '4px',        // Optional: Add border radius if desired
                                        }
                                    }
                                }
                            }}

                        />
                    </LocalizationProvider>


                    <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 500, mb: -1, color: '#3C2565' }}>14. Diversity Statement</Typography>
                    <TextField
                        name="diversityStatement"
                        fullWidth
                        placeholder="Statement on diversity and inclusion"
                        margin="normal"
                        multiline
                        rows={4}
                        value={formData.diversityStatement}
                        onChange={handleChange}
                        sx={{ mb: 3 }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'white',
                                borderRadius: '15px',
                            }
                        }}
                    />

                    <Box mb={1} mt={5} display='flex' justifyContent='center' alignItems='center'>

                        <Button

                            onClick={handleBack}
                            sx={{

                                backgroundColor: '#FEE4CC',

                                color: '#F57C20',
                                marginRight: 3,
                                '&:hover': {
                                    backgroundColor: '#ffffff',
                                },
                                borderRadius: 8,
                                width: isMobile ? '120px' : '200px',
                                height: isMobile ? '30px' : '40px',
                                fontWeight: 500,
                                fontSize: isMobile ? '10px' : '16px',
                                border: 'none',
                                outline: 'none',
                                boxShadow: 'none',
                                textTransform: 'none'

                            }}
                        >

                            Cancel
                        </Button>
                        <Button

                            onClick={handleSubmit}
                            sx={{
                                backgroundColor: '#FF6900',
                                color: 'white',

                                '&:hover': {
                                    backgroundColor: '#FF6900',
                                },
                                borderRadius: 8,

                                width: isMobile ? '120px' : '200px',
                                height: isMobile ? '30px' : '40px',
                                fontWeight: 500,
                                fontSize: isMobile ? '10px' : '16px',
                                textTransform: 'none'
                            }}
                        >

                            Save
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default EditJobOrganization;
