
// import React, { useEffect, useState, useContext } from 'react';
// import axios from 'axios';
// import { useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Grid, useMediaQuery } from '@mui/material';
// import Swal from 'sweetalert2';
// // import { ColorModeContext, tokens } from "../../../theme";
// import { useNavigate } from 'react-router-dom';


// import HiringDetails from '../../../admin/Hiring/HiringDetails';
// import CircularProgress from '@mui/material/CircularProgress';
// import AddIcon from '@mui/icons-material/Add';
// import CallMadeIcon from '@mui/icons-material/CallMade';
// import EditIcon from '@mui/icons-material/Edit';
// import ListIcon from '@mui/icons-material/List';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { useLocation } from 'react-router-dom';
// import DrawerOrganization from './DrawerOrganization';


// export const JobsOrganization = () => {
//     const theme = useTheme();
//     // const colors = tokens(theme.palette.mode);
//     // const colorMode = useContext(ColorModeContext);
//     const navigate = useNavigate();
//     const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
//     const [talentData, setTalentData] = useState([]);
//     const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
//     const location = useLocation();
//     const { id } = location.state || {};
//     console.log(id);

//     const handleError = () => {
//         Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: "Something went wrong!",
//             customClass: {
//                 popup: 'custom-swal'
//             }
//         }).then((result) => {
//             if (result.isConfirmed) {
//                 window.location.reload();
//             }
//         });
//     };



//     // const fetchHiringData = async () => {
//     //     try {
//     //         const response = await axios.get(`${process.env.REACT_APP_URL}/api/allHiringdata`);
//     //         setTalentData(response.data);
//     //     } catch (error) {
//     //         console.error('Error fetching talent data:', error);
//     //         handleError();
//     //     }
//     // };

//     // useEffect(() => {
//     //     fetchHiringData();
//     // }, []);

//     useEffect(() => {
//         const fetchHiringDetails = async () => {
//             try {
//                 // console.log(id);

//                 console.log(id);

//                 const response = await axios.get(`${process.env.REACT_APP_URL}/api/allorganiationdata`);
//                 setTalentData(response.data);
//                 console.log("75^^^^^^^^^^^^^^^^66", response.data);

//             } catch (error) {
//                 console.error('Error fetching talent details:', error);
//             }
//         };

//         if (id) {
//             fetchHiringDetails();
//         }
//     }, [id]); 

//     const handleDetails = (id) => {
//         // navigate(`/hiringdetails/${id}`);

//         navigate('/hiringdetails', { state: { id } })
//     };

//     const handlejob = (id) => {
//         navigate(`/jobs`, { state: { id } });
//     };

//     const handleAddJob = (id) => {
//         console.log(id);
//         navigate(`/addjob/${id}`);
//     };



//     const handleEdit = (id) => {
//         console.log(`Edit talent with id: ${id}`);
//         // navigate(`/editonboarding/${id}`);
//         navigate('/editonboarding', { state: { id } })
//     };

//     const handleDelete = async (id) => {
//         try {

//             const response = await axios.post(`${process.env.REACT_APP_URL}/deleteorgnaization`, { id })
//             console.log(response);
//             if (response.data === 'delete') {
//                 console.log('Deleted');
//             } else {
//                 handleError();
//             }

//         } catch (error) {
//             console.log(error);
//             handleError();

//         }

//     }

//     const handleOrganizationDelete = (id) => {
//         Swal.fire({
//             title: "Are you sure?",
//             text: "You won't be able to revert this!",
//             icon: "warning",
//             showCancelButton: true,
//             confirmButtonColor: '#FEE4CC',
//             cancelButtonColor: '#3C2565',
//             confirmButtonText: "Yes, delete it!",
//             customClass: {
//                 popup: 'custom-swal'
//             }
//         }).then((result) => {
//             if (result.isConfirmed) {
//                 handleDelete(id)
//                 Swal.fire({
//                     title: "Deleted!",
//                     text: "Organization data has been deleted.",
//                     icon: "success",
//                     showCancelButton: false,
//                     confirmButtonColor: '#FEE4CC',

//                     confirmButtonText: "Done",
//                     customClass: {
//                         popup: 'custom-swal'
//                     }
//                 }).then((result) => {

//                     window.location.reload();
//                 });
//             }
//         });
//     }





//     return (

//         <Box sx={{ display: 'flex' }}>
//             <DrawerOrganization id={id}/>
//             <Box component="main" sx={{ flexGrow: 1, p: 3, height: '100%', marginTop: "100px", justifyContent: 'center' }}>
//                 <Paper elevation={20} style={{ padding: 16, height: '100%' }}>
//                     <Typography variant="h4" gutterBottom justifyContent={'center'} color='#3C2565' mb={3}>
//                         Organization Data
//                     </Typography>
//                     {talentData.length > 0 ? (
//                         <TableContainer component={Paper}>
//                             <Table>
//                                 <TableHead>
//                                     <TableRow>
//                                         <TableCell style={{ fontSize: '16px' }}>Sr No</TableCell>
//                                         <TableCell style={{ fontSize: '16px' }}>Organization Name</TableCell>
//                                         <TableCell style={{ fontSize: '16px' }}>Representative Name</TableCell>
//                                         <TableCell style={{ fontSize: '16px' }}>Add Job</TableCell>
//                                         <TableCell style={{ fontSize: '16px' }}>Job List</TableCell>
//                                         <TableCell style={{ fontSize: '16px' }}>Details</TableCell>
//                                         <TableCell style={{ fontSize: '16px' }}>Edit</TableCell>
//                                         <TableCell style={{ fontSize: '16px' }}>Delete</TableCell>
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {talentData.map((talent, index) => (
//                                         <TableRow key={index}>
//                                             <TableCell style={{ fontSize: '14px' }}>{index + 1}</TableCell>
//                                             <TableCell style={{ fontSize: '14px' }}>{talent.companyName}</TableCell>
//                                             <TableCell style={{ fontSize: '14px' }}>{talent.representativeName}</TableCell>

//                                             <TableCell>
//                                                 <Button
//                                                     variant="contained"
//                                                     sx={{ backgroundColor: '#3C2565', color: 'white', fontWeight: 'bold' }}
//                                                     onClick={() => handleAddJob(talent._id)}>
//                                                     <AddIcon sx={{ mr: 1 }} />
//                                                     Add Job

//                                                 </Button>
//                                             </TableCell>
//                                             <TableCell>
//                                                 <Button
//                                                     variant="contained"
//                                                     sx={{ backgroundColor: '#F77E09', color: 'white', fontWeight: 'bold' }}
//                                                     onClick={() => handlejob(talent._id)}>
//                                                     <CallMadeIcon sx={{ mr: 1 }} />
//                                                     Job List


//                                                 </Button>
//                                             </TableCell>
//                                             <TableCell>
//                                                 <Button
//                                                     variant="contained"
//                                                     sx={{ backgroundColor: '#3C2565', color: 'white', fontWeight: 'bold' }}
//                                                     onClick={() => handleDetails(talent._id)}>
//                                                     <ListIcon sx={{ mr: 1 }} />
//                                                     Details


//                                                 </Button>
//                                             </TableCell>
//                                             <TableCell>
//                                                 <Button
//                                                     variant="contained"
//                                                     sx={{ backgroundColor: '#F77E09', color: 'white', fontWeight: 'bold' }}
//                                                     onClick={() => handleEdit(talent._id)}>
//                                                     <EditIcon sx={{ mr: 1 }} />
//                                                     Edit


//                                                 </Button>
//                                             </TableCell>
//                                             <TableCell>
//                                                 <Button
//                                                     variant="contained"
//                                                     sx={{ backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}
//                                                     onClick={() => handleOrganizationDelete(talent._id)}>
//                                                     <DeleteIcon sx={{ mr: 1 }} />
//                                                     Delete


//                                                 </Button>
//                                             </TableCell>
//                                         </TableRow>
//                                     ))}
//                                 </TableBody>
//                             </Table>
//                         </TableContainer>
//                     ) : (
//                         <Box
//                             sx={{
//                                 mt: 2,
//                                 display: 'flex',
//                                 justifyContent: 'center',

//                                 height: '100vh',
//                             }}
//                         >
//                             <CircularProgress />
//                         </Box>
//                     )}

//                     {/* {selectedPhoneNumber && <HiringDetails phoneNumber={selectedPhoneNumber} />} */}

//                 </Paper>
//             </Box>
//         </Box>
//     );
// };

// export default JobsOrganization;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid } from '@mui/material';
import Swal from 'sweetalert2';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import { useLocation } from 'react-router-dom';
import DrawerOrganization from './DrawerOrganization';





const JobsOrganization = () => {
    const location = useLocation();
    const { id } = location.state || {};
    const [hiringDetails, setHiringDetails] = useState(null);
    const navigate = useNavigate();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [checked, setChecked] = React.useState(true);
    const organizationId = id;


    // const fetchHiringDetails = async () => {
    //     try {
    //         const response = await axios.get(`${process.env.REACT_APP_URL}/api/hiring/${id}`);
    //         setHiringDetails(response.data);
    //         console.log(response.data);
    //     } catch (error) {
    //         console.error('Error fetching talent details:', error);
    //     }
    // };

    // useEffect(() => {
    //     if (id) {
    //         fetchHiringDetails();
    //     }
    // }, [id]);

    useEffect(() => {
        const fetchHiringDetails = async () => {
            try {
                // console.log(id);

               

                const response = await axios.get(`${process.env.REACT_APP_URL}/api/organizationdata/${id}`);
                setHiringDetails(response.data);
                console.log(response.data);
                
                

            } catch (error) {
                console.error('Error fetching talent details:', error);
            }
        };

        if (id) {
            fetchHiringDetails();
        }
    }, [id]);

    const handleJobError = () => {
      
        Swal.fire({
            title: "No jobs available",

            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
            customClass: {
                popup: 'custom-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {

                //window.location.reload();
                // navigate("/hiringdata");


            }
        });
    };



    if (!hiringDetails) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }









    const handleDetails = (jobid) => {
        navigate(`/jobdetailsorganization`, { state: { id , jobid } });
    };




    const handleEdit = (jobid) => {
        navigate(`/editjoborganization`, { state: { id , jobid } });
    };

    const handleDelete = async (jobid) => {
        try {

            const response = await axios.post(`${process.env.REACT_APP_URL}/api/deletejoborganization`, { jobid })
            
            if (response.data === 'delete') {
                console.log('Deleted');

            } else {
                Job();
            }

        } catch (error) {
            console.log(error);
            Job();

        }

    }



    const Job = () => {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            customClass: {
                popup: 'custom-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.reload();
            }
        });
    };

    const handleJobDelete = (jobid) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#FEE4CC',
            cancelButtonColor: '#3C2565',
            confirmButtonText: "Yes, delete it!",
            customClass: {
                popup: 'custom-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(jobid)
                Swal.fire({
                    title: "Deleted!",
                    text: "Job has been deleted.",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: '#FEE4CC',

                    confirmButtonText: "Done",
                    customClass: {
                        popup: 'custom-swal'
                    }
                }).then((result) => {

                    window.location.reload();
                });
            }
        });
    }


    const handleChange = async (event, index , jobid) => {
        const newJobListing = [...hiringDetails.jobListing];
        newJobListing[index].jobStatus = event.target.checked ? 'active' : 'inactive';
        setHiringDetails({ ...hiringDetails, jobListing: newJobListing });

        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/update-joblisting-organization/${jobid}`, {
                jobListing: newJobListing,
            });
            

        } catch (error) {
            console.error('Error updating jobListing:', error);

        }
    };


    // if (hiringDetails.jobListing.length === 0) {
    //     return (
    //         handleJobError()
    //     );
    // }

    const handleAddJob = () => {
        navigate('/addjoborganization', { state: { id } });
    }












    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerOrganization id={id} />
            <Box component="main" sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%', p: 5 }} marginTop={5}>

                <Paper elevation={20} style={{ padding: 16 }}>
                    <Grid container xs={12} md={12} lg={12} justifyContent='space-between' >
                        <Typography fontSize='20px' fontWeight={600} mt={2} mb={2}>
                            Job List of  "{hiringDetails.companyName}" organization
                        </Typography>



                        <Button
                            variant='contained'
                            sx={{ backgroundColor: '#3C2565', color: '#fff', width: '180px', height: '40px' }}
                            onClick={handleAddJob}
                        >
                            Add Job
                        </Button>


                    </Grid>

                    {hiringDetails.jobListing.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: 16 }}>Sr No</TableCell>
                                        <TableCell style={{ fontSize: 16 }}>Job Title</TableCell>
                                        <TableCell style={{ fontSize: 16 }}>Deadline</TableCell>
                                        <TableCell style={{ fontSize: 16 }}>Details</TableCell>
                                        <TableCell style={{ fontSize: 16 }}>Edit</TableCell>
                                        <TableCell style={{ fontSize: 16 }}>Delete Job</TableCell>
                                        <TableCell style={{ fontSize: 16 }}>Job Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {hiringDetails.jobListing.map((job, index) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ fontSize: 16 }}>{index + 1}</TableCell>
                                            <TableCell style={{ fontSize: 16 }}>{job.jobTitle}</TableCell>
                                            <TableCell style={{ fontSize: 16 }}>{job.applicationDeadline}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    sx={{ backgroundColor: '#3C2565', color: 'white', fontWeight: 'bold' }}
                                                    onClick={() => handleDetails(job._id)}
                                                >
                                                    <ListIcon sx={{ mr: 1 }} />
                                                    Details

                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    sx={{ backgroundColor: '#F77E09', color: 'white', fontWeight: 'bold' }}
                                                    onClick={() => handleEdit(job._id)}
                                                >
                                                    <EditIcon sx={{ mr: 1 }} />
                                                    Edit

                                                </Button>
                                            </TableCell>

                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    sx={{ backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}
                                                    onClick={() => handleJobDelete(job._id)}
                                                    disabled={job.jobStatus === 'active'}
                                                >
                                                    <DeleteIcon sx={{ mr: 1 }} />
                                                    Delete

                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Switch
                                                    checked={job.jobStatus === 'active'}
                                                    onChange={(event) => handleChange(event, index , job._id)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />

                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Box
                            sx={{
                                mt: 2,
                                display: 'flex',
                                justifyContent: 'center',
                                height: '100vh',
                            }}
                        >
                            {/* <CircularProgress /> */}
                            <Typography>No Jobs Available</Typography>
                        </Box>
                    )}
                </Paper>
            </Box>
        </Box>
    )
}

export default JobsOrganization;

