
import React from 'react';
import { Box, AppBar, Toolbar, Typography, Divider, Avatar, Tooltip, Grid, Button, TextField, Pagination, Drawer, ListItem, List } from '@mui/material';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from '@emotion/react';
import { useNavigate, useLocation } from 'react-router-dom';
import DrawerOrganization from './DrawerOrganization';
import EmailIcon from '@mui/icons-material/Email';
import DvrIcon from '@mui/icons-material/Dvr';
import CallIcon from '@mui/icons-material/Call';
import PersonIcon from '@mui/icons-material/Person';

export const ProfileTalent = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const theme = useTheme();
  const [talentData, setTalentData] = useState([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);

  const [page, setPage] = useState(1);
  const boxesPerPage = isMobile ? 1 : 4;
  const [hiringData, setHiringData] = useState([]);
  const [selectedBoxData, setSelectedBoxData] = useState(null);
  const [count, setCount] = useState(0);
  const [filterDataCount, setFilterDataCount] = useState(undefined);
  const [totalBoxes, setTotalBoxes] = useState(count);
  const totalPages = Math.ceil(totalBoxes / boxesPerPage);
  const [searchTerm, setSearchTerm] = useState('');
  const [locationTerm, setLocationTerm] = useState('');
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [companyNames, setCompanyNames] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [selectedJob, setSelectedJob] = useState('');
  const [salary, setSalaries] = useState([]);
  const [selectedSalary, setSelectedSalary] = useState('');
  const [talentCount, setTalentCount] = useState('')
  const [selectedState, setSelectedState] = useState('');
  const [locationInput, setLocationInput] = useState('');


  const handleLocationChange = (event) => setSelectedLocation(event.target.value);
  const handleOrganizationChange = (event) => setSelectedOrganization(event.target.value);
  const handleJobChange = (event) => setSelectedJob(event.target.value);

  const navigate = useNavigate();
  const location = useLocation();

  const { id } = location.state || {};
  console.log(id);

  const handleError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      customClass: {
        popup: 'custom-swal'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  };



  const fetchTalentData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/alldata`);
      const sortedData = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Adjust the attribute for sorting
      setTalentData(sortedData);
    } catch (error) {
      console.error('Error fetching talent data:', error);
      handleError();
    }
  };


  useEffect(() => {
    fetchTalentData();
  }, []);

  const handleDetails = (userid) => {
    // navigate(`/details/${phoneNumber}`);
    navigate('/persontalentdata', { state: { id, userid } });
  };

  const handleLike = async (userid) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/like-talent`, {
        id, // The organization ID from the location state
        userid // The talent ID
      });

      if (response.status === 200) {
        console.log("succesfull");

      }
    } catch (error) {
      console.error('Error liking talent:', error);

    }
  };





  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
    console.log("search term", searchTerm);
  };







  const handleChangePage = (event, value) => {
    setPage(value);
    setSelectedBoxData(null);
    window.scrollTo({ top: 0, behavior: 'smooth' });

  };

  const handleBoxClick = (job) => {
    setSelectedBoxData(job);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };














  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format


  const allJobListings = talentData






 

  const filteredData = talentData.filter(item => {
    return selectedLocation ? item.certification?.state === selectedLocation : true;
  });
  

  useEffect(() => {
    if (filteredData.length > 0) {
      setSelectedBoxData(filteredData[(page - 1) * boxesPerPage]);
    }
  }, [page, filteredData]);

  const filteredDataCount = filteredData.length;



  const dataToDisplay = selectedLocation === ''  ? allJobListings : filteredData;



  useEffect(() => {
    if (filteredDataCount) {
      setTotalBoxes(filteredDataCount);

    } else {
      setTotalBoxes(filteredDataCount);
    }
  }, [filteredDataCount]);







  if (!hiringData) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }


  if (!selectedBoxData) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!filteredData) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const handleChange = (event) => {
    setSelectedLocation(event.target.value);
  };


  const handleJobTypeChange = (event) => {
    setSelectedJob(event.target.value);
  };

  const handleSalaryChange = (event) => {
    setSelectedSalary(event.target.value);
  };




  const expertiseKeyMapping = {
    childAndMaternalHealth: 'Child and Maternal Health',
    communityRepresentation: 'Community Representation',
    accessibilityAndEquity: 'Accessibility and Equity',
    healthAndWellness: 'Health and Wellness',
    socialJusticeAndInclusion: 'Social Justice and Inclusion',
    youthServices: 'Youth Services'
  };

  const certificationKeyMapping = {
    healthcare: "Healthcare",
    specializedCareCertifications: "Specialized Care Certifications",
    mentalHealthandPeerSupport: "Mental Health and Peer Support",
    otherOptions: "Other Options",
  };


  const uniqueStates = Array.from(new Set(talentData.map(item => item.certification?.state).filter(Boolean)));
  const uniqueJobPositions = Array.from(new Set(talentData.map(item => item.jobPosition).filter(Boolean)));
  const uniqueCities = Array.from(new Set(talentData.map(item => item.city).filter(Boolean)));





  return (
    <Box sx={{ display: 'flex' }}>
      <DrawerOrganization id={id} />


      <Grid container xs={12} md={12} lg={12} justifyContent={'center'} bgcolor='#f5dbe0' minHeight='100vh'>
        <Grid item xs={10} sx={{
          p: '2px 4px', display: 'flex', alignItems: 'center', borderRadius: 8, height: 50, backgroundColor: 'white', mt: 13, width: '60%',
          '@media (max-width: 1000px)': {
            width: '95%',
            height: 40
          },
        }}>
          <Grid item component={IconButton} sx={{ p: '10px' }} aria-label="search">

            <SearchIcon />

          </Grid>
          <Grid item component={InputBase}

            sx={{
              ml: 1, flex: 1, '@media (max-width: 1000px)': {
                ml: 0
              },
            }}
            placeholder="Job title"
            inputProps={{
              'aria-label': 'search jobs',
              style: {
                fontSize: '1rem', '@media (max-width: 1000px)': {
                  fontSize: '5px',
                },
              }
            }}
            onChange={handleSearchChange} >

          </Grid>
          <Grid item>
            <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
          </Grid>
          <Grid item>
            <IconButton sx={{ p: '10px' }} aria-label="location">
              <RoomOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item xs>
            <InputBase
              sx={{
                ml: 1, flex: 1,
                '@media (max-width: 1000px)': {
                  ml: 0,
                  fontSize: '12px'
                },
              }}
              placeholder="City, state, zip code"
              inputProps={{
                'aria-label': 'search location',
                style: {
                  fontSize: '16px',
                  '@media (max-width: 1000px)': {
                    fontSize: '12px'
                  },
                }
              }}
              onChange={handleLocationChange}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" sx={{
              p: '10px', ml: 1, borderRadius: 5, width: 100, '@media (max-width: 1000px)': {
                p: '2px',
                width: 80
              },
            }}>
              Search
            </Button>
          </Grid>

        </Grid>

        <Grid item xs={12} md={10} lg={10} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }} gap={1} >



          <Grid item xs={6} md={4} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="state-select-label" sx={{ color: '#8C8C8C', alignItems: 'center' }}>Job State</InputLabel>
              <Select
                labelId="state-select-label"
                id="state-select"
                value={selectedLocation}
                onChange={handleLocationChange}
                label="Location"
                sx={{ bgcolor: '#D8D1E3', borderRadius: '20px' }}
              >
                <MenuItem value="">
                  <em>Select Location</em>
                </MenuItem>
                <MenuItem value="Texas">Texas</MenuItem>
                <MenuItem value="Maharashtra">Maharashtra</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item xs={6} md={4} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="organization-select-label" sx={{ color: '#8C8C8C', alignItems: 'center' }}>Job Position</InputLabel>
              <Select
                labelId="organization-select-label"
                id="organization-select"
                value={selectedOrganization}
                onChange={handleOrganizationChange}
                label="Organization"
                sx={{ bgcolor: '#D8D1E3', borderRadius: '20px' }}
              >
                <MenuItem value="">
                  <em>Select Organization</em>
                </MenuItem>
                {uniqueJobPositions.map((job, index) => (
                  <MenuItem key={index} value={job}>
                    {job}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} md={4} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="job-select-label" sx={{ color: '#8C8C8C', alignItems: 'center' }}>Job Type</InputLabel>
              <Select
                labelId="job-select-label"
                id="job-select"
                value={selectedJob}
                onChange={handleJobChange}
                label="Job Type"
                sx={{ bgcolor: '#D8D1E3', borderRadius: '20px' }}
              >
                <MenuItem value="">
                  <em>Select Job Type</em>
                </MenuItem>
                {uniqueCities.map((city, index) => (
                  <MenuItem key={index} value={city}>
                    "Maharashtra"
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>


          <Grid item xs={6} md={4} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" sx={{ color: '#8C8C8C', alignItems: 'center' }}>Pay</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={selectedSalary}
                label="Pay"
                // onChange={handleSalaryChange}
                sx={{ bgcolor: '#D8D1E3', borderRadius: '20px' }}
              >
                <MenuItem value="">
                  <em>Select salary</em>
                </MenuItem>
                <MenuItem value="<10000">
                  &lt; 10000
                </MenuItem>
                <MenuItem value="10000-50000">
                  10000 - 50000
                </MenuItem>
                <MenuItem value=">50000">
                  &gt; 50000
                </MenuItem>
              </Select>
            </FormControl>

          </Grid> */}


        </Grid>

        <Grid container bgcolor='#f5dbe0' sx={{ mt: { xs: 12, md: 8, lg: 8 } }} xs={12} md={12} lg={12} justifyContent='center'>
          <Grid item xs={11} md={4} lg={4} justifyContent='center'>
            {dataToDisplay.length === 0 ? (
              <Grid sx={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderRadius: 8,
                p: 2,
                border: '1px solid transparent',
                transition: 'border 0.3s ease-in-out , bgcolor 2s ease-in-out',
                '&:hover': {
                  border: '1px solid #000',
                },
                bgcolor: 'white',
              }} >

                <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', width: '100%', height: '100%' }}>
                  <Avatar alt='User Avatar' sx={{ width: 40, height: 40, mr: 2 }} />
                  <Grid mr={3} width='80%'>
                    <Typography fontSize={18} fontWeight={600}>
                      No Job Found
                    </Typography>
                  </Grid>

                </Grid>

              </Grid>
            ) : (
              dataToDisplay.slice((page - 1) * boxesPerPage, page * boxesPerPage).map((job, index) => (
                <Grid xs={12}
                  key={index}
                  mb={2}
                  onClick={() => handleBoxClick(job)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    borderRadius: 8,
                    p: 2,
                    border: '1px solid transparent',
                    transition: 'border 0.3s ease-in-out, bgcolor 0.3s ease-in-out',
                    '&:hover': {
                      border: '1px solid #000',
                      cursor: 'pointer'
                    },
                    bgcolor: selectedBoxData && selectedBoxData.id === job.id ? '#D8D1E3' : 'white',
                  }}

                >
                  <Grid
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    <Avatar alt="User Avatar" sx={{ width: 40, height: 40, mr: 2 }} />
                    <Grid mr={3} width="80%">
                      <Typography fontSize={18} fontWeight={600}>
                        {job.firstName} {job.lastName}
                      </Typography>
                    </Grid>

                  </Grid>

                  <Box key={index} sx={{ width: '100%', mt: 2 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        flexDirection: 'row',
                        width: '90%',
                      }}
                    >

                      <KeyboardAltOutlinedIcon />
                      <Box borderBottom="1px solid black" ml={2}>
                        <Typography fontSize={16}>{job.jobPosition}</Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        flexDirection: 'row',
                        mt: 2,
                        width: '90%',
                      }}
                    >
                      <EmailIcon />
                      <Box ml={2}>
                        <Typography>{job.userEmail}</Typography>
                      </Box>
                    </Box>

                    {selectedBoxData.gender && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'left',
                          flexDirection: 'row',
                          mt: 2,
                          width: '90%',
                        }}
                      >
                        <PersonIcon />
                        <Box ml={2}>
                          <Typography>{job.userEmail}</Typography>
                        </Box>
                      </Box>
                    )}

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        flexDirection: 'row',
                        mt: 2,
                        width: '90%',
                      }}
                    >
                      <RoomOutlinedIcon />
                      <Box ml={2}>
                        <Typography>{job.certification.state}</Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        flexDirection: 'row',
                        mt: 2,
                        width: '90%',
                      }}
                    >
                      <DvrIcon />
                      <Box ml={2}>
                        <Typography>Experience - {job.experience}</Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        flexDirection: 'row',
                        mt: 2,
                        width: '90%',
                      }}
                    >
                      <RoomOutlinedIcon />
                      <Box ml={2}>
                        <Typography>Intrested Job Location - {job.jobState} , {job.city}</Typography>
                      </Box>
                    </Box>
                    {/* <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                mt: 2,
                                                width: '100%',
                                            }}
                                        >
                                            <Box
                                                sx={{

                                                    backgroundColor: '#3C2565',
                                                    opacity: 0.4,
                                                    mr: 2,
                                                    width: 'auto',
                                                    height: '35px',
                                                    borderRadius: 3,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: '8px 12px',
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        textAlign: 'center',
                                                        color: '#ffffff',
                                                        fontWeight: 700,
                                                        fontFamily: 'Arial, sans-serif'
                                                    }}
                                                >
                                                    ${job.salary}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{

                                                    backgroundColor: '#3C2565',
                                                    opacity: 0.4,
                                                    mr: 2,
                                                    width: 'auto',
                                                    height: '35px',
                                                    borderRadius: 3,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: '8px 12px',
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        textAlign: 'center',
                                                        color: '#ffffff',
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {job.employmentType}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{

                                                    backgroundColor: '#3C2565',
                                                    opacity: 0.4,
                                                    mr: 2,
                                                    width: 'auto',
                                                    height: '35px',
                                                    borderRadius: 3,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: '8px 12px',
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        textAlign: 'center',

                                                        color: '#ffffff',
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {job.jobType}
                                                </Typography>
                                            </Box>
                                        </Box> */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        flexDirection: 'row',
                        mt: 2,
                        width: '90%',
                        mb: 2,

                      }}
                    >
                      <CircleIcon sx={{ color: 'gray' }} />


                      <Typography sx={{ fontFamily: 'Arial, sans-serif' }}> Availibility -  {job.joiningDate} </Typography>
                    </Box>

                  </Box>
                </Grid>
              ))
            )}


            <Grid container xs={12} md={12} lg={12} justifyContent='center' sx={{ mb: { xs: 2 } }}>

              <Grid item >
                <Pagination
                  variant="outlined" shape="rounded"
                  count={totalPages}
                  page={page}
                  onChange={handleChangePage}
                  sx={{
                    '& .MuiPaginationItem-root': {
                      border: '1px solid',
                      color: '#3C2565',
                      minWidth: 40,
                      minHeight: 40,
                      cursor: 'pointer',
                      '&.Mui-selected': {

                        backgroundColor: '#D8D1E3',
                        color: '#3C2565',
                      },
                    },
                  }}



                />
              </Grid>
            </Grid>

          </Grid>

          <Grid item xs={11} md={7} lg={7} ml={{ xs: 0, md: 4, lg: 4 }} p={{ xs: 3, md: 3, lg: 5 }} bgcolor='white' borderRadius={8}>
            {selectedBoxData ? (
              <Grid item xs={12} md={12} lg={12} sx={{ borderRadius: 8, bgcolor: 'white' }}>
                <Grid item xs={12} md={6} lg={12}>

                  <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', flexDirection: 'row' }}>
                    <Avatar alt='User Avatar' sx={{ width: 40, height: 40, mr: 2 }} />

                    <Grid component={Typography} fontSize={18} fontWeight={600}>
                      {selectedBoxData.firstName}  {selectedBoxData.lastName}
                    </Grid>

                  </Grid>

                </Grid>
                <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', flexDirection: 'row', mt: 2 }}>
                  <KeyboardAltOutlinedIcon />
                  <Grid borderBottom='1px solid black' ml={2} >
                    <Typography fontSize={16}>{selectedBoxData.jobPosition}</Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', flexDirection: 'row', mt: 2, width: '100%' }}>
                  <PersonIcon />
                  <Grid ml={2} >
                    <Typography fontSize='16px'>{selectedBoxData.userEmail}</Typography>
                  </Grid>
                </Grid>

                <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', flexDirection: 'row', mt: 2, width: '100%' }}>
                  <CallIcon />
                  <Grid ml={2} >
                    <Typography fontSize='16px'>{selectedBoxData.countryCode}{selectedBoxData.mobileNumber}</Typography>
                  </Grid>
                </Grid>


                {selectedBoxData.gender && (
                  <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', flexDirection: 'row', mt: 2, width: '100%' }}>
                    <CallIcon />
                    <Grid ml={2}>
                      <Typography fontSize='16px'>{selectedBoxData.gender}</Typography>
                    </Grid>
                  </Grid>
                )}


                <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', flexDirection: 'row', mt: 2, width: '100%' }}>
                  <RoomOutlinedIcon />
                  <Grid ml={2} >
                    <Typography fontSize='16px'>Intrested Job Location - {selectedBoxData.jobState} ,{selectedBoxData.city}</Typography>
                  </Grid>
                </Grid>




                <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', flexDirection: 'column', mt: 2, width: '100%', mb: 2 }}>
                  <Grid sx={{

                    display: 'flex',
                    justifyContent: 'start',
                    width: '100%',
                    mb: 2

                  }}>
                    <Typography fontSize='18px' fontWeight={500}>
                      Availibility	:   {selectedBoxData.joiningDate}
                    </Typography>


                  </Grid>

                  <Grid sx={{
                    borderLeft: '4px solid #FF6900',
                    display: 'flex',
                    justifyContent: 'start',
                    width: '100%',


                  }}>
                    <Typography fontSize='18px' fontWeight={500}>
                      Intrest in a Job	:
                    </Typography>


                  </Grid>

                  <Grid sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }} width='100%'>
                    <Typography fontSize='16px' mb={2}>- {selectedBoxData.intrest}</Typography>
                  </Grid>


                  <Grid sx={{
                    borderLeft: '4px solid #FF6900',
                    display: 'flex',
                    justifyContent: 'start',
                    width: '100%',
                    mb: 2
                  }}>
                    <Typography fontSize='18px' fontWeight={500}>
                      Expertise and community management
                    </Typography>
                  </Grid>

                  <Grid sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }} width='100%'>
                    <Typography fontSize='16px' mb={2}>

                      {selectedBoxData.expertise && (
                        (() => {
                          // Check if all expertise categories are empty
                          const allEmpty = Object.values(selectedBoxData.expertise).every(array => array.length === 0);

                          if (allEmpty) {
                            return <Typography>- No data available</Typography>;
                          }

                          return (
                            <>
                              {Object.keys(selectedBoxData.expertise).map((key) => {
                                const expertiseArray = selectedBoxData.expertise[key];

                                // Only show categories with non-empty arrays
                                if (expertiseArray.length === 0) return null;

                                // Use the mapped key names or fallback to the original key
                                const displayName = expertiseKeyMapping[key] || key;
                                return (
                                  <Grid key={key} sx={{ mt: 2 }}>
                                    <Typography fontWeight={600} fontSize={16}>{displayName}</Typography>
                                    <ul>
                                      {expertiseArray.map((item, index) => (
                                        <li key={index}>
                                          <Typography fontSize={14}>{item}</Typography>
                                        </li>
                                      ))}
                                    </ul>
                                  </Grid>
                                );
                              })}
                            </>
                          );
                        })()
                      )}

                    </Typography>

                  </Grid>

                  <Grid sx={{
                    borderLeft: '4px solid #FF6900',
                    display: 'flex',
                    justifyContent: 'start',
                    width: '100%',


                  }}>
                    <Typography fontSize='18px' fontWeight={500}>
                      Certifications	:
                    </Typography>


                  </Grid>

                  <Grid sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }} width='100%'>
                    <Typography fontSize='16px' mb={2}>
                      {selectedBoxData.certification && (
                        (() => {
                          // Check if all certification categories are empty (excluding otherOptions and state)
                          const allCertificationsEmpty = Object.keys(selectedBoxData.certification).every(
                            (key) =>
                              (Array.isArray(selectedBoxData.certification[key]) && selectedBoxData.certification[key].length === 0) ||
                              (key === "otherOptions" && !selectedBoxData.certification.otherOptions.trim())
                          );

                          if (allCertificationsEmpty && !selectedBoxData.certification.otherOptions.trim() && !selectedBoxData.certification.state) {
                            return <Typography>- No certification data available</Typography>;
                          }

                          return (
                            <>
                              {Object.keys(selectedBoxData.certification).map((key) => {
                                const certificationValue = selectedBoxData.certification[key];

                                // Skip if otherOptions is an empty string or array is empty
                                if ((Array.isArray(certificationValue) && certificationValue.length === 0) || (key === 'otherOptions' && !certificationValue.trim())) return null;

                                // Use the mapped key names or fallback to the original key
                                const displayName = certificationKeyMapping[key] || key;
                                return (
                                  <Grid key={key} sx={{ mt: 2 }}>
                                    <Typography fontWeight={600} fontSize={16}>{displayName}</Typography>
                                    {Array.isArray(certificationValue) ? (
                                      <ul>
                                        {certificationValue.map((item, index) => (
                                          <li key={index}>
                                            <Typography fontSize={14}>- {item}</Typography>
                                          </li>
                                        ))}
                                      </ul>
                                    ) : (
                                      <Typography fontSize={14}>- {certificationValue}</Typography>
                                    )}
                                  </Grid>
                                );
                              })}


                            </>
                          );
                        })()
                      )}


                    </Typography>
                  </Grid>


                  <Grid sx={{
                    borderLeft: '4px solid #FF6900',
                    display: 'flex',
                    justifyContent: 'start',
                    width: '100%',
                    mb: 2
                  }}>
                    <Typography fontSize='18px' fontWeight={500}>
                      Intrested in being mentor :
                    </Typography>
                  </Grid>

                  <Grid sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }} width='100%'>
                    <Typography fontSize='16px' mb={2}>
                      {selectedBoxData.mentor}
                    </Typography>
                  </Grid>


                  <Grid sx={{
                    borderLeft: '4px solid #FF6900',
                    display: 'flex',
                    justifyContent: 'start',
                    width: '100%',
                    mb: 2
                  }}>
                    <Typography fontSize='18px' fontWeight={500}>
                      Matches :
                    </Typography>
                  </Grid>

                  <Grid sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }} width='100%'>
                    <Typography fontSize='16px' mb={2}>
                      {selectedBoxData.matches === 'Text' && selectedBoxData.mobileNumber}
                      {selectedBoxData.matches === 'Email' && selectedBoxData.userEmail}
                      {selectedBoxData.matches === 'Both' && (
                        <>
                          {selectedBoxData.mobileNumber} <br /> {selectedBoxData.userEmail}
                        </>
                      )}
                    </Typography>
                  </Grid>








                </Grid>
              </Grid>



            ) : (

              <Box width='100%' height='100%' mb={2} sx={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderRadius: 8,
                p: 2,
                mb: 100,

                border: '1px solid transparent',
                transition: 'border 0.3s ease-in-out , bgcolor 2s ease-in-out',
                '&:hover': {
                  border: '1px solid #000',
                },
                bgcolor: 'white',
              }} >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', width: '100%', height: '100%' }}>
                  <Avatar alt='User Avatar' sx={{ width: 40, height: 40, mr: 2 }} />
                  <Box mr={3} width='80%'>
                    <Typography fontSize={18} fontWeight={600}>
                      No Job Found
                    </Typography>
                  </Box>
                  <TurnedInNotIcon />
                </Box>

              </Box>
            )
            }


          </Grid>




        </Grid>


      </Grid>
    </Box>
  );
};

export default ProfileTalent;
