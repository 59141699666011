import { Box , Typography } from '@mui/material'
import React from 'react'
import TalentDrawer from './TalentDrawer'
import { useLocation } from 'react-router-dom'
import BoxCardTalent from './BoxCardTalent'

const TalentDashboard = () => {
  const location = useLocation();
  const { talentId } = location.state || {};
  console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^", talentId);
  

  return (
    <Box display='flex'>
        <TalentDrawer talentId={talentId}/>
        <Box component="main" sx={{ flexGrow: 1, p: 3, height: '100%', marginTop: "50px", justifyContent: 'center' }}>
        <Box mb={2} width='100%' display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant="body1" component="span" fontSize='24px' fontWeight={400}>
            Dashboard
          </Typography>
        </Box>

        <BoxCardTalent id={talentId} />
      </Box>
    </Box>
  )
}

export default TalentDashboard