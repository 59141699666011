// // import React, { createContext, useReducer } from 'react';

// // const AuthContext = createContext();

// // const initialState = {
// //     isAuthenticated: false,
// //     user: null,
// //     token: null
// // };

// // const reducer = (state, action) => {
// //     switch (action.type) {
// //         case 'LOGIN':
// //             return {
// //                 ...state,
// //                 isAuthenticated: true,
// //                 user: action.payload.user,
// //                 token: action.payload.token
// //             };
// //         case 'LOGOUT':
// //             return {
// //                 ...state,
// //                 isAuthenticated: false,
// //                 user: null,
// //                 token: null
// //             };
// //         default:
// //             return state;
// //     }
// // };

// // const AuthProvider = ({ children }) => {
// //     const [state, dispatch] = useReducer(reducer, initialState);

// //     return (
// //         <AuthContext.Provider value={{ state, dispatch }}>
// //             {children}
// //         </AuthContext.Provider>
// //     );
// // };

// // export { AuthContext, AuthProvider };

// // import React, { createContext, useReducer } from 'react';

// // const AuthContext = createContext();

// // const authReducer = (state, action) => {
// //   switch (action.type) {
// //     case 'LOGIN':
// //       return { ...state, isAuthenticated: true, user: action.payload.user };
// //     case 'LOGOUT':
// //       return { ...state, isAuthenticated: false, user: null };
// //     default:
// //       return state;
// //   }
// // };

// // const AuthProvider = ({ children }) => {
// //   const [state, dispatch] = useReducer(authReducer, { isAuthenticated: false, user: null });

// //   return (
// //     <AuthContext.Provider value={{ state, dispatch }}>
// //       {children}
// //     </AuthContext.Provider>
// //   );
// // };

// // export { AuthContext, AuthProvider };

// import React, { createContext, useReducer, useEffect } from 'react';

// // Create the context
// const AuthContext = createContext();

// // Initial state
// const initialState = {
//     isAuthenticated: false,
//     user: null,
//     token: null
// };

// // Reducer function to handle authentication actions
// const reducer = (state, action) => {
//     switch (action.type) {
//         case 'LOGIN':
//             return {
//                 ...state,
//                 isAuthenticated: true,
//                 user: action.payload.user,
//                 token: action.payload.token
//             };
//         case 'LOGOUT':
//             return {
//                 ...state,
//                 isAuthenticated: false,
//                 user: null,
//                 token: null
//             };
//         default:
//             return state;
//     }
// };

// // AuthProvider component to provide auth context
// const AuthProvider = ({ children }) => {
//     const [state, dispatch] = useReducer(reducer, initialState);

//     useEffect(() => {
//         const storedToken = localStorage.getItem('token');
//         const storedUser = localStorage.getItem('user');
        
//         if (storedToken && storedUser) {
//             dispatch({
//                 type: 'LOGIN',
//                 payload: {
//                     user: JSON.parse(storedUser),
//                     token: storedToken
//                 }
//             });
//         }
//     }, []);

//     const login = (user, token) => {
//         localStorage.setItem('user', JSON.stringify(user));
//         localStorage.setItem('token', token);
//         dispatch({
//             type: 'LOGIN',
//             payload: { user, token }
//         });
//     };

//     const logout = () => {
//         localStorage.removeItem('user');
//         localStorage.removeItem('token');
//         dispatch({ type: 'LOGOUT' });
//     };

//     return (
//         <AuthContext.Provider value={{ state, login, logout }}>
//             {children}
//         </AuthContext.Provider>
//     );
// };


// export { AuthContext, AuthProvider };

// import React, { createContext, useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';

// const AuthContext = createContext();

// const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Check if user is logged in on initial load
//     const token = localStorage.getItem('token');
//     if (token) {
//       axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//       // Optionally, you can fetch user details here
//     }
//     setLoading(false);
//   }, []);

//   const login = async (email, password) => {
//     try {
//       const response = await axios.post('/api/admin/login', { email, password });
//       const { token, user } = response.data;
//       localStorage.setItem('token', token);
//       axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//       setUser(user);
//       navigate('/protected-route'); // Redirect to a protected route
//     } catch (error) {
//       console.error('Login error:', error);
//     }
//   };

//   const logout = () => {
//     localStorage.removeItem('token');
//     axios.defaults.headers.common['Authorization'] = '';
//     setUser(null);
//     navigate('/login'); // Redirect to login page
//   };

//   return (
//     <AuthContext.Provider value={{ user, loading, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export { AuthProvider, AuthContext };


// // AuthContext.jsx
// import React, { createContext, useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// export const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [currentUser, setCurrentUser] = useState(null);
//   const navigate = useNavigate();
//   const [isAuthenticated, setIsAuthenticated] = useState(
//     localStorage.getItem("isAuthenticated") === "true",
//   )

//   useEffect(() => {
//     // Simulate fetching user data
//     const user = JSON.parse(localStorage.getItem('user'));
//     console.log("in authcontext" , user , isAuthenticated);
    
//     if (user) {
//       setCurrentUser(user);
//       setIsAuthenticated(true)
//       console.log("in authcontext 221" , user , isAuthenticated);
//     } else {

//       console.log("in authcontext else");
      
//       navigate('/');
//     }
//   }, [navigate]);

//   const login = (user) => {
//     setCurrentUser(user);
//     setIsAuthenticated(true)
//     localStorage.setItem('user', JSON.stringify(user));
//     navigate('/dashboard');
//   };

//   const logout = () => {
//     setCurrentUser(null);
//     setIsAuthenticated(null);
//     localStorage.removeItem('user');
//     navigate('/');
//   };

//   return (
//     <AuthContext.Provider value={{ currentUser, login, logout , isAuthenticated }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// import React, { createContext, useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// // Create the AuthContext
// export const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//     const [isAuthenticated, setIsAuthenticated] = useState(false);
//     const [currentUser, setCurrentUser] = useState(null);
//     const navigate = useNavigate();

//     console.log("in autyhcontext - authentication" , isAuthenticated);
    

//     // Function to handle login
//     const login = (user, token) => {
//         localStorage.setItem('token', token);
//         setIsAuthenticated(true);
//         navigate('/dashboard');
//     };

//     // Function to handle logout
//     // const logout = () => {
//     //     localStorage.removeItem('token');
//     //     setIsAuthenticated(false);
//     //     navigate('/');
//     // };

//     const logout = () => {
//       localStorage.removeItem('token');
//       setIsAuthenticated(false);
//       navigate('/');
//   };

//     // Check if the user is authenticated when the app loads
//     // useEffect(() => {
//     //     const token = localStorage.getItem('token');
//     //     if (token) {
//     //         setIsAuthenticated(true);
//     //     }
//     // }, []);

//     useEffect(() => {
//       const token = localStorage.getItem('token');
//       console.log("Token found:", token);
//       if (token) {
//           setIsAuthenticated(true);
//           console.log("User is authenticated");
//       } else {
//           console.log("User is not authenticated");
//       }
//   }, []);
  

//     return (
//         <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
//             {children}
//         </AuthContext.Provider>
//     );
// };


// import React, { createContext, useReducer, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// // Create the AuthContext
// export const AuthContext = createContext();

// // Initial state
// const initialState = {
//     isAuthenticated: false,
//     currentUser: null,
// };

// // Reducer function to manage state transitions
// const authReducer = (state, action) => {
//     switch (action.type) {
//         case 'LOGIN':
//             return {
//                 ...state,
//                 isAuthenticated: true,
//                 currentUser: action.payload.user,
//             };
//         case 'LOGOUT':
//             return {
//                 ...state,
//                 isAuthenticated: false,
//                 currentUser: null,
//             };
//         default:
//             return state;
//     }
// };

// export const AuthProvider = ({ children }) => {
//     const [state, dispatch] = useReducer(authReducer, initialState);
//     const navigate = useNavigate();

//     // Function to handle login
//     const login = (user, token) => {
//         localStorage.setItem('token', token);
//         dispatch({ type: 'LOGIN', payload: { user } });
//         navigate('/dashboard');
//     };

//     // Function to handle logout
//     const logout = () => {
//         localStorage.removeItem('token');
//         dispatch({ type: 'LOGOUT' });
//         navigate('/');
//     };

//     // Check if the user is authenticated when the app loads
//     // useEffect(() => {
//     //     const token = localStorage.getItem('token');
//     //     if (token) {
//     //         // Fetch user information here if needed
//     //         setIsAuthenticated(true);
//     //         dispatch({ type: 'LOGIN', payload: { user: null } });
//     //         // Adjust as per your user fetching logic
//     //     }
//     // }, []);

//     useEffect(() => {
//         const token = localStorage.getItem('token');
//         if (token) {
//             // Assuming you want to fetch user information here
//             // You might want to fetch the user data with the token, but for now, we'll just set isAuthenticated to true
//             // setIsAuthenticated(true);
    
//             // If you're using a dispatch to manage the state, make sure the payload matches your expected state structure
//             dispatch({ type: 'LOGIN', payload: { user: null } });
            
//             // If you want to fetch user info, add an async function here and set the user in the payload.
//         }
//     }, []);
    

//     return (
//         <AuthContext.Provider value={{ state, dispatch, login, logout }}>
//             {children}
//         </AuthContext.Provider>
//     );
// };




import React, { createContext, useEffect, useState } from "react"

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true",
  )
  const [verifyOTP, setVerifyOTP] = useState(false)
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken"),
  )

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user))
    localStorage.setItem("isAuthenticated", String(isAuthenticated))
    localStorage.setItem("verifyOTP", String(verifyOTP))
  }, [user, isAuthenticated, verifyOTP])

  const save = (user, token) => {
    setIsAuthenticated(true)
    if (user) setUser(user)
    if (token) {
      setAccessToken(token)
      localStorage.setItem("accessToken", token)
    }
  }

  const remove = () => {
    setIsAuthenticated(false)
    setUser(null)
    setAccessToken("")
    localStorage.removeItem("accessToken")
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        setUser,
        verifyOTP,
        setVerifyOTP,
        save,
        remove,
        accessToken,
        setAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}