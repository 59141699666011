// // import React from 'react';
// // import { Route, Navigate } from 'react-router-dom';
// // import { useContext } from 'react';
// // import { AuthContext } from '../contexts/AuthContext';

// // const PrivateRoute = ({ element: Component, ...rest }) => {
// //   const { state } = useContext(AuthContext);

// //   return (
// //     <Route
// //       {...rest}
// //       element={state.isAuthenticated ? Component : <Navigate to="/" />}
// //     />
// //   );
// // };

// // export default PrivateRoute;

// // import React, { useContext } from 'react';
// // import { Navigate, Outlet } from 'react-router-dom';
// // import { AuthContext } from '../contexts/AuthContext';

// // const PrivateRoute = () => {
// //   const { state } = useContext(AuthContext);

// //   if (!state.isAuthenticated) {
// //     // Redirect to login if not authenticated
// //     return <Navigate to="/" />;
// //   }

// //   return <Outlet />; // Render the child routes
// // };

// // export default PrivateRoute;

// // import React, { useContext } from 'react';
// // import { Navigate, Outlet } from 'react-router-dom';
// // import { AuthContext } from '../contexts/AuthContext';

// // const PrivateRoute = () => {
// //   const { state } = useContext(AuthContext);

// //   if (!state.isAuthenticated) {
// //     // Redirect to login if not authenticated
// //     return <Navigate to="/" />;
// //   }

// //   return <Outlet />; // Render the child routes
// // };

// // export default PrivateRoute;

// import React, { useContext } from 'react';
// import { Route, Navigate } from 'react-router-dom';
// import { AuthContext } from '../contexts/AuthContext';

// const PrivateRoute = ({ component: Component, ...rest }) => {
//     const { state } = useContext(AuthContext);

//     return (
//         <Route
//             {...rest}
//             render={props =>
//                 state.isAuthenticated ? (
//                     <Component {...props} />
//                 ) : (
//                   <Navigate to="/" />
//                 )
//             }
//         />
//     );
// };

// export default PrivateRoute;

// import React, { useContext } from 'react';
// import { Navigate, Route, useNavigate } from 'react-router-dom';
// import { AuthContext } from '../contexts/AuthContext';

// const PrivateRoute = ({ component: Component, ...rest }) => {
//   const { user, loading } = useContext(AuthContext);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         user ? <Component {...props} /> : <Navigate to="/login" />
//       }
//     />
//   );
// };

// export default PrivateRoute;

// components/PrivateRoute.jsx
// import React, { useContext } from 'react';
// import { Route, Navigate } from 'react-router-dom';
// import { AuthContext } from '../contexts/AuthContext';

// const PrivateRoute = ({ element, ...rest }) => {
//   const { currentUser } = useContext(AuthContext);

//   return (
//     <Route
//       {...rest}
//       element={
//         currentUser ? (
//           element
//         ) : (
//           <Navigate to="/" replace />
//         )
//       }
//     />
//   );
// };

// export default PrivateRoute;



