import * as React from 'react';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Paper } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import { BorderAllRounded, Height, Visibility, VisibilityOff } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Swal from 'sweetalert2';
import DrawerHeader from '../../../components/DrawerHeader';
import { useSnackbar } from 'notistack';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://dshgsonic.com/">
                DSHGSonic
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();

export const AddAdmin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

  
    

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/addAdmin`, {
                userName: email,
                password
            });

            if (response.status === 201) {
                enqueueSnackbar('Admin added successfully', {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' }
                })
                
            }
        } catch (error) {
            if (error.response && error.response.status === 409) {
                enqueueSnackbar('Admin already present', {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' }
                })
            } else {
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' }
                })
            }
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerHeader />
            <Grid
                container
                sx={{
                    height: '100%',
                    display: 'flex',
                    minHeight: '100vh'
                }}
            >
                <Grid item xs={12} sm={8} md={6} lg={5} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: isMobile ? 0 : 2,
                    margin: 'auto'
                }}>
                    <CssBaseline />
                    <Paper elevation={10} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 5,
                        borderRadius: "25px",
                        width: { xs: '90%', md: '80%' }
                    }}  >
                        <Grid container direction="column" alignItems="center" sx={{
                            borderRadius: "25px 25px 0 0"
                        }} xs={12}>
                            <Grid item>
                                <Typography component="h1" variant="h5" marginTop={2}>
                                    Add Admin
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} xs={12}>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Username"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={(e) => setEmail(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EmailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl required sx={{ mt: 1 }} fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AddAdmin;
