// import * as React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import LoginIcon from '@mui/icons-material/Login';

// function Topbar() {
//     const handleLogin = () => {
//         localStorage.clear();
//         // Add your login logic here
//     };

//     return (
//         <>
//             <AppBar position="fixed" sx={{ bgcolor: 'white', boxShadow: 'none' }} elevation={0}>
//                 <Toolbar disableGutters>
//                     <Box display="flex" alignItems="center" width="100%" flexDirection='column'>

//                         <Box display="flex" justifyContent="center" width='100%'>
//                             <Typography
//                                 component="h4"
//                                 width='100%'
//                                 height='30px'
//                                 bgcolor='#3c1767'
//                                 color='white'
//                                 sx={{
//                                     display: 'flex',
//                                     justifyContent: 'center',
//                                     alignItems: 'center',
//                                     fontSize: '14px'
//                                 }}
//                             >
//                                 Be the first to know when WePair is available in your area. Sign-up today!
//                             </Typography>

//                         </Box>
//                         <Box display='flex' alignItems='center' justifyContent='space-around' flexDirection='row' width='85%' padding='5px'>
//                             <Box width='50%'>
//                                 <img
//                                     src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
//                                     alt="Logo"
//                                     style={{ width: '200px', marginRight: '5px' }}
//                                 />
//                             </Box>

//                             <Box width='50%' display='flex' alignItems='center' justifyContent='end'>
//                                 <Button
//                                     variant="outlined"
//                                     size="medium"
//                                     onClick={handleLogin}
//                                     sx={{
//                                         bgcolor: '#F77E09',
//                                         color: 'white',
//                                         height: '40px',
//                                         width: '150px',
//                                         '& .MuiButton-label': {
//                                             fontSize: '18px', // Adjust the font size as needed
//                                             fontWeight: 600, // Adjust the font weight as needed
//                                         }, '&:hover': {
//                                             backgroundColor: '#3c1767', // Replace with your desired hover color
//                                           },
//                                     }}
//                                 >
//                                     Join WePair
//                                 </Button>

//                             </Box>
//                         </Box>
//                     </Box>
//                 </Toolbar>
//             </AppBar>
//         </>
//     );
// }

// export default Topbar;


import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';

function Topbar() {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleLogin = () => {
        localStorage.clear();
        // Add your login logic here
    };

    return (
        <AppBar sx={{ position: 'fixed', bgcolor: '#E7E1F0', boxShadow: 'none' }} elevation={0}>
            <Toolbar disableGutters>
                <Box display="flex" alignItems="center" width="100%" flexDirection="column">
                    {/* <Box display="flex" justifyContent="center" width="100%">
                        <Typography
                            component="h4"
                            width="100%"
                            bgcolor="#3c1767"
                            color="white"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: isMobile ? '10px' : '14px',
                                textAlign: 'center',
                                paddingX: isMobile ? 1 : 0,
                                height: isMobile ? '15px' : '30px'
                            }}
                        >
                            Be the first to know when WePair is available in your area. Sign-up today!
                        </Typography>
                    </Box> */}
                    {/* <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-around"
                        flexDirection="row"
                        width="100%"
                        padding="5px"
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            width="auto"
                            justifyContent="flex-start"
                        >
                            <img
                                src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                                alt="Logo"
                                style={{
                                    width: isMobile ? '60px' : '150px',
                                    marginRight: '5px'
                                }}
                            />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="end"
                            width="auto"
                            justifyContent="flex-end"
                        >
                            <Button
                                variant="outlined"
                                size="medium"
                                onClick={handleLogin}
                                sx={{
                                    bgcolor: '#F77E09',
                                    color: 'white',
                                    height: isMobile ? '30px' : '40px',
                                    width: isMobile ? '100px' : '150px',
                                    fontSize: isMobile ? '10px' : '18px',
                                    fontWeight: 600,
                                    '&:hover': {
                                        backgroundColor: '#3c1767'
                                    },
                                }}
                            >
                                Join WePair
                            </Button>
                        </Box>
                    </Box> */}
                     <Box display='flex' alignItems='center' justifyContent='center' flexDirection='row' width='100%' padding='5px' mt={4}>
                            <Box width='85%' display='flex' justifyContent='left'>
                                 <img
                                    src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                                    alt="Logo"
                                    style={{  width: isMobile ? '80px' : '150px', marginRight: '5px' }}
                                />
                            </Box>

                            {/* <Box width='50%' display='flex' alignItems='center' justifyContent='end'>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    onClick={handleLogin}
                                    sx={{
                                        bgcolor: '#F77E09',
                                        color: 'white',
                                        height: isMobile ? '25px' : '40px',
                                        width: isMobile ? '110px' : '150px',
                                        '& .MuiButton-label': {
                                            fontSize: isMobile ? '5px' : '18px', 
                                            fontWeight: isMobile ? 300 : 600, 
                                        }, '&:hover': {
                                            backgroundColor: '#3c1767',
                                          },
                                    }}
                                >
                                    Join WePair
                                </Button>

                            </Box> */}
                        </Box>

                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Topbar;
