
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState, useContext } from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Paper } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import { BorderAllRounded, Height, Visibility, VisibilityOff } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../hooks/useAuth';
// import { AuthContext } from '../../contexts/AuthContext';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://dshgsonic.com/">
                DSHGSonic
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();

export const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [signInType, setSignInType] = useState('first');
    const navigate = useNavigate();
    const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));
    const { enqueueSnackbar } = useSnackbar();
    const [progress, setProgress] = useState(50); 
    // const { dispatch } = useContext(AuthContext);
    // const { login } = useContext(AuthContext);
    // const [isAuthenticated, setIsAuthenticated] = useState(false);




    const userName = 'admin';
    const adminPassword = 'Admin@123'

    const handleSwitchSignIn = () => {
        setSignInType(signInType === 'first' ? 'second' : 'first');
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };


    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     if (!email || !password) {
    //         handleFieldError();
    //     } else if (email !== userName) {
    //         handleWrongEmailError();
    //     } else if (password !== adminPassword) {
    //         handleWrongPass();
    //     } else if (email === userName && password === adminPassword) {
    //         navigate('/dashboard')
    //     } else {
    //         handleError();
    //     }
    // };
    const { save } = useAuth()

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!email || !password) {
            enqueueSnackbar('Please enter fields', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
            })
        
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userName: email, password })
            });

            const data = await response.json();
            console.log(data);
            // localStorage.setItem("token", data.token)
            // localStorage.setItem("isAuthenticated", "true")
            if (response.ok) {
                save({}, data.token)
                navigate('/dashboard');
            } else if (response.status === 401) {
                enqueueSnackbar('Wrong username or password', {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' }
                })
            } else {
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' }
                })
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong!', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' }
            })
        }
    };


    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     if (!email || !password) {
    //         handleFieldError();
    //         return;
    //     }

    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_URL}/api/login`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ userName: email, password })
    //         });

    //         const data = await response.json();

    //         if (response.ok) {
    //             // login({ user: email }, data.token);

    //             navigate('/dashboard'); // Adjust to your protected route
    //         } else if (response.status === 401) {
    //             handleWrongEmailError();
    //         } else {
    //             handleError();
    //         }
    //     } catch (error) {
    //         handleError();
    //     }
    // };

    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     if (!email || !password) {
    //         handleFieldError();
    //         return;
    //     }

    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_URL}/api/login`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ userName: email, password }),
    //         });

    //         const data = await response.json();

    //         if (response.ok) {
    //             // Store the token in local storage or any other secure place
    //             // localStorage.setItem('token', data.token);

    //             // Pass the user information and token to your login function
    //             login({ user: email }, data.token);
    //             // localStorage.setItem('token',  data.token);
    //             // setIsAuthenticated(true);
    //             // navigate('/dashboard');

    //             // Navigate to the protected route
    //             // navigate('/dashboard'); // Uncomment and adjust to your protected route
    //         } else if (response.status === 401) {
    //             handleWrongEmailError();
    //         } else {
    //             handleError();
    //         }
    //     } catch (error) {
    //         handleError();
    //     }
    // };

    



    return (
        // <ThemeProvider theme={defaultTheme}>
        <Grid
            container
            sx={{
                background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))',
                // height: '100%',
                display: 'flex',
                minHeight: '100vh'
                // padding: isMobile ? 5 : 0
            }}
        >
            <Grid item xs={12} sm={8} md={6} lg={4} sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: isMobile ? 0 : 2,
                margin: 'auto'
            }}>
                <CssBaseline />
                <Paper elevation={10} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 5,
                    borderRadius: "25px",
                    width: { xs: '90%', md: '80%' }
                }}  >
                    <Grid container direction="column" alignItems="center" sx={{
                        borderRadius: "20px 20px 0 0"
                    }} xs={12}>
                        <Grid item>
                            <img
                                src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                                alt="Logo"
                                style={{ width: '100px', marginRight: '5px' }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography component="h1" variant="h5" marginTop={2}>
                                Sign In
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} xs={12}>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Username"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => setEmail(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EmailIcon color='primary' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl required sx={{ mt: 1 }} fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={handlePasswordChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            // onClick={localStorage.clear()}
                            >
                                Sign In
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
        // </ThemeProvider>
    );
}

export default SignIn;


// import React from 'react';
// import {
//   Avatar, Button, CssBaseline, TextField, Paper, Grid, Typography,
//   Container, InputAdornment, IconButton, FormControl, InputLabel,
//   OutlinedInput, useMediaQuery
// } from '@mui/material';
// import { createTheme } from '@mui/material/styles';
// import { Visibility, VisibilityOff, Email as EmailIcon } from '@mui/icons-material';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import { useNavigate } from 'react-router-dom';
// import { useSnackbar } from 'notistack';

// const defaultTheme = createTheme();

// export const SignIn = () => {
//   const [showPassword, setShowPassword] = React.useState(false);
//   const navigate = useNavigate();
//   const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));
//   const { enqueueSnackbar } = useSnackbar();

//   const validationSchema = Yup.object({
//     email: Yup.string().min(4).required('Username is required'),
//     password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required')
//   });

//   const formik = useFormik({
//     initialValues: {
//       email: '',
//       password: ''
//     },
//     validationSchema: validationSchema,
//     onSubmit: async (values) => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_URL}/api/login`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({ userName: values.email, password: values.password })
//         });

//         if (response.ok) {
//             const { token } = response.data;
//             console.log('Token:', token);
            
//             // Store the token in localStorage
//             localStorage.setItem('token', token);
    
//             // Navigate to the dashboard
//             navigate('/dashboard');
//         } else if (response.status === 401) {
//           enqueueSnackbar('Wrong username or password', {
//             variant: 'error',
//             anchorOrigin: { vertical: 'top', horizontal: 'right' }
//           });
//         } else {
//           enqueueSnackbar('Something went wrong!', {
//             variant: 'error',
//             anchorOrigin: { vertical: 'top', horizontal: 'right' }
//           });
//         }
//       } catch (error) {
//         enqueueSnackbar('Something went wrong!', {
//           variant: 'error',
//           anchorOrigin: { vertical: 'top', horizontal: 'right' }
//         });
//       }
//     },
//   });

//   const handleClickShowPassword = () => setShowPassword((show) => !show);

//   return (
//     <Grid
//       container
//       sx={{
//         background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))',
//         display: 'flex',
//         minHeight: '100vh',
//       }}
//     >
//       <Grid item xs={12} sm={8} md={6} lg={4} sx={{
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         padding: isMobile ? 0 : 2,
//         margin: 'auto'
//       }}>
//         <CssBaseline />
//         <Paper elevation={10} sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           p: 5,
//           borderRadius: "25px",
//           width: { xs: '90%', md: '80%' }
//         }}>
//           <Grid container direction="column" alignItems="center" sx={{ borderRadius: "20px 20px 0 0" }} xs={12}>
//             <Grid item>
//               <img
//                 src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
//                 alt="Logo"
//                 style={{ width: '100px', marginRight: '5px' }}
//               />
//             </Grid>
//             <Grid item>
//               <Typography component="h1" variant="h5" marginTop={2}>
//                 Sign In
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }} xs={12}>
//             <Grid item xs={12}>
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="email"
//                 label="Username"
//                 name="email"
//                 autoComplete="email"
//                 autoFocus
//                 value={formik.values.email}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={formik.touched.email && Boolean(formik.errors.email)}
//                 helperText={formik.touched.email && formik.errors.email}
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <EmailIcon color='primary' />
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <FormControl required sx={{ mt: 1 }} fullWidth variant="outlined">
//                 <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
//                 <OutlinedInput
//                   id="outlined-adornment-password"
//                   name="password" // Ensure this matches the name in Formik's initialValues
//                   type={showPassword ? 'text' : 'password'}
//                   value={formik.values.password}
//                   onChange={formik.handleChange} // Make sure this is properly set
//                   onBlur={formik.handleBlur}
//                   error={formik.touched.password && Boolean(formik.errors.password)}
//                   endAdornment={
//                     <InputAdornment position="end">
//                       <IconButton
//                         aria-label="toggle password visibility"
//                         onClick={handleClickShowPassword}
//                         edge="end"
//                       >
//                         {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
//                       </IconButton>
//                     </InputAdornment>
//                   }
//                   label="Password"
//                 />
//                 {formik.touched.password && formik.errors.password ? (
//                   <Typography variant="caption" color="error">
//                     {formik.errors.password}
//                   </Typography>
//                 ) : null}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12}>
//               <Button
//                 type="submit"
//                 fullWidth
//                 variant="contained"
//                 sx={{ mt: 3, mb: 2 }}
//               >
//                 Sign In
//               </Button>
//             </Grid>
//           </Grid>
//         </Paper>
//       </Grid>
//     </Grid>
//   );
// };

// export default SignIn;

