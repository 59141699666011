// import React from 'react'

// const GlobalTalentInfo = () => {
//   return (
//     <div>GlobalTalentInfo</div>
//   )
// }


import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState , useContext } from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Paper } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import { BorderAllRounded, Height, Visibility, VisibilityOff } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import PhoneIcon from '@mui/icons-material/Phone';
import axios from 'axios';
// import { AuthContext } from '../../contexts/AuthContext';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://dshgsonic.com/">
                DSHGSonic
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();

export const GlobalTalentInfo = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [signInType, setSignInType] = useState('first');
    const navigate = useNavigate();
    const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));
    // const { dispatch } = useContext(AuthContext);
    // const { login } = useContext(AuthContext);
    // const [isAuthenticated, setIsAuthenticated] = useState(false);




    const userName = 'admin';
    const adminPassword = 'Admin@123'

    const handleSwitchSignIn = () => {
        setSignInType(signInType === 'first' ? 'second' : 'first');
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleFieldError = () => {
        Swal.fire({
            title: "Error",
            text: "Please fill all fields",
            icon: "warning",
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
            customClass: {
                popup: 'custom-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                console.log("email error");
            }
        });
    }

    const handleWrongPass = () => {
        Swal.fire({
            title: "Error",
            text: "Wrong Password!!",
            icon: "warning",
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
            customClass: {
                popup: 'custom-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                console.log("email error");
            }
        });
    }

    const handleWrongEmailError = () => {
        Swal.fire({
            title: "Error",
            text: "Wrong username or password",
            icon: "warning",
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
            customClass: {
                popup: 'custom-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {
               
            }
        });
    }

    const handleError = () => {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            customClass: {
                popup: 'custom-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.reload();
            }
        });
    }

   

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
          const response = await axios.get(`${process.env.REACT_APP_URL}/globaltalent/${phoneNumber}`);
          console.log("id of global talent data" , response.data.id);
          
          const id  =  response.data.id;
          navigate('/persontalentdata' , { state: { id } })
        } catch (error) {
          console.error('Error fetching talent id:', error);
          // Handle error accordingly
        }
    }


    
    
      
    return (
       
            <Grid
                container
                sx={{
                    background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))',
                   
                    display: 'flex',
                    minHeight:'100vh'
                    
                }}
            >
                <Grid item xs={12} sm={8} md={6} lg={4} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: isMobile ? 0 : 2,
                    margin: 'auto'
                }}>
                    <CssBaseline />
                    <Paper elevation={10} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 5,
                        borderRadius: "25px",
                        width: {xs : '90%' , md: '80%'}
                    }}  >
                        <Grid container direction="column" alignItems="center" sx={{
                            borderRadius: "20px 20px 0 0"
                        }} xs={12}>
                            <Grid item>
                                <img
                                    src="https://wepairhealth.com/wp-content/uploads/2023/07/logo-1.png"
                                    alt="Logo"
                                    style={{ width: '100px', marginRight: '5px' }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography component="h1" variant="h5" marginTop={2}>
                                    Talent Data
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} xs={12}>
                            <Grid item xs={12}>
                            <TextField
                                    margin="normal"
                                    variant='standard'
                                    required
                                    fullWidth
                                    id="mobileNumber"
                                    label="Mobile Number"
                                    name="mobileNumber"
                                    autoComplete="tel"
                                    // value={mobileNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PhoneIcon color='primary' />
                                            </InputAdornment>
                                        ),
                                        inputMode: 'numeric', // Ensures numeric keyboard on mobile devices
                                    }}
                                    onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}

                                />
                            </Grid>
                           
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    // onClick={localStorage.clear()}
                                >
                                    Show Information
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
       
    );
}



export default GlobalTalentInfo

