import { Box, Grid, Typography, InputAdornment, TextField, FormControl, FormControlLabel, InputLabel, IconButton, OutlinedInput, Button, Input } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import TalentDrawer from './TalentDrawer'
import axios from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';

const ChangePassTalent = () => {
    const [password, setPassword] = useState('');
    const [previouspassword, setPreviousPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPreviousPassword, setPreviousShowPassword] = useState(false);
    const [error, setError] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const { talentId } = location.state || {};



    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleShowPreviousPassword = () =>  setPreviousShowPassword((show) => !show);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handlePreviousPasswordChange = (event) => {
        setPreviousPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
      
        // Validate that password and confirm password match
        if (password !== confirmPassword) {
          enqueueSnackbar('Passwords do not match', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          return;
        }
      
        try {
          const response = await axios.post(`${process.env.REACT_APP_URL}/api/changetalentpassword`, {
            password: previouspassword,
            id: talentId,
            newPassword: confirmPassword,
          });
      
          if (response.status === 200) {
            enqueueSnackbar('Password changed successfully!', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            
          } else {
            enqueueSnackbar('Previous password not matched', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        } catch (error) {
          console.error('Error changing password:', error.response ? error.response.data : error.message);
          enqueueSnackbar('An error occurred. Please try again.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      };
    



    return (
        <Box display='flex'>
            <TalentDrawer talentId={talentId} />
            <Grid container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} xs={12} md={12} lg={12} mt={20}>
                <Grid container xs={10} md={8} lg={6} p={5} border='2px solid black' borderRadius={5} sx={{ justifyContent:'center'}}>
                    <Grid item xs={10} md={8} lg={10}>
                        <FormControl required variant='standard' fullWidth margin='normal'>
                            <InputLabel htmlFor="standard-adornment-password">Previous Password</InputLabel>
                            <Input
                                id="standard-password-input"
                                type={showPreviousPassword ? 'text' : 'password'}
                                value={previouspassword}

                                onChange={handlePreviousPasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleShowPreviousPassword}
                                            edge="end"
                                        >
                                            {showPreviousPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Previous Password"
                            />
                        </FormControl>


                    </Grid>

                    <Grid item xs={10} md={8} lg={10}>
                        <FormControl required variant='standard' fullWidth margin='normal'>
                            <InputLabel htmlFor="standard-adornment-password">Set Password</InputLabel>
                            <Input
                                id="standard-password-input"
                                type={showPassword ? 'text' : 'password'}
                                value={password}

                                onChange={handlePasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff color='primary' /> : <Visibility color='primary' />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>


                    </Grid>


                    <Grid item xs={10} md={8} lg={10} >
                        <FormControl required variant="standard" fullWidth margin='normal'>
                            <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                            <Input
                                id="outlined-adornment-confirm-password"
                                type="password"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                label="Confirm Password"
                            />
                        </FormControl>
                        <Typography variant="caption" color="textSecondary" >
                            Note : Password should contain at least one special character, one digit, one uppercase letter, and one lowercase letter
                        </Typography>
                        {error && <Typography color="error">{error}</Typography>}
                    </Grid>

                    <Grid item xs={9}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2, borderRadius: 4 }}
                            onClick={handleSubmit}
                        >
                            Change Password
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ChangePassTalent