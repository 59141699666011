import { Box, Grid, IconButton, Paper, Tooltip, Typography, useTheme , Button} from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Navigate, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthContext } from "../contexts/AuthContext";







export const HeaderTopbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate()

  // const { logout } = useContext(AuthContext);





  const handleLogout = () => {
    localStorage.clear();
    navigate('/')
  }

  const handleError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      customClass: {
        popup: 'custom-swal'
    }
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the window after clicking "OK"
        window.location.reload();
      }
    });
  }


  


  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">


      <Box display="flex" justifyContent="flex-end" alignItems="center" >
      <Grid item xs={1} sm={6} md={4}>
        <Tooltip title="Logout">
          {/* <IconButton onClick={handleLogout}>
            <PersonOutlineOutlinedIcon sx={{color:'white'}}/>
          </IconButton> */}
          <Button 
              onClick={handleLogout} 
              startIcon={<LogoutIcon />} 
              sx={{ color: 'white', textTransform: 'none' }}
            >
              Logout
            </Button>
        </Tooltip>
        </Grid>
      </Box>




    </Box>
  )
}

export default HeaderTopbar;
