
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import Swal from 'sweetalert2';
import { DrawerHeader } from '../../components/DrawerHeader';
import { ColorModeContext, tokens } from "../../theme";
import { useNavigate } from 'react-router-dom';

import TalentDetails from './Talent/TalentDetails';
import BoxCard from './Cardbox';

export const MainDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const colorMode = useContext(ColorModeContext);
  // const navigate = useNavigate();



  const [talentData, setTalentData] = useState([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);







  return (
    <Box sx={{ display: 'flex' }}>
      <DrawerHeader />
      <Box component="main" sx={{ flexGrow: 1, p: 3, height: '100%', marginTop: "50px", justifyContent: 'center' }}>
        <Box mb={2} width='100%' display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant="body1" component="span" fontSize='24px' fontWeight={400}>
            Dashboard
          </Typography>
        </Box>

        <BoxCard />
      </Box>
    </Box>
  );
};

export default MainDashboard;
