import { ColorModeContext, useMode } from "./theme";
import {  CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Router, Routes } from "react-router-dom";
import OrganizationProfile from "./pages/user/OrganizationProfile";
import FLPHPsForm from "./pages/user/FLPHPsForm";
import { DrawerHeader } from "./components/DrawerHeader";
import Talent from "./pages/admin/Talent/Talent";
import SignIn from "./pages/admin/SignIn";
import TermsAndConditions from "./pages/user/TermsAndConditions";
import OnBoardingNew from "./pages/user/OnBoardingNew";
import HealthOnboarding from "./pages/user/HealthOnboarding";
import Profile from "./pages/admin/Profile";
import TalentDetails  from "./pages/admin/Talent/TalentDetails";
import MainDashboard from "./pages/admin/Dashboard";
import HiringDetails from "./pages/admin/Hiring/HiringDetails";
import Hiring from "./pages/admin/Hiring/Hiring";
import BoxCard from "./pages/admin/Cardbox";
import EditTalent from "./pages/admin/Talent/EditTalent";
import AdvanceDetails, { AdvanceTalentDetails } from "./pages/admin/AdvanceDetails";
import EditOnboarding from "./pages/admin/Hiring/EditOnboarding";
import JobListing from "./pages/user/JobListing";
import AddJobListing from "./pages/admin/Hiring/AddJoblisting";
import JobDetails from "./pages/admin/Hiring/JobDetails";
import Jobs from "./pages/admin/Hiring/Jobs";
import EditJob from "./pages/admin/Hiring/EditJob";
import CityList from "./pages/user/CityList";
import New from "./pages/user/New";
import { FormEditTalent } from "./pages/admin/Talent/FormEditTalent";
import GlobalTalentData from "./pages/user/userAdmin/Organization/GlobalTalentData";
import Admins from "./pages/admin/Admin/Admins";
import AddAdmin from "./pages/admin/Admin/AddAdmin";
import { AuthProvider } from '../src/contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import TalentLogin from "./pages/user/userAdmin/Talent/TalentLogin";
import SignUp from "./pages/user/SignUp";
import EmailVrification from "./pages/user/EmailVrification";
import TalentDashboard from "./pages/user/userAdmin/Talent/TalentDashboard";
import TalentDrawer from "./pages/user/userAdmin/Talent/TalentDrawer";
// import {TalentProfile } from "./pages/user/userAdmin/Talent/TalentProfile";
import { ForgotPassword } from "./pages/user/userAdmin/Talent/ForgotPassword";
import OrganizationDashboard from "./pages/user/userAdmin/Organization/DashboardOrganization";
import ProfileOrganization from "./pages/user/userAdmin/Organization/ProfileOrganization";
import OrganizationLogin from "./pages/user/userAdmin/Organization/LoginOrganization";
import { ForgotPassOrganization } from "./pages/user/userAdmin/Organization/ForgotPassOrganization";
import JobsOrganization from "./pages/user/userAdmin/Organization/JobsOrganization";
import AddJobOrganization from "./pages/user/userAdmin/Organization/AddJobOrganization";
import JobDetailsOrganization from "./pages/user/userAdmin/Organization/JobDetailsOrganization";
import EditJobOrganization from "./pages/user/userAdmin/Organization/EditJobOrganization";
import GlobalTalentInfo from "./pages/user/GlobalTalentInfo";
import TalentJobs from "./pages/user/userAdmin/Talent/TalentJobs";
import { TalentProfile } from "./pages/user/userAdmin/Talent/TalentProfile";
import ProfileTalent from "./pages/user/userAdmin/Organization/ProfileTalent";
import ChangePassTalent from "./pages/user/userAdmin/Talent/ChangePassTalent";
import ChangePassOrganization from "./pages/user/userAdmin/Organization/ChangePassOrganization";
import { Navigate } from "react-router-dom";
import { useEffect , useState } from "react";
import axios from "axios";
import { useAuth } from "./hooks/useAuth";







function App() {
const [theme, colorMode] = useMode();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  console.log(isAuthenticated);
  
  const { isAuthenticated:auth } = useAuth()
  
  useEffect(() => {
    const verifyToken = async () => {
      try {
      

        const token = localStorage.getItem("token")

        const response = await axios.post(`${process.env.REACT_APP_URL}/verify-token`, {}, {
          headers: {
            Authorization: token,
          },
        });

        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    verifyToken();
  }, []);
  return (
   
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content">
          
                  <Routes>
                  <Route path="/" element={<SignIn />} />
                  <Route path="/talentlogin" element={<TalentLogin/>}/>
                  <Route path="/organizatiologin" element={<OrganizationLogin/>}/>
                  <Route path="/signup" element={<SignUp/>}/>
                  <Route path="/emailverification" element={<EmailVrification/>}/>
                  <Route path="/citylist" element={<CityList/>}/>
                  <Route path="/healthonboarding" element={<HealthOnboarding/>}/>
                  <Route path="/onboardingform" element={<OnBoardingNew/>}/>
                  <Route path="/forgottalentpassword" element={<ForgotPassword/>}/>
                { auth ? (
                  <>
                    
                    {/* <Route path="/organizationprofile" element={<OrganizationProfile/>}/> */}
                    <Route path="/flphpsform" element={<FLPHPsForm/>}/>
                    <Route path="/joblisting" element={<JobListing/>}/>
                    <Route path="/talentdata" element={<Talent/>}/>
                    <Route path="/home" element={<DrawerHeader/>}/>
                    <Route path="/edittalent" element={<EditTalent />} />
                    <Route path="/details" element={<TalentDetails/>}/>
                    <Route path="/hiringdetails" element={<HiringDetails/>}/>
                    <Route path="/hiringdata" element={<Hiring/>}/>
                    <Route path="/dashboard" element={<MainDashboard/>}/>
                    <Route path="/card" element={<BoxCard/>}/>
                    <Route path="/advancetalentdetails/:phoneNumber" element={<AdvanceTalentDetails/>}/>
                    <Route path="/editonboarding" element={<EditOnboarding/>}/>
                    <Route path="/addjob/:id" element={<AddJobListing/>}/>
                    <Route path="/jobdetails" element={<JobDetails/>}/>
                    <Route path="/jobs" element={<Jobs/>}/>
                    <Route path="/editjob" element={<EditJob/>}/>
                    <Route path="/new" element={<New/>}/>
                    <Route path="/formonboardingtalent" element={<FormEditTalent/>}/>
                    <Route path="/persontalentdata" element={<GlobalTalentData/>}/>
                    <Route path="/admins" element={<Admins/>}/>
                    <Route path="/addadmin" element={<AddAdmin/>}/>   
                    <Route path="/talentdashboard" element={<TalentDashboard/>}/>
                    <Route path="/talentdrawer" element={<TalentDrawer/>}/>
                    <Route path="/talentprofile" element={<TalentProfile/>}/>
                    <Route path="/organizationdashboard" element={<OrganizationDashboard/>}/>
                    <Route path="/organizationprofile" element={<ProfileOrganization/>}/>
                    <Route path="/organizationforgotpassword" element={<ForgotPassOrganization/>}/>
                    <Route path="/organizationjobs" element={<JobsOrganization/>}/>
                    <Route path="/addjoborganization" element={<AddJobOrganization/>}/>
                    <Route path="/jobdetailsorganization" element={<JobDetailsOrganization/>}/>
                    <Route path="/editjoborganization" element={<EditJobOrganization/>}/>
                    <Route path="/globaltalentinformation" element={<GlobalTalentInfo/>}/>
                    <Route path="/talentjob" element={<TalentJobs/>}/>
                    <Route path="/talentprofileapply" element={<ProfileTalent/>}/>
                    <Route path="/talentchangepassword" element={<ChangePassTalent/>}/>
                    <Route path="/organizationchangepassword" element={<ChangePassOrganization/>}/>
                    </>
                ) : (
                  <Route path="*" element={<Navigate to="/" />} />
                )}
                    </Routes>
           
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>

  );
}

export default App;


// import { ColorModeContext, useMode } from "./theme";
// import { CssBaseline, ThemeProvider } from "@mui/material";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import OrganizationProfile from "./pages/user/OrganizationProfile";
// import FLPHPsForm from "./pages/user/FLPHPsForm";
// import { DrawerHeader } from "./components/DrawerHeader";
// import Talent from "./pages/admin/Talent/Talent";
// import SignIn from "./pages/admin/SignIn";
// import TermsAndConditions from "./pages/user/TermsAndConditions";
// import OnBoardingNew from "./pages/user/OnBoardingNew";
// import HealthOnboarding from "./pages/user/HealthOnboarding";
// import Profile from "./pages/admin/Profile";
// import TalentDetails from "./pages/admin/Talent/TalentDetails";
// import MainDashboard from "./pages/admin/Dashboard";
// import HiringDetails from "./pages/admin/Hiring/HiringDetails";
// import Hiring from "./pages/admin/Hiring/Hiring";
// import BoxCard from "./pages/admin/Cardbox";
// import EditTalent from "./pages/admin/Talent/EditTalent";
// import AdvanceDetails, { AdvanceTalentDetails } from "./pages/admin/AdvanceDetails";
// import EditOnboarding from "./pages/admin/Hiring/EditOnboarding";
// import JobListing from "./pages/user/JobListing";
// import AddJobListing from "./pages/admin/Hiring/AddJoblisting";
// import JobDetails from "./pages/admin/Hiring/JobDetails";
// import Jobs from "./pages/admin/Hiring/Jobs";
// import EditJob from "./pages/admin/Hiring/EditJob";
// import CityList from "./pages/user/CityList";
// import New from "./pages/user/New";
// import { FormEditTalent } from "./pages/admin/Talent/FormEditTalent";
// import GlobalTalentData from "./pages/user/GlobalTalentData";
// import Admins from "./pages/admin/Admin/Admins";
// import AddAdmin from "./pages/admin/Admin/AddAdmin";
// import { AuthProvider } from './contexts/AuthContext';
// import PrivateRoute from './components/PrivateRoute';

// function App() {
//   const [theme, colorMode] = useMode();

//   return (
//     <ColorModeContext.Provider value={colorMode}>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         <AuthProvider>
//           {/* <Router> */}
//             <div className="app">
//               <main className="content">
//                 <Routes>
//                   {/* Public Routes */}
//                   <Route path="/onboardingform" element={<OnBoardingNew />} />
//                   <Route path="/organizationprofile" element={<OrganizationProfile />} />
//                   <Route path="/flphpsform" element={<FLPHPsForm />} />
//                   <Route path="/healthonboarding" element={<HealthOnboarding />} />
//                   <Route path="/" element={<SignIn />} />
//                   <Route path="/citylist" element={<CityList />} />
//                   <Route path="/joblisting" element={<JobListing />} />

//                   {/* Private Routes */}
//                   <PrivateRoute path="/talentdata" element={<Talent />} />
//                   <PrivateRoute path="/home" element={<DrawerHeader />} />
//                   <PrivateRoute path="/edittalent" element={<EditTalent />} />
//                   <PrivateRoute path="/details" element={<TalentDetails />} />
//                   <PrivateRoute path="/hiringdetails" element={<HiringDetails />} />
//                   <PrivateRoute path="/hiringdata" element={<Hiring />} />
//                   <PrivateRoute path="/dashboard" element={<MainDashboard />} />
//                   <PrivateRoute path="/card" element={<BoxCard />} />
//                   <PrivateRoute path="/advancetalentdetails/:phoneNumber" element={<AdvanceTalentDetails />} />
//                   <PrivateRoute path="/editonboarding" element={<EditOnboarding />} />
//                   <PrivateRoute path="/addjob/:id" element={<AddJobListing />} />
//                   <PrivateRoute path="/jobdetails" element={<JobDetails />} />
//                   <PrivateRoute path="/jobs" element={<Jobs />} />
//                   <PrivateRoute path="/editjob" element={<EditJob />} />
//                   <PrivateRoute path="/new" element={<New />} />
//                   <PrivateRoute path="/formonboardingtalent" element={<FormEditTalent />} />
//                   <PrivateRoute path="/persontalentdata/:id" element={<GlobalTalentData />} />
//                   <PrivateRoute path="/admins" element={<Admins />} />
//                   <PrivateRoute path="/addadmin" element={<AddAdmin />} />
//                 </Routes>
//               </main>
//             </div>
//           {/* </Router> */}
//         </AuthProvider>
//       </ThemeProvider>
//     </ColorModeContext.Provider>
//   );
// }

// export default App;

// import { ColorModeContext, useMode } from "./theme";
// import { CssBaseline, ThemeProvider } from "@mui/material";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import OrganizationProfile from "./pages/user/OrganizationProfile";
// import FLPHPsForm from "./pages/user/FLPHPsForm";
// import { DrawerHeader } from "./components/DrawerHeader";
// import Talent from "./pages/admin/Talent/Talent";
// import SignIn from "./pages/admin/SignIn";
// import TermsAndConditions from "./pages/user/TermsAndConditions";
// import OnBoardingNew from "./pages/user/OnBoardingNew";
// import HealthOnboarding from "./pages/user/HealthOnboarding";
// import Profile from "./pages/admin/Profile";
// import TalentDetails from "./pages/admin/Talent/TalentDetails";
// import MainDashboard from "./pages/admin/Dashboard";
// import HiringDetails from "./pages/admin/Hiring/HiringDetails";
// import Hiring from "./pages/admin/Hiring/Hiring";
// import BoxCard from "./pages/admin/Cardbox";
// import EditTalent from "./pages/admin/Talent/EditTalent";
// import AdvanceDetails, { AdvanceTalentDetails } from "./pages/admin/AdvanceDetails";
// import EditOnboarding from "./pages/admin/Hiring/EditOnboarding";
// import JobListing from "./pages/user/JobListing";
// import AddJobListing from "./pages/admin/Hiring/AddJoblisting";
// import JobDetails from "./pages/admin/Hiring/JobDetails";
// import Jobs from "./pages/admin/Hiring/Jobs";
// import EditJob from "./pages/admin/Hiring/EditJob";
// import CityList from "./pages/user/CityList";
// import New from "./pages/user/New";
// import { FormEditTalent } from "./pages/admin/Talent/FormEditTalent";
// import GlobalTalentData from "./pages/user/GlobalTalentData";
// import Admins from "./pages/admin/Admin/Admins";
// import AddAdmin from "./pages/admin/Admin/AddAdmin";
// import { AuthProvider } from './contexts/AuthContext';
// import PrivateRoute from './components/PrivateRoute';
// import { useNavigate } from "react-router-dom";

// function App() {
//   const [theme, colorMode] = useMode();
  

//   return (
//     <ColorModeContext.Provider value={colorMode}>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         {/* <Router> */}
//           <AuthProvider>
//             <div className="app">
//               <main className="content">
//                 <Routes>
//                   {/* Public Routes */}
//                   <Route path="/onboardingform" element={<OnBoardingNew />} />
//                   <Route path="/organizationprofile" element={<OrganizationProfile />} />
//                   <Route path="/flphpsform" element={<FLPHPsForm />} />
//                   <Route path="/healthonboarding" element={<HealthOnboarding />} />
//                   <Route path="/" element={<SignIn />} />
//                   <Route path="/citylist" element={<CityList />} />
//                   <Route path="/joblisting" element={<JobListing />} />

//                   {currentUser && (
//                         <>
//                           <Route path="/talentdata" element={<Talent />} />
//                           <Route path="/home" element={<DrawerHeader />} />
//                           <Route path="/edittalent" element={<EditTalent />} />
//                           <Route path="/details" element={<TalentDetails />} />
//                           <Route path="/hiringdetails" element={<HiringDetails />} />
//                           <Route path="/hiringdata" element={<Hiring />} />
//                           <Route path="/dashboard" element={<MainDashboard />} />
//                           <Route path="/card" element={<BoxCard />} />
//                           <Route path="/advancetalentdetails/:phoneNumber" element={<AdvanceTalentDetails />} />
//                           <Route path="/editonboarding" element={<EditOnboarding />} />
//                           <Route path="/addjob/:id" element={<AddJobListing />} />
//                           <Route path="/jobdetails" element={<JobDetails />} />
//                           <Route path="/jobs" element={<Jobs />} />
//                           <Route path="/editjob" element={<EditJob />} />
//                           <Route path="/new" element={<New />} />
//                           <Route path="/formonboardingtalent" element={<FormEditTalent />} />
//                           <Route path="/persontalentdata/:id" element={<GlobalTalentData />} />
//                           <Route path="/admins" element={<Admins />} />
//                           <Route path="/addadmin" element={<AddAdmin />} />
//                         </>
//                       )}
//                       {/* Redirect to login if not authenticated */}
//                       <Route path="*" element={<Navigate to="/" />} />
                      

//                   {/* Private Routes */}
//                   {/* <PrivateRoute path="/talentdata" element={Talent} />
//                   <PrivateRoute path="/home" element={DrawerHeader} />
//                   <PrivateRoute path="/edittalent" element={EditTalent} />
//                   <PrivateRoute path="/details" element={TalentDetails} />
//                   <PrivateRoute path="/hiringdetails" element={HiringDetails} />
//                   <PrivateRoute path="/hiringdata" element={Hiring} />
//                   <PrivateRoute path="/dashboard" element={MainDashboard} />
//                   <PrivateRoute path="/card" element={BoxCard} />
//                   <PrivateRoute path="/advancetalentdetails/:phoneNumber" element={AdvanceTalentDetails} />
//                   <PrivateRoute path="/editonboarding" element={EditOnboarding} />
//                   <PrivateRoute path="/addjob/:id" element={AddJobListing} />
//                   <PrivateRoute path="/jobdetails" element={JobDetails} />
//                   <PrivateRoute path="/jobs" element={Jobs} />
//                   <PrivateRoute path="/editjob" element={EditJob} />
//                   <PrivateRoute path="/new" element={New} />
//                   <PrivateRoute path="/formonboardingtalent" element={FormEditTalent} />
//                   <PrivateRoute path="/persontalentdata/:id" element={GlobalTalentData} />
//                   <PrivateRoute path="/admins" element={Admins} />
//                   <PrivateRoute path="/addadmin" element={AddAdmin} />  */}

                  
//                 </Routes>
//               </main>
//             </div>
//           </AuthProvider>
//         {/* </Router> */}
//       </ThemeProvider>
//     </ColorModeContext.Provider>
//   );
// }

// export default App;

// import { ColorModeContext, useMode } from "./theme";
// import { CssBaseline, ThemeProvider } from "@mui/material";
// import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// import OrganizationProfile from "./pages/user/OrganizationProfile";
// import FLPHPsForm from "./pages/user/FLPHPsForm";
// import { DrawerHeader } from "./components/DrawerHeader";
// import Talent from "./pages/admin/Talent/Talent";
// import SignIn from "./pages/admin/SignIn";
// import TermsAndConditions from "./pages/user/TermsAndConditions";
// import OnBoardingNew from "./pages/user/OnBoardingNew";
// import HealthOnboarding from "./pages/user/HealthOnboarding";
// import Profile from "./pages/admin/Profile";
// import TalentDetails from "./pages/admin/Talent/TalentDetails";
// import MainDashboard from "./pages/admin/Dashboard";
// import HiringDetails from "./pages/admin/Hiring/HiringDetails";
// import Hiring from "./pages/admin/Hiring/Hiring";
// import BoxCard from "./pages/admin/Cardbox";
// import EditTalent from "./pages/admin/Talent/EditTalent";
// import AdvanceDetails, { AdvanceTalentDetails } from "./pages/admin/AdvanceDetails";
// import EditOnboarding from "./pages/admin/Hiring/EditOnboarding";
// import JobListing from "./pages/user/JobListing";
// import AddJobListing from "./pages/admin/Hiring/AddJoblisting";
// import JobDetails from "./pages/admin/Hiring/JobDetails";
// import Jobs from "./pages/admin/Hiring/Jobs";
// import EditJob from "./pages/admin/Hiring/EditJob";
// import CityList from "./pages/user/CityList";
// import New from "./pages/user/New";
// import { FormEditTalent } from "./pages/admin/Talent/FormEditTalent";
// // import GlobalTalentData from "./pages/user/GlobalTalentData";
// import Admins from "./pages/admin/Admin/Admins";
// import AddAdmin from "./pages/admin/Admin/AddAdmin";
// // import { AuthProvider, AuthContext   } from './contexts/AuthContext';
// import PrivateRoute from "./layout/PrivateRoute";
// import TalentLogin from "./pages/user/userAdmin/Talent/TalentLogin";
// import SignUp from "./pages/user/SignUp";
// import EmailVrification from "./pages/user/EmailVrification";
// import TalentDashboard from "./pages/user/userAdmin/Talent/TalentDashboard";
// import TalentDrawer from "./pages/user/userAdmin/Talent/TalentDrawer";
// import { TalentProfile } from "./pages/user/userAdmin/Talent/TalentProfile";
// import { ForgotPassword } from "./pages/user/userAdmin/Talent/ForgotPassword";
// import OrganizationDashboard from "./pages/user/userAdmin/Organization/DashboardOrganization";
// import ProfileOrganization from "./pages/user/userAdmin/Organization/ProfileOrganization";
// import OrganizationLogin from "./pages/user/userAdmin/Organization/LoginOrganization";
// import { ForgotPassOrganization } from "./pages/user/userAdmin/Organization/ForgotPassOrganization";
// import JobsOrganization from "./pages/user/userAdmin/Organization/JobsOrganization";
// import AddJobOrganization from "./pages/user/userAdmin/Organization/AddJobOrganization";
// import JobDetailsOrganization from "./pages/user/userAdmin/Organization/JobDetailsOrganization";
// import EditJobOrganization from "./pages/user/userAdmin/Organization/EditJobOrganization";
// import GlobalTalentInfo from "./pages/user/GlobalTalentInfo";
// import { useEffect , useState } from "react";
// import axios from "axios";
// import { useAuth } from "./hooks/useAuth";


// function App() {
//   const [theme, colorMode] = useMode();
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   console.log(isAuthenticated);
  
//   const { isAuthenticated:auth } = useAuth()
  
//   useEffect(() => {
//     const verifyToken = async () => {
//       try {
      

//         const token = localStorage.getItem("token")

//         const response = await axios.post(`${process.env.REACT_APP_URL}/verify-token`, {}, {
//           headers: {
//             Authorization: token,
//           },
//         });

//         if (response.status === 200) {
//           setIsAuthenticated(true);
//         } else {
//           setIsAuthenticated(false);
//         }
//       } catch (error) {
//         setIsAuthenticated(false);
//       }
//     };

//     verifyToken();
//   }, []);

//   return (
//     <ColorModeContext.Provider value={colorMode}>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         {/* <Router> */}
//           <div className="app">
//             <main className="content">
//               <Routes>
//                 <Route path="/" element={<SignIn />} />
//                 { auth ? (
//                   <>
//                     <Route path="/onboardingform" element={<OnBoardingNew />} />
//                     <Route path="/flphpsform" element={<FLPHPsForm />} />
//                     <Route path="/healthonboarding" element={<HealthOnboarding />} />
//                     <Route path="/citylist" element={<CityList />} />
//                     <Route path="/joblisting" element={<JobListing />} />
//                     <Route path="/talentdata" element={<Talent />} />
//                     <Route path="/home" element={<DrawerHeader />} />
//                     <Route path="/edittalent" element={<EditTalent />} />
//                     <Route path="/details" element={<TalentDetails />} />
//                     <Route path="/hiringdetails" element={<HiringDetails />} />
//                     <Route path="/hiringdata" element={<Hiring />} />
//                     <Route path="/dashboard" element={<MainDashboard />} />
//                     <Route path="/card" element={<BoxCard />} />
//                     <Route path="/advancetalentdetails/:phoneNumber" element={<AdvanceTalentDetails />} />
//                     <Route path="/editonboarding" element={<EditOnboarding />} />
//                     <Route path="/addjob/:id" element={<AddJobListing />} />
//                     <Route path="/jobdetails" element={<JobDetails />} />
//                     <Route path="/jobs" element={<Jobs />} />
//                     <Route path="/editjob" element={<EditJob />} />
//                     <Route path="/new" element={<New />} />
//                     <Route path="/formonboardingtalent" element={<FormEditTalent />} />
//                     {/* <Route path="/persontalentdata" element={<GlobalTalentData />} /> */}
//                     <Route path="/admins" element={<Admins />} />
//                     <Route path="/addadmin" element={<AddAdmin />} />
//                     <Route path="/talentdashboard" element={<TalentDashboard />} />
//                     <Route path="/talentdrawer" element={<TalentDrawer />} />
//                     <Route path="/talentprofile" element={<TalentProfile />} />
//                     <Route path="/organizationdashboard" element={<OrganizationDashboard />} />
//                     <Route path="/organizationprofile" element={<ProfileOrganization />} />
//                     <Route path="/organizationjobs" element={<JobsOrganization />} />
//                     <Route path="/addjoborganization" element={<AddJobOrganization />} />
//                     <Route path="/jobdetailsorganization" element={<JobDetailsOrganization />} />
//                     <Route path="/editjoborganization" element={<EditJobOrganization />} />
//                     <Route path="/globaltalentinformation" element={<GlobalTalentInfo />} />
//                   </>
//                 ) : (
//                   <Route path="*" element={<Navigate to="/" />} />
//                 )}
//               </Routes>
//             </main>
//           </div>
//         {/* </Router> */}
//       </ThemeProvider>
//     </ColorModeContext.Provider>
//   );
// }

// export default App;


