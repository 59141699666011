import * as React from 'react';
import { Paper, Box, Typography, MenuItem, Checkbox, FormControlLabel, Stepper, Step, StepLabel, useMediaQuery } from '@mui/material';
import { TextField, Button } from '@mui/material';
import { useState } from 'react';
import Swal from 'sweetalert2'
import Topbar from '../../components/Topbar';

const fields = [
  'Organization Information',
  'Basic Information',
  'Number of Employees',
  'Organization Type',
  'Industry Hiring For',
  'Agree to Terms and Conditions',
];

const organizationTypes = ['Health Center', 'CBO', 'Health Plan', 'Nonprofit'];
const industryOptions = ['CHW/P/CHR', 'Doula', 'Peer Support Specialist', 'Wellness Coach'];

export const OnboardingForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({
    companyName: '',
    representativeFullName: '',
    zipCode: '',
    workEmail: '',
    phoneNumber: '', // Required
    numberOfEmployees: '',
    organizationType: '', // New field
    industryHiringFor: '', // Required
    agreeToTerms: false,
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: fieldValue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form values:', formValues);

    // Add your submission logic here
  };


  const handleJoin = () => {
    Swal.fire({
      title: "successful.",
      text: "Our team will respond to your request",
      icon: "success",
      showCancelButton: false, // Set to false to hide the cancel button
      showConfirmButton: true, // Set to true to show the confirm (OK) button
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
      customClass: {
        popup: 'custom-swal'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Topbar/>
      <Box component="main" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }} marginTop="120px">
        <Paper elevation={20} style={{ width: '100%', height: '100%', background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))' }}>
          <Box height='100%' width='100%' display='flex' alignItems='center' justifyContent='center'>
            <Box
              sx={{
                width: '70%',
                height: '80%',
                marginTop: '10px',
                textAlign: 'center',
                backgroundColor: '#f0f0f0',
                alignItems: 'center',
                p: 20,
                paddingTop: 10,
                borderRadius: 8,
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Stepper activeStep={activeStep} alternativeLabel>
                {fields.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Typography variant="h4" gutterBottom sx={{ color: '#800080', fontWeight: 'bold' }}>
               Organization Profile Page
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ marginBottom: 5, color: '#800080' }}>
                {`Field ${activeStep + 1} of ${fields.length}`}
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box>
                  {fields[activeStep] === 'Agree to Terms and Conditions' ? (
                    <FormControlLabel
                      control={<Checkbox checked={formValues.agreeToTerms} onChange={handleChange} name="agreeToTerms" required />}
                      label={fields[activeStep]}
                    />
                  ) : (
                    <>
                      {fields[activeStep] === 'Organization Information' && (
                        <>
                          <TextField
                            name="companyName"
                            label="Company Name"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={formValues.companyName}
                            onChange={handleChange}
                          />
                          <TextField
                            name="representativeFullName"
                            label="Representative Full Name"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={formValues.representativeFullName}
                            onChange={handleChange}
                          />
                        </>
                      )}
                      {fields[activeStep] === 'Basic Information' && (
                        <>
                          <TextField
                            name="zipCode"
                            label="Zip Code"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={formValues.zipCode}
                            onChange={handleChange}
                          />
                          <TextField
                            name="workEmail"
                            label="Work Email"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={formValues.workEmail}
                            onChange={handleChange}
                          />
                          <TextField
                            name="phoneNumber"
                            label="Phone Number"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={formValues.phoneNumber}
                            onChange={handleChange}
                            required
                          />
                        </>
                      )}
                      {fields[activeStep] === 'Number of Employees' && (
                        <TextField
                          name="numberOfEmployees"
                          label="Number of Employees"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          value={formValues.numberOfEmployees}
                          onChange={handleChange}
                          type="number"
                        />
                      )}
                      {fields[activeStep] === 'Organization Type' && (
                        <TextField
                          name="organizationType"
                          label="Organization Type"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          value={formValues.organizationType}
                          onChange={handleChange}
                          select
                        >
                          {organizationTypes.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      {fields[activeStep] === 'Industry Hiring For' && (
                        <TextField
                          name="industryHiringFor"
                          label="Industry Hiring For"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          value={formValues.industryHiringFor}
                          onChange={handleChange}
                          select
                          required
                        >
                          {industryOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </>
                  )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
                  <Button variant="contained" disabled={activeStep === 0} onClick={handleBack} sx={{ backgroundColor: '#F77E09', color: 'white', fontWeight: 'bold' }}>
                    Back
                  </Button>
                  {fields[activeStep] !== 'Agree to Terms and Conditions' && (
                    <Button variant="contained" onClick={handleNext} sx={{ backgroundColor: '#F77E09', color: 'white', fontWeight: 'bold' }}>
                      Save & Next
                    </Button>
                  )}
                  {fields[activeStep] === 'Agree to Terms and Conditions' && (
                    <Button type="submit" variant="contained" onClick={handleJoin} sx={{ backgroundColor: '#F77E09', color: 'white', fontWeight: 'bold' }}>
                      Join
                    </Button>
                  )}
                </Box>

              </form>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
  }

//   const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

//   return (
//     <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
//       <Topbar />
//       <Box component="main" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', marginTop: isMobile ? '60px' : '120px' }}>
//         <Paper elevation={20} style={{ width: '100%', height: '100%', background: 'repeating-radial-gradient(circle at right, #ff6900, rgba(107, 70, 255, 0.59))' }}>
//           <Box height='100%' width='100%' display='flex' alignItems='center' justifyContent='center'>
//             <Box
//               sx={{
//                 width: isMobile ? '90%' : '70%',
//                 height: isMobile ? 'auto' : '80%',
//                 marginTop: '10px',
//                 textAlign: 'center',
//                 backgroundColor: '#f0f0f0',
//                 alignItems: 'center',
//                 p: isMobile ? 5 : 20,
//                 paddingTop: isMobile ? 3 : 10,
//                 borderRadius: 8,
//                 boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
//               }}
//             >
//               <Stepper activeStep={activeStep} alternativeLabel>
//                 {fields.map((label) => (
//                   <Step key={label}>
//                     <StepLabel>{label}</StepLabel>
//                   </Step>
//                 ))}
//               </Stepper>
//               <Typography variant="h4" gutterBottom sx={{ color: '#800080', fontWeight: 'bold' }}>
//                 Organization Profile Page
//               </Typography>
//               <Typography variant="body1" gutterBottom sx={{ marginBottom: 5, color: '#800080' }}>
//                 {`Field ${activeStep + 1} of ${fields.length}`}
//               </Typography>
//               <form onSubmit={handleSubmit}>
//                 <Box>
//                   {fields[activeStep] === 'Agree to Terms and Conditions' ? (
//                     <FormControlLabel
//                       control={<Checkbox checked={formValues.agreeToTerms} onChange={handleChange} name="agreeToTerms" required />}
//                       label={fields[activeStep]}
//                     />
//                   ) : (
//                     <>
//                       {fields[activeStep] === 'Organization Information' && (
//                         <>
//                           <TextField
//                             name="companyName"
//                             label="Company Name"
//                             variant="outlined"
//                             margin="normal"
//                             fullWidth
//                             value={formValues.companyName}
//                             onChange={handleChange}
//                           />
//                           <TextField
//                             name="representativeFullName"
//                             label="Representative Full Name"
//                             variant="outlined"
//                             margin="normal"
//                             fullWidth
//                             value={formValues.representativeFullName}
//                             onChange={handleChange}
//                           />
//                         </>
//                       )}
//                       {fields[activeStep] === 'Basic Information' && (
//                         <>
//                           <TextField
//                             name="zipCode"
//                             label="Zip Code"
//                             variant="outlined"
//                             margin="normal"
//                             fullWidth
//                             value={formValues.zipCode}
//                             onChange={handleChange}
//                           />
//                           <TextField
//                             name="workEmail"
//                             label="Work Email"
//                             variant="outlined"
//                             margin="normal"
//                             fullWidth
//                             value={formValues.workEmail}
//                             onChange={handleChange}
//                           />
//                           <TextField
//                             name="phoneNumber"
//                             label="Phone Number"
//                             variant="outlined"
//                             margin="normal"
//                             fullWidth
//                             value={formValues.phoneNumber}
//                             onChange={handleChange}
//                             required
//                           />
//                         </>
//                       )}
//                       {fields[activeStep] === 'Number of Employees' && (
//                         <TextField
//                           name="numberOfEmployees"
//                           label="Number of Employees"
//                           variant="outlined"
//                           margin="normal"
//                           fullWidth
//                           value={formValues.numberOfEmployees}
//                           onChange={handleChange}
//                           type="number"
//                         />
//                       )}
//                       {fields[activeStep] === 'Organization Type' && (
//                         <TextField
//                           name="organizationType"
//                           label="Organization Type"
//                           variant="outlined"
//                           margin="normal"
//                           fullWidth
//                           value={formValues.organizationType}
//                           onChange={handleChange}
//                           select
//                         >
//                           {organizationTypes.map((option) => (
//                             <MenuItem key={option} value={option}>
//                               {option}
//                             </MenuItem>
//                           ))}
//                         </TextField>
//                       )}
//                       {fields[activeStep] === 'Industry Hiring For' && (
//                         <TextField
//                           name="industryHiringFor"
//                           label="Industry Hiring For"
//                           variant="outlined"
//                           margin="normal"
//                           fullWidth
//                           value={formValues.industryHiringFor}
//                           onChange={handleChange}
//                           select
//                           required
//                         >
//                           {industryOptions.map((option) => (
//                             <MenuItem key={option} value={option}>
//                               {option}
//                             </MenuItem>
//                           ))}
//                         </TextField>
//                       )}
//                     </>
//                   )}
//                 </Box>
//                 <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: isMobile ? 'center' : 'space-between', marginTop: 5 }}>
//                   <Button variant="contained" disabled={activeStep === 0} onClick={handleBack} sx={{ backgroundColor: '#F77E09', color: 'white', fontWeight: 'bold', marginBottom: isMobile ? 2 : 0 }}>
//                     Back
//                   </Button>
//                   {fields[activeStep] !== 'Agree to Terms and Conditions' && (
//                     <Button variant="contained" onClick={handleNext} sx={{ backgroundColor: '#F77E09', color: 'white', fontWeight: 'bold' }}>
//                       Save & Next
//                     </Button>
//                   )}
//                   {fields[activeStep] === 'Agree to Terms and Conditions' && (
//                     <Button type="submit" variant="contained" onClick={handleJoin} sx={{ backgroundColor: '#F77E09', color: 'white', fontWeight: 'bold' }}>
//                       Join
//                     </Button>
//                   )}
//                 </Box>
//               </form>
//             </Box>
//           </Box>
//         </Paper>
//       </Box>
//     </Box>

//   )
// };

export default OnboardingForm;
