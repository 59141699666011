import React, { useEffect, useState } from 'react';

const CityList = () => {
  const [cities, setCities] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const headers = new Headers();
    headers.append("X-CSCAPI-KEY", "API_KEY");

    const requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    };

    fetch("https://api.countrystatecity.in/v1/countries/IN/states/MH/cities", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(result => {
        console.log("Fetched data:", result); // Add logging to inspect the result
        if (Array.isArray(result)) {
          setCities(result);
        } else {
          throw new Error("Unexpected response format");
        }
      })
      .catch(error => {
        console.error("Fetching error:", error);
        setError(error);
      });

  }, []);

  return (
    <div>
      {error && <div>Error: {error.message}</div>}
      <ul>
        {cities.map((city) => (
          <li key={city.id}>{city.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default CityList;
