import { Grid, Paper } from '@mui/material'
// import { tokens } from "../../theme";
import React from 'react'
import { CardContent, Typography, CardActions, Button, Tooltip } from "@mui/material"
import { useTheme } from '@emotion/react';
//import { useContext } from 'react';
import Card from '@mui/material/Card';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import axios from 'axios';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';


export const BoxCardOrganization = (id) => {

  const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
  //const colorMode = useContext(ColorModeContext);
  const [hiringCount, setHiringCunt] = useState(0);
  const [talentCount, setTalentCount] = useState(0);
  const [showMessage, setShowMessage] = useState(false);

  const [jobListingsCount, setJobListingsCount] = useState(0);
  const [activeJobListingsCount, setActiveJobListingsCount] = useState(0);
  const navigate = useNavigate();






  useEffect(() => {
    fetchJobListingsCount()
    fetchActiveJobListingsCount()
  }, []);





  const handleError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      customClass: {
        popup: 'custom-swal'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the window after clicking "OK"
        window.location.reload();
      }
    });
  }










  const fetchJobListingsCount = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/job-listings-count-organization`, { id });
      
      
      setJobListingsCount(response.data.totalJobListings);
    } catch (err) {
      console.error('Error fetching count:', err);
    }
};



  const fetchActiveJobListingsCount = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/active-job-listings-count-organization` , {id});
      setActiveJobListingsCount(response.data.totalActiveJobs);

    } catch (err) {
      console.error('Error fetching count:', err);
    }
  };


  const handleHiringData = () => {
    navigate('/hiringdata')
  }


  const handleTalentdata = () => {
    navigate('/talentdata')
  }




  return (



    <Grid xs={12} md={12} lg={12} container marginTop="20px" spacing={3}>


      <Grid xs={12} md={3} lg={3} item display="flex" margin="auto">
        <Paper elevation={8} style={{ backgroundColor: '#e0e0e0', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          
          <Card sx={{ backgroundColor: '#17A2B8', height: "100%" }}>
            <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                variant="h5"
                color="text.secondary"
                gutterBottom
                sx={{ textAlign: 'center', color: 'white', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
              >

                <WorkIcon
                  sx={{
                    marginLeft: 'auto',
                    // marginTop: '9px',
                    marginRight:'10px',
                    fontSize: 30,
                    transition: 'color 0.3s, transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      color: 'yellow',
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                    },
                  }}
                />

                Total Jobs
              </Typography>
              <Typography
                sx={{
                  fontSize: 30,
                  textAlign: 'center',
                  color: 'white',
                }}
                component="div"
              >
                {jobListingsCount}
              </Typography>
            </CardContent>
            <CardActions
              sx={{
                justifyContent: 'center',
                '&:hover': {
                  '& > button': {
                    backgroundColor: '#3C2565',
                  },
                },
              }}
            >
              <Button
                fullWidth
                size="small"
                sx={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,.30)',
                }}
                // onClick={handleTalentdata}
              >
                More info
                <ArrowCircleRightRoundedIcon />
              </Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>

      <Grid xs={12} md={3} lg={3} item display="flex" margin="auto">
        <Paper elevation={8} style={{ backgroundColor: '#e0e0e0', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Card sx={{ backgroundColor: '#17A2B8', height: "100%" }}>
            <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                variant="h5"
                color="text.secondary"
                gutterBottom
                sx={{ textAlign: 'center', color: 'white', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
              >

                <WorkHistoryIcon
                  sx={{
                    marginLeft: 'auto',
                    // marginTop: '9px',
                    marginRight:'10px',
                    fontSize: 30,
                    transition: 'color 0.3s, transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      color: 'yellow',
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                    },
                  }}
                />

                Active Jobs
              </Typography>
              <Typography
                sx={{
                  fontSize: 30,
                  textAlign: 'center',
                  color: 'white',
                }}
                component="div"
              >
                {activeJobListingsCount}
              </Typography>
            </CardContent>
            <CardActions
              sx={{
                justifyContent: 'center',
                '&:hover': {
                  '& > button': {
                    backgroundColor: '#3C2565',
                  },
                },
              }}
            >
              <Button
                fullWidth
                size="small"
                sx={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,.30)',
                }}
                // onClick={handleTalentdata}
              >
                More info
                <ArrowCircleRightRoundedIcon />
              </Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>

      <Grid xs={12} md={3} lg={3} item display="flex" margin="auto">
        <Paper elevation={8} style={{ backgroundColor: '#e0e0e0', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Card sx={{ backgroundColor: '#17A2B8', height: "100%" }}>
            <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                variant="h5"
                color="text.secondary"
                gutterBottom
                sx={{ textAlign: 'center', color: 'white', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
              >

                <ThumbUpIcon
                  sx={{
                    marginLeft: 'auto',
                    // marginTop: '9px',
                    marginRight:'10px',
                    fontSize: 30,
                    transition: 'color 0.3s, transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      color: 'yellow',
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                    },
                  }}
                />

               Liked Talent Profiles
              </Typography>
              <Typography
                sx={{
                  fontSize: 30,
                  textAlign: 'center',
                  color: 'white',
                }}
                component="div"
              >
                {/* {activeJobListingsCount} */}
                0
              </Typography>
            </CardContent>
            <CardActions
              sx={{
                justifyContent: 'center',
                '&:hover': {
                  '& > button': {
                    backgroundColor: '#3C2565',
                  },
                },
              }}
            >
              <Button
                fullWidth
                size="small"
                sx={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,.30)',
                }}
                // onClick={handleTalentdata}
              >
                More info
                <ArrowCircleRightRoundedIcon />
              </Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>

      <Grid xs={12} md={3} lg={3} item display="flex" margin="auto">
        <Paper elevation={8} style={{ backgroundColor: '#e0e0e0', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Card sx={{ backgroundColor: '#17A2B8', height: "100%" }}>
            <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                variant="h5"
                color="text.secondary"
                gutterBottom
                sx={{ textAlign: 'center', color: 'white', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
              >

                <WorkOffIcon
                  sx={{
                    marginLeft: 'auto',
                    // marginTop: '9px',
                    marginRight:'10px',
                    fontSize: 30,
                    transition: 'color 0.3s, transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      color: 'yellow',
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                    },
                  }}
                />

                Pending for Approval Jobs
              </Typography>
              <Typography
                sx={{
                  fontSize: 30,
                  textAlign: 'center',
                  color: 'white',
                }}
                component="div"
              >
                {/* {activeJobListingsCount} */}
                0
              </Typography>
            </CardContent>
            <CardActions
              sx={{
                justifyContent: 'center',
                '&:hover': {
                  '& > button': {
                    backgroundColor: '#3C2565',
                  },
                },
              }}
            >
              <Button
                fullWidth
                size="small"
                sx={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,.30)',
                }}
                // onClick={handleTalentdata}
              >
                More info
                <ArrowCircleRightRoundedIcon />
              </Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>


    </Grid>

  )

}


export default BoxCardOrganization;
